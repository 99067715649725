import React, { Component } from 'react'
import CardFleet from './CardFleet'
import myConfig from '../../configs/config'
import axios from 'axios'
import SelectBox from '../../components/SelectBox';
import MDSpinner from "react-md-spinner";
import { withTranslation } from 'react-i18next';
import ExportButton from '../../components/Export_button';
import Doc from "../../components/DocService";
import PdfContainer from "../../components/PdfContainer";

import './Report.css' // CSS

class ReportFleet extends Component {
    constructor(props) {
        super(props);
        let date = new Date()
        if (date.getDate() < 15) {
            date.setDate(-1);
        } 
        this.state = {
            farm_id: this.props.match.params.farm_id,
            loading: true,
            reports: [],
            dates: [],
            years: [],
            message: '',
            selected_month: date.getMonth() + 1,
            selected_year: date.getFullYear(),
            lasdate: 0,
        }
        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.handleChangeMonthDesktop = this.handleChangeMonthDesktop.bind(this);
        this.handleChangeMonthMobile = this.handleChangeMonthMobile.bind(this);
    }

    componentDidMount() {

        document.title = 'Agriconnected - Relatórios';
        


        let REPORT_FLEET = myConfig.API_URL + "/report_fleet/?machine__farm_id=" + this.state.farm_id + "&month=" + this.state.selected_month + "&year=" + this.state.selected_year;

        axios({
            baseURL: REPORT_FLEET,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(REPORT_FLEET)
            .then(res => {
                // console.log('*****************')
                // console.log(res.data)
                // console.log('*****************')
                this.setState({
                    reports: res.data,
                });
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 5000);
            });

        let REPORT_DATES = myConfig.API_URL + "/report/get_dates/?farm_id=" + this.state.farm_id;

        axios({
            baseURL: REPORT_DATES,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(REPORT_DATES)
            .then(res => {
                // console.log('*****************')
                // console.log(res.data.dates)
                // console.log('*****************')
                this.setState({
                    years: res.data.dates,
                    dates: res.data.dates,
                });
            });
    }

    handleChangeMonthMobile(e, mes) {
        e.preventDefault();

        this.setState({
            selected_month: e.target.value,
        });

        let MONTH_URL = myConfig.API_URL + "/report_fleet/?machine__farm_id=" + this.state.farm_id + "&month=" + e.target.value + "&year=" + this.state.selected_year;
        axios({
            baseURL: MONTH_URL,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(MONTH_URL)
            .then(res => {
                // console.log(res.data)
                this.setState({
                    reports: res.data,
                });
            });
    }

    handleChangeMonthDesktop(e, mes) {
        e.preventDefault();

        this.setState({
            selected_month: mes,
        });

        let MONTH_URL = myConfig.API_URL + "/report_fleet/?machine__farm_id=" + this.state.farm_id + "&month=" + mes + "&year=" + this.state.selected_year;

        axios({
            baseURL: MONTH_URL,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(MONTH_URL)
            .then(res => {
                // console.log(res.data)
                this.setState({
                    reports: res.data,
                });
            });
    }

    handleChangeYear(e, ano) {
        e.preventDefault();

        this.setState({
            selected_year: e.target.value,
        });
        let YEAR_URL = myConfig.API_URL + "/report_fleet/?machine__farm_id=" + this.state.farm_id + "&month=&year=" + e.target.value;

        axios({
            baseURL: YEAR_URL,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(YEAR_URL)
            .then(res => {
                // console.log(res.data)
                this.setState({
                    reports: res.data,
                });
            });
    }

    createPdf = (html) => Doc.createPdf(html);    

    render() {
        // console.log("******************"    + this.state.dates.length + "************")
        // console.log(typeof(this.state.dates.length))

        let content,
            select_months = [],
            select_months_mobile,
            select_year,
            message

        // VERIFICANDO SE EXISTEM ANOS PARA PODER LISTAR
        if (this.state.years !== undefined) {
            let data = [],
                value

            for (let i = 0; i < Object.keys(this.state.years).length; i++) {
                let index = Object.keys(this.state.years)[i];
                value = index;
                data.push({ 'id': index, 'ano': index });
            }

            select_year =
                <SelectBox strong="true" data={data} selected_value={value} change={(e) => this.handleChangeYear(e, value)} value={"ano"} id="id_year" />
        }
        else {
            select_year =
                <div>
                    <select name="" className="form-control">
                        <option value="">-------------</option>
                    </select>
                </div>
        }

        // VERIFICANDO SE EXISTEM MESES PARA PODER LISTA // DESKTOP 
        if (this.state.dates !== undefined) {
            let data = [];
            let active;
            try{
                for (let i = 0; i < Object.keys(this.state.dates[this.state.selected_year]).length; i++) {

                    let index = Object.keys(this.state.dates[this.state.selected_year])[i]; //IDS
                    let value = this.state.dates[this.state.selected_year][Object.keys(this.state.dates[this.state.selected_year])[i]]; // VALORES COMO JANEIRO, FEVEREIRO
                    data.push({ 'id': index, 'name': value, });

                    this.state.lastdate = data.slice(-1)[0].id;
                    active = (this.state.selected_month === parseInt(this.state.lastdate)) ? 'active' : '';

                    select_months.push(
                        <li role="presentation" key={index + "-tab"} className={active + " months"}>
                            <a className="nav-link  months" href="#" data-month={index} onClick={(e) => this.handleChangeMonthDesktop(e, index)}
                                id={index + "-tab"} role="tab" data-toggle="tab" aria-expanded="true">
                                {value}
                            </a>
                        </li>
                    );
                }
            }catch(err){
                console.log("error", err)
          }
        }
        else {
            select_months.push(
                <div>
                    <li role="presentation" className="active months" >
                        <a
                            className="nav-link months"
                            href="#"
                            data-month=''
                            id="tab"
                            role="tab"
                            data-toggle="tab"
                            aria-expanded="true"
                        >

                        </a>
                    </li>
                </div>
            )
        }

        // MESES MOBILE 
        if (this.state.dates !== undefined) {
            let data = [],
                valor = 0
            try{
                for (let i = 0; i < Object.keys(this.state.dates[this.state.selected_year]).length; i++) {
                    let index = Object.keys(this.state.dates[this.state.selected_year])[i]; //IDS
                    let value = this.state.dates[this.state.selected_year][Object.keys(this.state.dates[this.state.selected_year])[i]]; // VALORES COMO JANEIRO, FEVEREIRO
                    data.push({ 'id': index, 'name': value, });
                }

                let lastdate = data.slice(-1)[0].id;
                if (this.state.selected_month !== data[0].id) { valor = lastdate } else { valor = lastdate }

                select_months_mobile =
                    <SelectBox strong="true" data={data} selected_value={valor} change={this.handleChangeMonthMobile} value={"name"} id="id_months" />
            
            }catch(err){
                console.log("error", err)
          }
        }
        else {
            select_months_mobile =
                <div>
                    <select name="" className="form-control">
                        <option value="">-------------</option>
                    </select>
                </div>
        }

        // ENVIAR MENSAGEM CASO NÃO SEJA ENCONTRADO NENHUM DADO
        if (this.state.reports.length === 0 && this.state.loading === false) {
            message =
                <div className="x_panel_no_width col-xs-12">
                    <h2 className="x_title">{this.props.t('Nenhum resultado encontrado')}</h2>
                </div>
        }

        // LISTAGEM DE CARDS
        if (this.state.loading === false) {
            content =
                <PdfContainer createPdf={this.createPdf}>
                <div>
                    
                    {this.state.reports.map(report =>
                        <CardFleet key={report.id} report={report} />
                    )}
                </div>
                <div className='clear'> </div>
                { this.state.reports.length > 0 && this.state.reports[0].report_type === 'biweekly' && 
                    <div className='x_title title'><h2>Obs: Este relatório é parcial (do dia 1 ao 15)</h2></div>
                }
                </PdfContainer>
        }
        else {
            content = <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}><MDSpinner /></div>;
        }

        return (
            <div id="report_fleet_container">
                <ExportButton 
                    classname="btn btn-default-export btn-export-extra" 
                    export={myConfig.API_URL + "/report_fleet/export_fleet/?farm_id=" + this.props.match.params.farm_id + "&month=" + this.state.selected_month + "&year=" + this.state.selected_year} 
                />

                <div className="x_title title">
                    <h2>{this.props.t('Relatório mensal Frota')}</h2>
                    <div className="clearfix"></div>
                </div>

                {/* MOBILE MESES*/}
                <div className="form-group col-xs-12 col-md-2 col-lg-2 pull-right select-year-box months_list mobile">
                    <label htmlFor="selectAno">
                        <span>{this.props.t('Mês')}</span>
                    </label>
                    <div className="form-group">
                        {select_months_mobile}
                    </div>
                </div>

                {/* DESKTOP*/}
                <ul id="month-tab" className="nav nav-tabs" role="tablist">

                    {select_months}

                    {/* DIV RELACIONADA AOS ANOS */}
                    <div className="form-group col-xs-12 col-md-2 col-lg-2 pull-right">
                        {select_year}
                    </div>

                </ul>

                {/* MENSAGEM CASO REPOSTS NÃO TIVER DADOS  */}
                {message}

                {/* LISTAGEM DE CARDS  */}
                {content}

            </div>
        )
    }
}
export default withTranslation()(ReportFleet);
