import React from "react";

import MDSpinner from "react-md-spinner";
import logoIcon from "../../assets/images/logo.png";

export default function Loading() {
  return (
    <div className="initial-loading">
      <div>
        <img src={logoIcon} alt="..." className="logo-loading" />
      </div>
      <br />
      <div>
        <MDSpinner />
      </div>
    </div>
  );
}
