import { FETCH_FARMS } from '../actions/types';

const INITAL_STATE = [];

export default function farm(state = INITAL_STATE, action) {
  
  switch (action.type) {
    case FETCH_FARMS:
      return action.farms;
    default:
      return state;
  }
}


