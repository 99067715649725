import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PathReport from '../Machine/PathReport';
import ReportActivity from './ReportActivity'
import ReportFleet from './ReportFleet'

class Routes extends Component {

    render() {
        return (
            <Switch>
                <Route path="/:farm_id/report/activity/" component={ReportActivity} />} />
                <Route path="/:farm_id/report/fleet/" component={ReportFleet} />} />
                <Route path="/:farm_id/report/path/" component={PathReport} />} />
            </Switch>

        )
    }
}

export default withRouter(Routes)