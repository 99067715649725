import React, { useState, useEffect } from "react";

// COMPONENTS
import Title from "../../components/Title";
import Table from "../../components/Table";
import ToggleComponent from "../../components/Toggle_component";
import Search from "../../components/Search";
import SelectBox from "../../components/SelectBox";
import MDSpinner from "react-md-spinner";
import FloatingDropdown from "../../components/FloatingDropdown";
import Modal from "../../components/Modal";
import AgriDateTime from "../../components/AgriDateTime";

// HELPERS
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  getErrorComponent,
  updateQueryStringParameter,
  getTime,
  getFormatedDate,
} from "../../helpers/utils";

// CONFIGS
import myConfig from "../../configs/config";
import axios from "axios";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const initialState_search = {
  startDate: "",
  endDate: "",
  operations: [],
  ident__icontains: "",
};

const initial_page = 1;

let status_data = [
  { id: 0, name: "Não Finalizado" },
  { id: 1, name: "Finalizado" },
];

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0,0.5)",
  },
};

let operations_checked = [];
let notFinishedOperations = [];

function OperationList(props) {
  document.title = "Agriconnected - Operações";

  const params = useParams();

  const history = useHistory();

  const farmId = params.farm_id;

  let EXPORT_OPERATIONS_URL = `${myConfig.API_URL}/operation/export_operations/?farm_id=${farmId}&id__in=`;
  let FINISHED_OPERATIONS = `${myConfig.API_URL}/operation/finalize/?farm_id=${farmId}&id__in=`;
  let DUPLICATE_OPERATIONS = `${myConfig.API_URL}/operation/clone/?farm_id=${farmId}&id__in=`;
  let RELATIVE_OPERATION_URL = `${myConfig.API_URL}/operation/?farm_id=${farmId}`;
  let OPERATION_URL = `${RELATIVE_OPERATION_URL}&page=${initial_page}`;

  const [operations, setOperations] = useState([]);
  const [farm] = useState([]);
  const [listLandplots, setListLandplots] = useState([]);
  const [listOperators, setListOperators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastSearch, setLastSearch] = useState("");
  const [errors, setErrors] = useState([]);
  const [ident, setIdent] = useState("");
  const [dt_ini, setDtIni] = useState("");
  const [dt_end, setDtEnd] = useState("");
  const [fineshed, setFinished] = useState("");
  const [land_plot, setLandplot] = useState("");
  const [operator, setOperator] = useState("");
  const [pageLength, setPageLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [operationsSelected, setOperationsSelected] = useState([]);
  const [landplot_label, setLandplotLabel] = useState("");
  const [fineshed_label, setFinishedLabel] = useState("");
  const [operator_label, setOperatorLabel] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasDuplicated, setHasDuplicated] = useState(false);
  const [new_dt, setNewDate] = useState(new Date());

  async function getLandplotData() {
    try {
      const LANDPLOT_URL = `${myConfig.API_URL}/landplot/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: LANDPLOT_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;
      let status = response.status;

      if (status !== 200) {
        setErrors(errors.concat(getErrorComponent(data.detail)));
        return;
      }

      setListLandplots(data);
    } catch (error) {
      console.error(error);

      let error_msg =
        "Falha ao carregar talhões, recarregue a página e tente novamente.";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getOperatorsData() {
    try {
      const OPERATOR_URL = `${myConfig.API_URL}/operator/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: OPERATOR_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;
      let status = response.status;

      if (status !== 200) {
        setErrors(errors.concat(getErrorComponent(data.detail)));
        return;
      }

      setListOperators(data);
    } catch (error) {
      console.error(error);

      let error_msg =
        "Falha ao carregar dados dos operadores, recarregue a página e tente novamente.";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getOperationData() {
    try {
      let response = await axios({
        baseURL: OPERATION_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      let { data, status } = response;

      if (status === 200) {
        const operations = data.results,
          page_length = data.size,
          current_page = data.current_page;

        setOperations(operations);
        setLoading(false);
        setLastSearch(OPERATION_URL);
        setPageLength(page_length);
        setCurrentPage(current_page);

        if (data.hasOwnProperty("detail")) {
          setErrors(errors.concat(getErrorComponent(data.detail)));
        }
      }
    } catch (error) {
      console.error(error);

      setLoading(false);

      let error_msg =
        "Falha ao fazer requisição, recarregue a página e tente novamente.";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  useEffect(() => {
    getOperationData();

    getOperatorsData();

    getLandplotData();
  }, []);

  function handleScrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  function windoConfirm() {
    if (
      window.confirm(
        `Existem ${notFinishedOperations.length} operações não finalizadas, deseja finalizar para continuar ?`
      )
    ) {
    } else {
      return;
    }
  }

  async function handleSubmitFinishedOperations() {
    windoConfirm();

    try {
      let FINISHED_OPERATIONS = `${myConfig.API_URL}/operation/finalize/?farm_id=${farmId}&id__in=${notFinishedOperations}`;

      let response = await axios({
        method: "GET",
        url: FINISHED_OPERATIONS,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 200) {
        toast.success(`Operação(ões) finaliza(as) com sucesso`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        notFinishedOperations = [];

        setIsModalOpen(true);
      }
    } catch (error) {
      let error_msg =
        "Falha ao finalizar operações, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  async function handleSubmitDuplicateOperation(event) {
    event.preventDefault();

    setHasDuplicated(true);

    try {
      let DUPLICATE_OPERATIONS = `${
        myConfig.API_URL
      }/operation/clone/?farm_id=${farmId}&id__in=${operationsSelected}&new_dt=${getFormatedDate(
        new_dt
      )}`;

      let response = await axios({
        method: "GET",
        url: DUPLICATE_OPERATIONS,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      if (response.status === 200) {
        toast.success(`Operação(ões) duplicada(as) com sucesso`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.href = `/${farmId}/operation/`;
        }, 3000);
      }
    } catch (error) {
      let error_msg =
        "Falha ao duplicar operações, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  async function findForOperation() {
    for (let i = 0; i < operationsSelected.length; i++) {
      await operations.map((operation) => (
        <>
          {operation.id === parseInt(operationsSelected[i]) &&
          operation.status.status !== "finalizado"
            ? notFinishedOperations.push(operationsSelected[i])
            : setIsModalOpen(true)}
        </>
      ));
    }

    if (notFinishedOperations.length > 0) {
      handleSubmitFinishedOperations();
    }
  }

  function openModalDuplicate() {
    if (operationsSelected.length === 0) {
      let error_msg =
        "Está ação não pode ser realizada, por favor selecione ao menos uma operação para continuar.";
      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    findForOperation();

    setIsModalOpen(true);
  }

  function mountUrls(e, page = "") {
    let url = "";
    url = lastSearch !== "" ? lastSearch : RELATIVE_OPERATION_URL;

    if (url.search("page")) {
      if (page !== "") {
        OPERATION_URL = updateQueryStringParameter(url, "page", page);
      } else {
        OPERATION_URL = updateQueryStringParameter(
          url,
          "page",
          parseInt(e.currentTarget.innerText)
        );
      }
    } else {
      OPERATION_URL = url.search("page")
        ? ""
        : url + "&page=" + parseInt(e.currentTarget.innerText);
    }

    return url;
  }

  function handlePagination(e) {
    e.preventDefault();

    let url = mountUrls(e);

    setLoading(true);
    setLastSearch(url);
    handleScrollTop();
    getOperationData();
  }

  function replaceAll(str, search, replace) {
    let i = 0,
      strLength = str.length;

    for (i; i < strLength; i++) {
      str = str.replace(search, replace);
    }

    return str;
  }

  function getFormData() {
    let form_data = new FormData();

    form_data.append("fineshed_at", new Date().toISOString());
    form_data.append("fineshed", true);

    return form_data;
  }

  async function handleFinished(event, operation_id) {
    event.preventDefault();

    if (!window.confirm("Deseja finalizar a operação ? ")) {
      return 0;
    } else {
      setLoading(true);

      let FINISHED_URL = `${myConfig.API_URL}/operation/${operation_id}/?farm_id=${farmId}`;

      axios({
        method: "PATCH",
        url: FINISHED_URL,
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      })
        .then((res) => {
          axios.get(lastSearch).then((res) => {
            const { results } = res.data;

            setOperations(results);
            setLoading(false);
            setLastSearch(lastSearch);
          });
          history.push(`/${farmId}/operation/`);
        })
        .catch((error) => {
          console.error("error in finished operation", error);

          let error_msg =
            "Falha ao fazer finalizar operação, recarregue a página e tente novamente.";

          toast.error(error_msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.props.history.push("/" + this.farmId + "/operation/");
        })
        .catch((error) => {
          console.log(error);
          let error_msg =
            "Falha ao finalizar operação, tente novamente mais tarde";

          alert(error_msg);
        });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    const form_data = new FormData(event.target);

    let ident = form_data.get("ident__icontains");
    let dt_ini = form_data.get("dt_ini__gte");
    let dt_end = form_data.get("dt_ini__lte");
    let fineshed = form_data.get("fineshed");
    let land_plot = form_data.get("land_plot");
    let operator = form_data.get("operator");

    let current_page = 1;
    let size = window.innerWidth;

    if (dt_ini !== "") {
      dt_ini =
        replaceAll(dt_ini, "/", "-").split("-").reverse().join("-") +
        "T00:00:00-00:00";
    }
    if (dt_end !== "") {
      dt_end =
        replaceAll(dt_end, "/", "-").split("-").reverse().join("-") +
        "T00:00:00-00:00";
    }

    setLoading(true);
    setIdent(ident);
    setDtIni(dt_ini);
    setDtEnd(dt_end);
    setFinished(fineshed);
    setLandplot(land_plot);
    setOperator(operator);

    mountUrls(event, current_page);

    if (size < 700) {
      let collapse = document.getElementById("collapse-button");
      collapse.click();
    }

    try {
      const url = `${OPERATION_URL}&ident__icontains=${ident}&dt_ini__gte=${dt_ini}&dt_ini__lte=${dt_end}&fineshed=${fineshed}&land_plot=${land_plot}&operator=${operator}`;

      let response = await axios({
        baseURL: url,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let { data } = response;

      const operations = data.results;
      const page_length = data.size;

      setOperations(operations);
      setLoading(false);
      setLastSearch(url);
      setPageLength(page_length);
      setCurrentPage(current_page);
    } catch (error) {
      console.error("error in handle submit", error);

      let error_msg =
        "Falha ao fazer requisição, recarregue a página e tente novamente.";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function mountPagination() {
    let pages = [];

    for (let i = 1; i <= pageLength; i++) {
      if (i === currentPage) {
        pages.push(
          <PaginationItem active key={i}>
            <PaginationLink href="#">{i}</PaginationLink>
          </PaginationItem>
        );
      } else {
        pages.push(
          <PaginationItem key={i}>
            <PaginationLink onClick={handlePagination} href="#">
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    return <Pagination key="2">{pages}</Pagination>;
  }

  function handleChangeSelect(e, component_name) {
    let index = e.target.selectedIndex;
    let label = e.target[index].text;

    if (component_name === "land_plot") {
      setLandplot(e.target.value);
      setLandplotLabel(label);
    }
    if (component_name === "operator") {
      setOperator(e.target.value);
      setOperatorLabel(label);
    }
    if (component_name === "fineshed") {
      setFinished(e.target.value);
      setFinishedLabel(label);
    }
  }

  async function handleCheckedAllOperations(e) {
    let checked = e.target.checked;

    if (!checked) {
      await setOperationsSelected([]);
      return;
    }

    for (let i = 0; i < operations.length; i++) {
      operations_checked.push({
        id: operations[i].id,
        checked: e.target.checked,
      });
    }

    let arrayOfMachines = checkedAtualOperation();

    await setOperationsSelected(arrayOfMachines);
  }

  async function handleChecked(event, operation_id) {
    operations_checked.push({
      id: operation_id,
      checked: event.target.checked,
    });

    let arrayOfMachines = checkedAtualOperation();

    await setOperationsSelected(arrayOfMachines);
  }

  function checkedAtualOperation() {
    let uniqueObject = {};
    let newArray = [];

    for (let i in operations_checked) {
      let objId = operations_checked[i]["id"];

      uniqueObject[objId] = operations_checked[i];
    }

    for (let i in uniqueObject) {
      if (uniqueObject[i].checked === true) {
        newArray.push(uniqueObject[i].id);
      }
    }
    return newArray;
  }

  function toastSuccess() {
    const sucess_msg = "Operação realizada com sucesso.";

    return toast.success(sucess_msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  async function duplicateOperation() {
    if (operationsSelected.length === 0) {
      toast.error(
        "Está ação não pode ser realizada, por favor selecione ao menos uma operação para continuar.",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      return;
    }

    try {
      let URL = `${DUPLICATE_OPERATIONS}${operationsSelected}&new_dt=${getFormatedDate(
        new_dt
      )}`;

      let response = await axios({
        baseURL: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      if (response.status === 200) {
        toastSuccess();
        setTimeout(() => {
          window.location.href = `/${farmId}/operation/`;
        }, 3000);
      }
    } catch (error) {
      console.error("duplicate error", error);
      toast.error(
        "Não foi possível realizar operação, recarregue a página e tente novamente",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  async function finishedOperation() {
    if (operationsSelected.length === 0) {
      toast.error(
        "Está ação não pode ser realizada, por favor selecione ao menos uma operação para continuar.",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    try {
      let URL = `${FINISHED_OPERATIONS}${operationsSelected}`;

      let response = await axios({
        baseURL: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      if (response.status === 200) {
        toastSuccess();
        setTimeout(() => {
          window.location.href = `/${farmId}/operation/`;
        }, 3000);
      }
    } catch (error) {
      console.error("finished operation error", error);
      toast.error(
        "Não foi possível realizar operação, recarregue a página e tente novamente",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  function Spinner() {
    return (
      <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
        <MDSpinner />
      </div>
    );
  }

  function handleTimeChange(e) {
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let time_changed = new Date(
      new_dt.toLocaleDateString("en-US", options) + " " + e.target.value
    );

    setNewDate(time_changed);
  }
  function closeModalDuplicate() {
    setIsModalOpen(false);
  }

  return (
    <div className="operation-list-container">
      {isModalOpen && (
        <Modal
          isModalOpen={isModalOpen}
          closeModal={isModalOpen}
          style={modalStyle}
        >
          <h4>Defina uma data para inicio da operação</h4>
          <form onSubmit={handleSubmitDuplicateOperation}>
            <div
              className="form-group text-left col-xs-12 col-md-2"
              style={{
                width: "70%",
                marginLeft: "97px",
              }}
            >
              <AgriDateTime
                time={getTime(new_dt)}
                selectedDate={new_dt}
                label={"Data inicial"}
                datetimeformat="true"
                handleDateChange={(e) => setNewDate(e)}
                handleTimeChange={handleTimeChange}
              />
              <br />
              <button
                style={{ width: "100%" }}
                type="submit"
                className="btn btn-primary"
                disabled={hasDuplicated}
              >
                Salvar
              </button>
            </div>
          </form>
          <input
            style={{ width: "66%", marginLeft: "108px" }}
            type="submit"
            className="btn btn-ligth"
            value={"Cancelar"}
            onClick={closeModalDuplicate}
          />
        </Modal>
      )}

      <Title title={props.t("Operações")} farm_name="" add_button="true">
        <ToggleComponent
          className="toggle-search"
          subtitle=" Os campos são opcionais , escolha um ou mais para filtrar "
          show_arrow="True"
        >
          <Search
            states={initialState_search}
            filters={[
              {
                label: props.t("Identificação"),
                name: "ident__icontains",
                type: "text",
              },
              {
                label: props.t("Data Inicio de"),
                name: "dt_ini__gte",
                type: "date-start",
                date_type: "start",
              },
              {
                label: props.t("Data Inicio até"),
                name: "dt_ini__lte",
                type: "date-end",
                date_type: "end",
              },
            ]}
            action={OPERATION_URL}
            handleSubmit={handleSubmit}
            farm_id={farmId}
          >
            <div className="form-group col-md-3 col-xs-12">
              <SelectBox
                data={status_data}
                value="name"
                change={handleChangeSelect}
                label={props.t("Finalizado")}
                component_name="fineshed"
              />
            </div>
            <div className="form-group col-md-3 col-xs-12">
              <SelectBox
                data={listLandplots}
                change={handleChangeSelect}
                value="name"
                label={props.t("Talhão")}
                component_name="land_plot"
              />
            </div>
            <div className="form-group col-md-3 col -xs-12">
              <SelectBox
                data={listOperators}
                value="nome"
                change={handleChangeSelect}
                label={props.t("Operador")}
                component_name="operator"
              />
            </div>
          </Search>
        </ToggleComponent>
      </Title>
      <div className="x_content">
        <FloatingDropdown>
          <li>
            <Link to={`/${farmId}/operation/add`}>
              {props.t("Nova operação")}
            </Link>
          </li>
          <li>
            <a onClick={openModalDuplicate}>{props.t("Duplicar")}</a>
          </li>
          <li>
            <a
              href={
                operationsSelected.length > 0
                  ? `${EXPORT_OPERATIONS_URL}${operationsSelected}`
                  : `${EXPORT_OPERATIONS_URL}`
              }
            >
              {props.t("Exportar")}
            </a>
          </li>

          <li>
            <a onClick={finishedOperation}>{props.t("Finalizar")}</a>
          </li>
        </FloatingDropdown>

        {loading === false ? (
          <>
            {" "}
            <Table
              columns_name={[
                props.t("Operação"),
                props.t("Início"),
                props.t("Status"),
                props.t("Ação"),
              ]}
              farm_id={farmId}
              object_name="operations"
              ident={ident}
              farm={farm}
              dt_ini={dt_ini}
              dt_end={dt_end}
              fineshed={fineshed}
              land_plot={land_plot}
              operator={operator}
              operations={operations}
              pageLength={pageLength}
              currentPage={currentPage}
              errors={errors}
              operations_selected={operationsSelected}
              operations_checked={operationsSelected}
              lastSearch={lastSearch}
              landplots={listLandplots}
              operators={listOperators}
              handleFinished={handleFinished}
              handleChecked={handleChecked}
              handleCheckedAllOperations={handleCheckedAllOperations}
              finishedOperation={finishedOperation}
              duplicateOperation={duplicateOperation}
              columns_values={[
                "ident-created",
                "date-init-time",
                "status",
                "finish",
              ]}
            />
            {mountPagination()}{" "}
          </>
        ) : (
          <Spinner />
        )}

        <ToastContainer />
      </div>
    </div>
  );
}
export default withTranslation()(withRouter(OperationList));
