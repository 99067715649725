import React, { Component, cloneElement } from 'react'
import './Report.css' // CSS
import myConfig from '../../configs/config'
import axios from 'axios'
import SelectBox from '../../components/SelectBox';
import MDSpinner from "react-md-spinner";
import CardActivity from './CardActivity'
import { withTranslation } from 'react-i18next';
import ExportButton from '../../components/Export_button';

class ReportActivity extends Component {
    constructor(props) {
        super(props);
        let date = new Date()
        date.setDate(-1)
        this.state = {
            farm_id: this.props.match.params.farm_id,
            activities: [],
            loading: true,
            dates: [],
            years: [],
            selected_month: date.getMonth() + 1,
            selected_year: date.getFullYear(),
            lastdate: 0,
        }
        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.handleChangeMonthDesktop = this.handleChangeMonthDesktop.bind(this);
        this.handleChangeMonthMobile = this.handleChangeMonthMobile.bind(this);
    }
    componentDidMount() {

        document.title = 'Agriconnected - Relatórios';
        


        let REPORT_ACTIVITY = myConfig.API_URL + "/report_activity/get_dates/?farm_id=" + this.state.farm_id + "&month=" + this.state.selected_month + "&year=" + this.state.selected_year;
        // console.log(REPORT_ACTIVITY)
        axios({
            baseURL: REPORT_ACTIVITY,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(REPORT_ACTIVITY)
            .then(res => {
                // console.log('*****************')
                // console.log(res.data)
                // console.log('*****************')
                this.setState({
                    activities: res.data,
                });
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 5000);
            });

        let REPORT_DATES = myConfig.API_URL + "/report_dates/dates/?farm_id=" + this.state.farm_id;

        axios({
            baseURL: REPORT_DATES,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            // axios.get(REPORT_DATES)
            .then(res => {
                // console.log('*****************')
                // console.log(res.data.dates)
                // console.log('*****************')
                this.setState({
                    years: res.data.dates,
                    dates: res.data.dates,
                });
            });
    }

    handleChangeMonthMobile(e, mes) {
        e.preventDefault();

        // console.log(e.target.value)
        this.setState({
            selected_month: e.target.value,
        });

        let MONTH_URL = myConfig.API_URL + "/report_activity/get_dates/?farm_id=" + this.state.farm_id + "&month=" + e.target.value + "&year=" + this.state.selected_year;
        // axios.get(MONTH_URL)
        axios({
            baseURL: MONTH_URL,
            method: 'get',
            headers: {
                'Authorization':`Bearer ${localStorage.getItem('client-token')}`
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    activities: res.data,
                });
            });
    }

    handleChangeMonthDesktop(e, mes) {
        e.preventDefault();

        this.setState({
            selected_month: parseInt(mes),
        });

        let MONTH_URL = myConfig.API_URL + "/report_activity/get_dates/?farm_id=" + this.state.farm_id + "&month=" + mes + "&year=" + this.state.selected_year;
        // axios.get(MONTH_URL)
        axios({
            baseURL: MONTH_URL,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    activities: res.data,
                });
            });
    }

    handleChangeYear(e) {
        e.preventDefault();

        this.setState({
            selected_year: e.target.value,
        });

        let YEAR_URL = myConfig.API_URL + "/report_activity/get_dates/?farm_id=" + this.state.farm_id + "&month=&year=" + e.target.value;
        // axios.get(YEAR_URL)
        axios({
            baseURL: YEAR_URL,
            method: 'get',
            headers: {
                'Authorization':`Bearer ${localStorage.getItem('client-token')}`
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    activities: res.data,
                });
            });
    }

    render() {
        let content,
            select_months = [],
            select_months_mobile,
            select_year,
            message

        // VERIFICANDO SE EXISTEM ANOS PARA PODER LISTAR
        if (this.state.years !== undefined) {
            let data = [],
             value

            for (let i = 0; i < Object.keys(this.state.years).length; i++) {
                let index = Object.keys(this.state.years)[i];
                value = index;
                data.push({ 'id': index, 'ano': index });
            }

            select_year =
                <SelectBox strong="true" data={data} selected_value={value} change={(e) => this.handleChangeYear(e, value)} value={"ano"} id="id_year" />
        }
        else {
            select_year =
                <div>
                    <select name="" className="form-control">
                        <option value="">-------------</option>
                    </select>
                </div>
        }

        // VERIFICANDO SE EXISTEM MESES PARA PODER LISTA // DESKTOP 
        if (this.state.dates !== undefined) {
            let data = [];
            let active
            try{
                for (let i = 0; i < Object.keys(this.state.dates[this.state.selected_year]).length; i++) {

                    let index = Object.keys(this.state.dates[this.state.selected_year])[i]; //IDS
                    let value = this.state.dates[this.state.selected_year][Object.keys(this.state.dates[this.state.selected_year])[i]]; // VALORES COMO JANEIRO, FEVEREIRO
                    data.push({ 'id': index, 'name': value, });

                    this.state.lastdate = data.slice(-1)[0].id;
                    if (this.state.selected_month === parseInt(this.state.lastdate)) {
                        active = 'active'
                    }

                    select_months.push(
                        <li role="presentation" key={index + "-tab"} className={active + " months"}>
                            <a className="nav-link  months" href="#" data-month={index} onClick={(e) => this.handleChangeMonthDesktop(e, index)}
                                id={index + "-tab"} role="tab" data-toggle="tab" aria-expanded="true">
                                {value}
                            </a>
                        </li>
                    );
                }
            }catch(err){
                console.log("error", err)
          }
        }
        else {
            select_months.push(
                <div>
                    <li role="presentation" className="active months" >
                        <a
                            className="nav-link months"
                            href="#"
                            data-month=''
                            id="tab"
                            role="tab"
                            data-toggle="tab"
                            aria-expanded="true"
                        >

                        </a>
                    </li>
                </div>
            )
        }

        // MESES MOBILE 
        if (this.state.dates !== undefined) {
            let data = [],
                valor = 0
            try{
                for (let i = 0; i < Object.keys(this.state.dates[this.state.selected_year]).length; i++) {
                    let index = Object.keys(this.state.dates[this.state.selected_year])[i]; //IDS
                    let value = this.state.dates[this.state.selected_year][Object.keys(this.state.dates[this.state.selected_year])[i]]; // VALORES COMO JANEIRO, FEVEREIRO
                    data.push({ 'id': index, 'name': value, });
                }

                let lastdate = data.slice(-1)[0].id;
                if (this.state.selected_month !== data[0].id) { valor = lastdate } else { valor = lastdate }

                select_months_mobile =
                    <SelectBox strong="true" data={data} selected_value={valor} change={this.handleChangeMonthMobile} value={"name"} id="id_months" />
            
            }catch(err){
                console.log("error", err)
          }
        }
        else {
            select_months_mobile =
                <div>
                    <select name="" className="form-control">
                        <option value="">-------------</option>
                    </select>
                </div>
        }

        //LISTAGEM DOS CARDS
        if (this.state.loading === false) {
            content =
                <div>
                    {this.state.activities.map(activity =>
                        <CardActivity activity={activity} key={activity.id}/>
                    )}
                </div>
        }
        else {
            content = <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}><MDSpinner /></div>;
        }

        // ENVIAR MENSAGEM CASO NÃO SEJA ENCONTRADO NENHUM DADO
        if (this.state.activities.length === 0 && this.state.loading === false) {
            message =
                <div className="x_panel_no_width col-xs-12">
                    <h2 className="x_title">{this.props.t('Nenhum resultado encontrado')}</h2>
                </div>
        }
        return (
            <div id="report_activity_container">
                <ExportButton classname='btn btn-default-export'  export={myConfig.API_URL + "/report_activity/export_activity/?farm_id=" + this.state.farm_id + "&month=" + this.state.selected_month + "&year=" + this.state.selected_year} />

                <div className="x_title title">
                    <h2>{this.props.t('Relatório mensal de atividades por talhão')}</h2>
                    <div className="clearfix"></div>
                </div>

                {/* MOBILE MESES*/}
                <div className="form-group col-xs-12 col-md-2 col-lg-2 pull-right select-year-box months_list mobile">
                    <span>{this.props.t('Mês')}</span>
                    <div className="form-group">
                        {select_months_mobile}
                    </div>
                </div>

                {/* DESKTOP*/}
                <ul id="month-tab" className="nav nav-tabs" role="tablist">

                    {select_months}

                    {/* DIV RELACIONADA AOS ANOS */}
                    <div className="form-group col-xs-12 col-md-2 col-lg-2 pull-right">
                        {select_year}
                    </div>

                </ul>

                {/* MENSAGEM CASO REPOSTS NÃO TIVER DADOS  */}
                {message}

                {/* LISTA DE CARDS  */}
                {content}

            </div>
        )
    }
}
export default withTranslation()(ReportActivity);