import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import OperatorCreate from './CreateOperator';
import ListOperator from './ListOperator'


class Routes extends Component {

    render() {
        return (
            <Switch>
                <Route path="/:farm_id/operator/:operator_id/change" component={OperatorCreate} />} />
                <Route path="/:farm_id/operator/add" component={OperatorCreate} />} />
                <Route path="/:farm_id/operator/" component={ListOperator} />} />
            </Switch>
        );
    }
}

export default withRouter(Routes)

