import React, { useState, Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import green from "@material-ui/core/colors/green";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: green,
    },
});

function getDatePicker(props){
    let format = "";
    props.datetimeformat === "true" ? format = "dd/MM/yyyy" : format = "yyyy/MM/dd HH:mm";

    if(props.validDates !== undefined){
        return <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        className="form-control"
        format={format}
        value={props.selectedDate}
        onAccept={(event) => props.handleDateChange(event)}
        onChange={props.handleDateChange}
        minDate={props.validDates.init}
        maxDate={props.validDates.end}
        />        
    }else{
        return <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        className="form-control"
        format={format}
        value={props.selectedDate}
        onAccept={(event) => props.handleDateChange(event)}
        onChange={props.handleDateChange}            
        />
    }
}


function AgriDateTime(props) {
    
    let timeLabel = "";

    let style = {
        date_class : "",        
        time_class : ""
    };
        

    if(props.align === "horizontal"){
        style = {
            date_class : "col-xs-7 no-padding-left",            
            time_class : "col-xs-5 no-padding-left"
        };
    }

    props.timeLabel !== "" ? timeLabel = <label className={"col-xs-12 no-padding"}>{props.timeLabel}</label> : timeLabel = "";    
        

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>            
            <div>
                <div className={style.date_class}>
                    <label>{props.label}</label>
                    <ThemeProvider theme={defaultMaterialTheme} >
                        {getDatePicker(props)}
                        <br /><br />

                    </ThemeProvider>            
                </div>
                <div className={style.time_class}>
                    {timeLabel}
                    <ThemeProvider theme={defaultMaterialTheme} >
                    <input
                        style={{ borderRadius: '5px' }}
                        type="time"
                        className="form-control left"
                        value={props.time}
                        onAccept={(event)    => props.handleTimeChange(event)}
                        onChange={props.handleTimeChange}
                    />           
                    </ThemeProvider> 
                </div>    
            </div>
        </MuiPickersUtilsProvider>


    );
}


export default AgriDateTime;

