import React from "react";
import { useParams, withRouter, Link } from "react-router-dom";

//HELPERS
import { withTranslation } from "react-i18next";

// COMPONENTS
import Title from "../components/Title";
import Table from "../components/Table";

function Index(props) {
  const params = useParams();

  let items = [
    {
      name: "Operações",
      to: `/${params.farm_id}/operation/add`,
    },
    {
      name: "Máquinas",
      to: `/${params.farm_id}/equipment/machine/add/`,
    },
    {
      name: "Implementos",
      to: `/${params.farm_id}/equipment/implement/add/`,
    },
    {
      name: "Operadores",
      to: `/${params.farm_id}/operator/add/`,
    },
    {
      name: "Talhões",
      to: `/${params.farm_id}/landplots/add/`,
    },
  ];

  let tableHeaders = [
    { title: props.t("Item") },
    { title: props.t("Adicionar") },
  ];

  return (
    <>
      <Title title="Novo" />

      <Table headers={tableHeaders}>
        {items.map((item) => (
          <tr>
            <td scope="row">{item.name}</td>
            <td>
              <Link to={item.to} className="btn btn-default btn-primary">
                {props.t("Escolher")}
              </Link>
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}

export default withTranslation()(withRouter(Index));
