import React, { Component } from "react";

import GoogleMapReact from "google-map-react";

import { Collapse } from "reactstrap";

import {
  secondToMinute,
  secondsToFormateTime,
  formatTime,
} from "../helpers/utils";
import { withTranslation } from "react-i18next";

import stoppedIcon from "../assets/icons/stopped.ico";
import offlineIcon from "../assets/icons/offline.ico";

import insideIcon from "../assets/icons/enter.ico";
import outIcon from "../assets/icons/exit.ico";
import myConfig from "../configs/config";

import "../containers/Operation/Operation.css";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: null,
      poly: null,
      center: {
        lat: this.props.map.centroidLat,
        lng: this.props.map.centroidLng,
      },
      mapZoom: 15,
      collapsePoints: false,
      isActiveStops: false,
      isActiveSpeeds: false,
      isActiveLandplots: false,
    };
  }

  setPolyline = (google) => {
    let polylines = "";
    let last_line = "";
    let last_posi = "";

    // TRATANDO OS DADOS  DAS LINHAS
    if (this.props.map.lines !== "") {
      polylines = this.props.map.lines;
      last_line = polylines[polylines.length - 1];
      if (last_line !== undefined) {
        last_posi = last_line.path[last_line.path.length - 1];
      }
    }

    // MARKER PARA LOCALIZAÇÃO CENTRAL
    this.state.marker = new google.maps.Marker({
      position: last_posi,
      title: last_posi.speed,
      icon: require(`../assets/traccar/${this.props.equipment.machine_status}/${this.props.equipment.traccar_ico}.svg`),
      opacity: 1,
    });
    this.state.marker.setMap(google.map);

    // RENDERIZANDO TODAS AS LINHAS
    for (let i = 0; i < polylines.length; i++) {
      this.state.poly = null;
      this.state.poly = new google.maps.Polyline({
        path: polylines[i].path,
        strokeColor: polylines[i].color,
        strokeOpacity: 0.5,
        strokeWeight: 5,
      });
      this.state.poly.setMap(google.map);
    }
  };

  handleGoogleMapApi = (google) => {
    // FERRAMENTAS DE DESENHO DO POLIGONO
    window.google = google;
    try {
      var drawingManager = new google.maps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: ["polygon", "polyline"],
          position: window.google.maps.ControlPosition.TOP_CENTER,
        },
        drawingControl: true,
        polylineOptions: {
          editable: true,
          strokeColor: "#00e600",
          fillColor: "#00e600",
          fillOpacity: 0.4,
          strokeWeight: 6,
        },
        polygonOptions: {
          editable: true,
          strokeColor: "#ff9933",
          fillColor: "#ff9933",
          fillOpacity: 0.4,
          strokeWeight: 5,
        },
      });

      drawingManager.setMap(google.map);
    } catch (e) {
      console.log("error", e);
    }
    google.maps.event.addListener(
      drawingManager,
      "polylinecomplete",
      function (polyline) {
        let lengthInMeters = google.maps.geometry.spherical.computeLength(
          polyline.getPath()
        );
        alert(
          `O tamanho da linha em metros é de ${Math.round(lengthInMeters)} m`
        );

        function clearPolygon() {
          polyline.setMap(null);
        }

        google.maps.event.addDomListener(
          document.getElementById("CoordsButton"),
          "click",
          clearPolygon
        );
      }
    );

    // FUNÇÃO PARA TRABALHAR  OS DADOS DO POLIGONO
    google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      function (polygon) {
        let path = polygon.getPath();

        //METROS
        let area = google.maps.geometry.spherical.computeArea(path);
        area = Math.trunc(area);

        //HECTARE
        let ha = area / 10000;
        alert(ha.toFixed(2) + " ha");

        drawingManager.setMap(null); //SETAR AS OPÇÕES DE DESENHO

        function clearPolygon() {
          polygon.setMap(null);
          drawingManager.setMap(google.map);
        }
        google.maps.event.addDomListener(
          document.getElementById("CoordsButton"),
          "click",
          clearPolygon
        );
      }
    );

    if (this.props.map.operation_type === "covering") {
      let path = [];
      if (this.props.map.geojson !== "") {
        let geometry = JSON.parse(this.props.map.geojson);

        geometry.coordinates[0].map(function (item) {
          path.push({ lat: item[1], lng: item[0] });
          return { lat: item[1], lng: item[0] };
        });
      }
      // CONTRUIR O POLIGONO.
      let Polygon = new google.maps.Polygon({
        paths: [path],
        strokeColor: "#000000",
        strokeWeight: 5,
        fillColor: "#000000",
        fillOpacity: 0.4,
      });
      Polygon.setMap(google.map);
    } else {
      this.setFarmLandplots(google);
    }
    this.setPolyline(google);
  }; //FIM HANDLE

  setFarmLandplots = (google) => {
    this.props.landplots.forEach((lp) => {
      let geometry = JSON.parse(lp.geomap);
      let coords = geometry.coordinates[0].map(function (item) {
        return { lat: item[1], lng: item[0] };
      });
      let polygon = new google.maps.Polygon({
        paths: coords,
        strokeColor: "#00709E",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00709E",
        fillOpacity: 0.35,
      });
      polygon.setMap(google.map);

      let marker = new google.maps.Marker({
        position: lp.landplot_center,
        label: { text: lp.name, color: "white" },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0,
          labelOrigin: new google.maps.Point(0, 0),
        },
      });
      marker.setMap(google.map);
    });
  };

  getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: true,
      gestureHandling: "greedy",
      disableDoubleClickZoom: false,
      minZoom: 2,
      mapTypeId: maps.MapTypeId.SATELLITE,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.LEFT_TOP,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
      zoomControl: true,
      clickableIcons: false,
      fullscreenControlOptions: { position: 1 },
    };
  };

  handleControllCollapse() {
    let { collapsePoints } = this.state;

    this.setState({
      collapsePoints: !collapsePoints,
    });
  }

  handleMarkerCenter = (e, data) => {
    e.preventDefault();

    let { lat, lng } = data;

    this.setState({
      center: { lat: lat, lng: lng },
      mapZoom: 30,
    });
  };

  TabSpeed() {
    return (
      <li
        className={`nav-item`}
        onClick={() =>
          this.setState({
            isActiveSpeeds: true,
            isActiveStops: false,
            isActiveLandplots: false,
          })
        }
      >
        <a
          className="nav-link active"
          id="speed-table"
          data-toggle="tab"
          href="#speed"
          role="tab"
          aria-controls="speed"
          aria-selected="true"
          onClick={() => this.methodMarkersSpeedData()}
        >
          {this.props.t("Velocidade")}
        </a>
      </li>
    );
  }

  methodMarkersSpeedData() {
    this.props.map.lines.map((item) => {
      this.renderMarkersSpeedData(item);
    });
  }

  renderMarkersSpeedData(item) {
    let google = window.google;

    let marker;
    let markers = [];
    let infowindow = new google.maps.InfoWindow();

    item.path.map((data) => {
      if (data.show === true) {
        marker = new google.maps.Marker({
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            rotation: data.course,
            strokeColor: item.color,
            fillColor: item.color,
            scale: 3,
            strokeWeight: 3.5,
            fillOpacity: 0.9,
          },
          position: new google.maps.LatLng(data.lat, data.lng),
          map: google.map,
        });

        google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(
                `<div>
                    <strong style="font-weight: bold;">Velocidade:</strong> ${
                      data.speed
                    } <br>   
                    <br> 
                    <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                      data.time
                    )} min
                    <br>          
                </div>`
              );
              infowindow.open(google.map, marker);
            };
          })(marker)
        );

        markers.push(marker);

        function clearMarkers() {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }

        google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("stops-tab"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("clearPoints"),
          "click",
          clearMarkers
        );
      }
    });
  }

  TabsSpeedContent() {
    return (
      <div
        className="  table-responsive"
        id="speed"
        role="tabpanel"
        aria-labelledby="speed-table"
      >
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">{this.props.t("Data/Hora")}</th>
              <th scope="col">{this.props.t("Velocidade")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.map.lines.map((data) =>
              this.returnRowsTableSpeedData(data)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  returnRowsTableSpeedData(item) {
    return item.path.map((data, index) => {
      return data.show === true ? (
        <tr
          key={index}
          style={{
            fontSize: "13px",
            cursor: "pointer",
            backgroundColor: item.color,
          }}
          onClick={(e) => this.handleMarkerCenter(e, data)}
        >
          <td> {formatTime(data.time)}</td>
          <td> {data.speed}</td>
        </tr>
      ) : null;
    });
  }

  TabStops() {
    return (
      <li
        className={`nav-item `}
        onClick={() =>
          this.setState({
            isActiveStops: true,
            isActiveSpeeds: false,
            isActiveLandplots: false,
          })
        }
      >
        <a
          className="nav-link stopped-control"
          id="stops-tab"
          data-toggle="tab"
          href="#stops"
          role="tab"
          aria-controls="stops"
          aria-selected="false"
          onClick={() => this.setMarkersStoppedTime()}
        >
          {this.props.t("Paradas")}
        </a>
      </li>
    );
  }

  setMarkersStoppedTime() {
    let { map } = this.props;

    this.renderMarkersStoppedTime(map.stopped_points);
  }

  renderMarkersStoppedTime(item) {
    let google = window.google;
    let marker;
    let markers = [];
    let infowindow = new google.maps.InfoWindow();

    item.forEach((data) => {
      if (secondToMinute(data.stopped_time) >= 5) {
        marker = new google.maps.Marker({
          icon: data.status === "start" ? offlineIcon : stoppedIcon,
          position: new google.maps.LatLng(data.lat, data.lng),
          map: google.map,
        });

        google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(
                `<div>
                    <strong style="font-weight: bold;">Equipamento:</strong> ${
                      data.ident
                    } <br> 
                    <strong style="font-weight: bold;">Tempo:</strong> ${secondsToFormateTime(
                      data.stopped_time
                    )} min
                    <br> 
                    <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                      data.time
                    )}
                  </div>`
              );
              infowindow.open(google.map, marker);
            };
          })(marker)
        );

        markers.push(marker);

        function clearMarkers() {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }

        google.maps.event.addDomListener(
          document.getElementById("stops-tab"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("clearPoints"),
          "click",
          clearMarkers
        );
      }
    });
  }

  TableStoppedPoints() {
    let { map } = this.props;

    return (
      <div
        className="table-responsive"
        id="stops"
        role="tabpanel"
        aria-labelledby="stops-tab"
      >
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">{this.props.t("Data/Hora")}</th>
              <th scope="col">{this.props.t("P/D")}</th>
              <th scope="col">{this.props.t("Duração")}</th>
            </tr>
          </thead>
          <tbody>
            {map.stopped_points.map((data, index) => (
              <tr
                key={index}
                style={{ fontSize: "13px", cursor: "pointer" }}
                onClick={(e) => this.handleMarkerCenter(e, data)}
              >
                <td> {formatTime(data.time)}</td>
                <td> {data.status === "start" ? "D" : "P"}</td>
                <td> {secondsToFormateTime(data.stopped_time)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  TabLandplot() {
    return (
      <li
        className={`nav-item`}
        onClick={() =>
          this.setState({
            isActiveSpeeds: false,
            isActiveStops: false,
            isActiveLandplots: true,
          })
        }
      >
        <a
          className="nav-link active"
          id="landplot-table"
          data-toggle="tab"
          href="#landplot"
          role="tab"
          aria-controls="landplot"
          aria-selected="true"
          onClick={() => this.methodMarkersLandplots()}
        >
          {this.props.t("Talhão E/S")}
        </a>
      </li>
    );
  }
  methodMarkersLandplots() {
    this.props.map.lines.map((item) => {
      this.renderMarkerslandplots(item);
    });
  }

  renderMarkerslandplots(item) {
    let google = window.google;

    let marker;
    let markers = [];
    let infowindow = new google.maps.InfoWindow();

    item.path.map((data) => {
      if (data.io !== "") {
        marker = new google.maps.Marker({
          icon: data.io === "in" ? insideIcon : outIcon,
          position: new google.maps.LatLng(data.lat, data.lng),
          map: google.map,
        });

        google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(
                `<div>
                    <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                      data.time
                    )} min
                    <br>          
                </div>`
              );
              infowindow.open(google.map, marker);
            };
          })(marker)
        );

        markers.push(marker);

        function clearMarkers() {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }

        google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("stops-tab"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("clearPoints"),
          "click",
          clearMarkers
        );
      }
    });
  }

  TableLandplot() {
    return (
      <div
        className="table-responsive"
        id="landplot"
        role="tabpanel"
        aria-labelledby="landplot-tab"
      >
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">{this.props.t("Data/Hora")}</th>
              <th scope="col">{this.props.t("E / S")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.map.lines.map((data) =>
              this.returnRowsTableLandplots(data)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  returnRowsTableLandplots(item) {
    return item.path.map((data, index) => {
      return data.io !== "" ? (
        <tr
          key={index}
          style={{
            fontSize: "13px",
            cursor: "pointer",
            backgroundColor: data.io === "in" ? "#40bf40" : " #cc5200",
          }}
          onClick={(e) => this.handleMarkerCenter(e, data)}
        >
          <td> {formatTime(data.time)}</td>
          <td> {data.io === "in" ? "E" : "S"}</td>
        </tr>
      ) : null;
    });
  }

  verifyIsActiveTab() {
    this.setState({
      isActiveStops: false,
      isActiveSpeeds: false,
      isActiveLandplots: false,
    });
  }

  render() {
    return (
      <div>
        <div className="map_style">
          <GoogleMapReact
            options={this.getMapOptions}
            bootstrapURLKeys={{
              libraries: ["drawing", "places"],
              key: myConfig.GOOGLE_API_KEY,
            }}
            defaultCenter={this.state.center}
            center={this.state.center}
            zoom={this.state.mapZoom}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={this.handleGoogleMapApi.bind(this)}
            key={this.props.map_salt}
          />

          <button
            className="btn btn-sm btn-ligth collapsed-button-map "
            type="button"
            id="clearPoints"
            onClick={this.handleControllCollapse.bind(this)}
            style={{ borderRadius: "18px", width: "285px", height: "30px" }}
          >
            {this.state.collapsePoints === true ? (
              <p onClick={() => this.verifyIsActiveTab()}>
                {" "}
                {this.props.t("Esconder alertas")}
              </p>
            ) : (
              "Exibir alertas"
            )}
          </button>

          <Collapse isOpen={this.state.collapsePoints}>
            <div className="col collapsed-content" style={{ width: "285px" }}>
              <div className="x_content">
                <ul
                  className="nav nav-tabs bar_tabs"
                  role="tablist"
                  style={{ fontSize: "9px" }}
                >
                  {this.TabSpeed()}
                  {this.TabStops()}
                  {this.TabLandplot()}y
                </ul>
                <div className="tab-content" id="">
                  {this.state.isActiveSpeeds && this.TabsSpeedContent()}
                  {this.state.isActiveStops && this.TableStoppedPoints()}
                  {this.state.isActiveLandplots && this.TableLandplot()}
                </div>
              </div>
            </div>
          </Collapse>

          <button id="CoordsButton" className="clear_map btn btn-default">
            {this.props.t("Limpar Área")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MapContainer);
