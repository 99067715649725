import React , {Component } from "react";
import { useTranslation } from 'react-i18next';

const StepControl = props => {
    const { t } = useTranslation();

    let button , return_button = "";

    if(props.currentStep !== 0){
        return_button = <button className="btn btn-default pull-right " onClick={ props.handleBack }>{t('Voltar')}</button>;
    }

    if(props.currentStep === props.lastStep){
        button = <input type="submit" onClick={ props.handleSave } className="btn btn-primary  pull-right" value={t('Salvar')}></input>;
    }else{
        button = <button className="btn btn-primary  pull-right " onClick={ props.handleNext }>{t('Próximo')}</button>
    }        


    return(
        <div>
            {button}
            {return_button}
        </div>
    );
}


export default StepControl;