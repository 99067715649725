import React, {Component} from 'react'
import RadioButton from '../../../components/RadioButton';
import StepControl from './StepControl';
import { withTranslation } from 'react-i18next';

class FirstOperationStep extends Component{

    getOperationTypes(){
        let Operação_em_Talhão = this.props.t('Operação em Talhão')
        let Operação_em_Área  = this.props.t('Operação em Área Comum (Serviços Gerais)')
        let Multiplas_operações   = this.props.t('Multiplas operações')
        return {['covering']: Operação_em_Talhão,['recurrent']: Operação_em_Área,['multiples_operations']: Multiplas_operações};
    } 

    mountOperationTypes(){
        let types = this.getOperationTypes();
        let content = [];
        let current_value = this.props.current_type;
        let handleChange = this.props.handleCheck;        
  
        Object.keys(types).map(function(key, index) {          
          let checked = "false";          
          if(key === current_value){
            checked = "true";
          }          
        
          content.push(<RadioButton label={types[key]} class="pull-left radio-button no-position" name={"optradio_"+key} change={handleChange} check={checked} value={key}/>);
          
        });
  
        return content;
  
    }
  

    render(){
        return (
            <div className="first-op-step-container">
                {this.mountOperationTypes()}
                <StepControl handleNext={this.props.handleNext} handleBack={this.props.handleBack} lastStep={this.props.lastStep} currentStep={this.props.currentStep} />                     
            </div>
        )
    }
}

export default withTranslation()(FirstOperationStep);