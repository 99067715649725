import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import { FaEdit,FaTrashAlt,FaTractor,FaEye } from "react-icons/fa";


class Action extends Component {
    button = "";
    detail = "";

    constructor(props) {
        super(props);
        this.state = {
            status: this.props.status
        }
    }

    render() {

        this.state.status === 'finalizado' ?
            this.button = <button disabled="True" className="btn btn-primary btn-finalizar btn-xs btn-lg btn-sm disabled">{this.props.t('Finalizado')}</button> :
            this.button = <button onClick={(event) => this.props.handleFinished(event, this.props.id, this.props.operation_ident)} className="btn btn-primary btn-finalizar btn-xs btn-lg btn-sm">{this.props.t('Finalizar')}</button>;

        if (this.props.type === "covering") {
            this.detail = <li>
                <Link to={this.props.farm_id + "/operation/" + this.props.operation_id} className="btn"><i class="fas fa-eye"></i></Link>
            </li>;
        }

        return (
            <div>
                <div className="btn-group mobile-items" style={{ display: "none", padding: "6px" }}>
                    <button type="button" className="btn btn-primary btn-xs btn-lg btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <button className="btn btn-primary btn-xs btn-lg btn-sm">...</button>
                        <span className="caret"></span>
                        <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu" role="menu">
                        {this.detail}
                        <li>
                            <Link
                                to={this.props.farm_id + "/operation/" + this.props.operation_id + "/progress"}
                                className="btn"
                            >
                                <FaTractor />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={this.props.farm_id + "/operation/" + this.props.operation_id + "/change"}
                                className="btn"
                            >
                                <FaEdit />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={this.props.farm_id + "/operation/" + this.props.operation_id + "/delete"}
                                className="btn"
                            >
                                <FaTrashAlt />
                            </Link>
                        </li>
                    </ul>
                    {this.button}
                </div>
                <div className="desktop-items" style={{ display: "block" }}>
                    <Link
                        to={this.props.farm_id + "/operation/" + this.props.operation_id}
                        className="btn"
                    >
                        <FaEye />
                    </Link>
                    <Link
                        to={this.props.farm_id + "/operation/" + this.props.operation_id + "/progress"}
                        className="btn"
                    >
                        <FaTractor />
                    </Link>
                    <Link
                        to={this.props.farm_id + "/operation/" + this.props.operation_id + "/change"}
                        className="btn"
                    >
                        <FaEdit />
                    </Link>
                    <Link
                        to={this.props.farm_id + "/operation/" + this.props.operation_id + "/delete"}
                        className="btn">
                        <FaTrashAlt />
                    </Link>
                    {this.button}
                </div>
            </div>
        );
    }
}

export default withTranslation()(Action);
