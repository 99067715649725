import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ListMachine from "./ListMachine";
import FormMachine from "./FormMachine";
import DailyReport from "./DailyReport";
import Appointments from "./Appointments";
import DailyList from '../Mobile/DailyMachine'

function MachineRoutes() {

  return (
    <Switch>
      <Route
        path="/:farm_id/equipment/machine/:machine_id/report"
        component={DailyReport}
      />
      <Route
        path="/:farm_id/equipment/machine/report/daily"
        component={DailyList}
      />
      <Route
        path="/:farm_id/equipment/machine/:machine_id/appointments"
        component={Appointments}
      />
      <Route
        path="/:farm_id/equipment/machine/:machine_id"
        component={FormMachine}
      />
      <Route
        path="/:farm_id/equipment/machine/:machine_id"
        component={FormMachine}
      />
      <Route
        path="/:farm_id/equipment/machine/add"
        component={FormMachine}
        machine_id={-1}
      />
      <Route path="/:farm_id/equipment/machine/" component={ListMachine} />
    </Switch>
  );
}

export default withRouter(MachineRoutes);
