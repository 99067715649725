import React from "react";
// import { makeStyles } from '@material-ui/core/styles';
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withTranslation } from "react-i18next";

import { FaBell } from "react-icons/fa";
import "./Operation.css";

function SimpleModal(props) {
  // STATE
  const [open, setOpen] = React.useState(false);
  const [alerts] = React.useState(props.alerts);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p onClick={handleOpen}>
        {/* <i className="fas fa-bell ico-alert"></i> */}
        <FaBell className="ico-alert" />
        {props.t("Ver todos alertas")}
      </p>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="modal-size">
          <div className="x_title">
            <h5 align="center">{props.t("Alertas")}</h5>
            <div class="clearfix"></div>
          </div>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{props.t("Descrição")}</TableCell>
                <TableCell align="center"> {props.t("Criado em")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.map((alert) => (
                <TableRow key={alert.id}>
                  <TableCell>{alert.description}</TableCell>
                  <TableCell>{alert.created}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(SimpleModal);
