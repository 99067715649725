import React, { useState, useEffect } from "react";

import SelectBox from "../../components/SelectBox";
import MaskedInput from "react-text-mask";

import axios from "axios";
import myConfig from "../../configs/config";

import { Link, withRouter, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import logoIcon from "../../assets/images/logo.png";

import "./Account.css"; //CSS

function Signup(props) {
  let history = useHistory();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [cell_phone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [accepted_terms, setAcceptedTerms] = useState(true);
  const [error, setError] = useState(false);
  const [listStates, setListStates] = useState([]);
  const [state, setState] = useState("");
  const [listCities, setListCities] = useState([]);
  const [city, setCity] = useState("");

  useEffect(() => {
    document.title = "Agriconnected - Registre-se";

    loadStates();
  }, []);

  useEffect(() => {
    loadCities();
  }, [listStates]);

  async function loadCities() {
    if (state.length !== 0) {
      let CITY_URL = `${myConfig.API_URL}/city/?state=${state}`;

      let response = await axios.get(CITY_URL);

      let data = response.data;

      if (data) {
        setListCities(data);
      }
    }
  }
  async function loadStates() {
    let STATE_URL = `${myConfig.API_URL}/state`;

    let response = await axios.get(STATE_URL);

    let data = response.data;

    if (data) {
      setListStates(data);
    }
  }

  function getFormData() {
    let form_data = new FormData();

    form_data.append("first_name", first_name);
    form_data.append("last_name", last_name);
    form_data.append("cell_phone", cell_phone);
    form_data.append("city", city);
    form_data.append("state", state);
    form_data.append("email", email);
    form_data.append("password", password);
    form_data.append("accepted_terms", accepted_terms);

    return form_data;
  }

  function checkPassword() {
    let error_msg = "O campo senha e confirmar senha precisam ser idênticos";
    if (password !== confirm_password) {
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(true);
    }
  }

  async function handleSave(e) {
    e.preventDefault();

    let CREATE_USER = `${myConfig.API_URL}/accounts/`;

    await checkPassword();

    try {
      if (error === false) {
        let response = await axios({
          baseURL: CREATE_USER,
          method: "POST",
          data: getFormData(),
        });
        if (response.status === 201) {
          toast.success(
            `${props.t(
              "Enviamos um link para confirmação de email, por favor verifique a sua caixa de entrada."
            )}!`,
            {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setTimeout(() => {
            history.push(`/account/login/`);
          }, 2000);
        }
      }

      window.scrollTo(0, 0);
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function handleSelected(e) {
    e.preventDefault();

    setState(e.target.value);

    let CITY_URL = `${myConfig.API_URL}/city/?state=${e.target.value}`;

    let response = await axios({
      baseURL: CITY_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setListCities(data);
  }

  return (
    <>
      <div id="signup_profile_container">
        <div className="row" style={{ marginTop: "1vh" }}>
          <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <h1 align="center">Crie uma conta</h1>

                <form action="POST" onSubmit={handleSave}>
                  <div className="form-group">
                    <label htmlFor="id_first_name" className="labels">
                      {props.t("Nome")}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      name="first_name"
                      value={first_name}
                      maxLength={35}
                      className="form-control"
                      placeholder="Nome"
                      required
                      id="id_first_name"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="id_last_name" className="labels">
                      {props.t("Sobrenome")}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      name="last_name"
                      value={last_name}
                      maxLength={35}
                      className="form-control"
                      placeholder="Sobrenome"
                      id="id_last_name"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="id_cell_phone" className="labels">
                      {props.t("Celular")}
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /[1-9]/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      name="cell_phone"
                      onChange={(e) => setCellphone(e.target.value)}
                      value={cell_phone}
                      maxLength={16}
                      className="form-control"
                      placeholder="Telefone celular"
                      id="id_cell_phone"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="id_email" className="labels">
                      {props.t("Email")}
                    </label>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      value={email}
                      className="form-control"
                      placeholder="Endereço de e-mail"
                      required
                      id="id_email"
                    />
                  </div>

                  {listStates.length > 0 ? (
                    <SelectBox
                      strong="true"
                      data={listStates}
                      selected_value={state}
                      value={"name"}
                      change={handleSelected}
                      label="Estados"
                      id="id_state"
                    />
                  ) : (
                    <div>
                      <label htmlFor="">Estados</label>
                      <select name="" className="form-control">
                        <option value="">-------------</option>
                      </select>
                    </div>
                  )}

                  {listCities.length > 0 ? (
                    <SelectBox
                      strong="true"
                      data={listCities}
                      selected_value={listCities}
                      value={"name"}
                      change={(e) => setCity(e.target.value)}
                      label="Cidades"
                    />
                  ) : (
                    <div>
                      <label htmlFor="">Cidades</label>
                      <select name="" className="form-control">
                        <option value="">-------------</option>
                      </select>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="id_password" className="labels">
                      {props.t("Senha")}
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      value={password}
                      maxLength={35}
                      className="form-control"
                      placeholder="Senha"
                      required
                      id="id_password"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="id_c_password" className="labels">
                      {props.t("Confirmar Senha")}
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      name="c_password"
                      value={confirm_password}
                      maxLength={35}
                      className="form-control"
                      placeholder="Confirmar senha"
                      required
                      id="id_c_password"
                    />
                  </div>

                  <div align="center">
                    <input
                      type="submit"
                      className="botao_login btn btn-primary"
                      value="Registrar"
                    />
                  </div>
                </form>
                <Separator />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default withTranslation()(withRouter(Signup));

function Separator() {
  return (
    <div className="separator" align="center">
      <p> Já possui conta? </p>

      <Link to="/account/login/" className="to_register" align="center">
        <a className="botao_login">Entrar</a>
      </Link>

      <div className="logo-agri">
        <img
          src={logoIcon}
          alt="logo-mini-agriconnected"
          className="logo-mini"
        />
      </div>

      <p>
        ©2019 Todos Direitos Reservados. Política de Privacidade e Termos de Uso
      </p>
    </div>
  );
}
