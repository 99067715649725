import React from "react";

import axios from "axios";
import myConfig from "../../configs/config";
import { Link, useHistory } from "react-router-dom";

import { FaTrashAlt } from "react-icons/fa";

import "./Landplot.css"; //CSS

export default function CardLandplot(props) {
  const history = useHistory();

  function handleDelete(e) {
    let DELETE_LANDPLOT = `${myConfig.API_URL}/landplot/${props.landplot.id}/`;

    if (
      !window.confirm(
        `Realmente deseja excluir o talhão: ${props.landplot.name} ?`
      )
    ) {
      return 0;
    } else {
      axios({
        baseURL: DELETE_LANDPLOT,
        method: "delete",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      }).then((res) => {
        alert("Talhão deletado com sucesso !");
        window.scrollTo(0, 0);
        history.go(`/${props.farm_id}/landplots/`);
      });
    }
  }

  const img_url =
    "https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=250x250&maptype=satellite&sensor=false&path=color:red|weight:1|fillcolor:red|" +
    props.landplot.pipeCoords +
    "&key=" +
    myConfig.GOOGLE_API_KEY;

  return (
    <div>
      <div className="col-md-3 col-sm-6 col-xs-12">
        <div className="x_panel no-margin-top h-250  z-depth-2  min-card-fixed">
          <div className="view view-first">
            <Link
              to={`/${props.farm_id}/landplots/${props.landplot.id}/change`}
            >
              <img
                className="image"
                alt=""
                src={
                  props.landplot.picture !== null
                    ? props.landplot.picture
                    : img_url
                }
              />
            </Link>
          </div>
          <div className="landplot_subtitle point">
            <Link
              className="point"
              to={`/${props.farm_id}/landplots/${props.landplot.id}/change`}
            >
              <p className="pull-left w-170 point">
                {props.landplot.name} - {props.landplot.area_h2} ha
              </p>
            </Link>
            <a
              className="btn-show-options pointer point"
              onClick={handleDelete}
            >
              <FaTrashAlt className="pull-right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
