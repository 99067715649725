import React from 'react';

const Kpi_gadget_content = (props) => {

    if(props.first_value){
        return(
            <div className="kpi_gadget_content ">            
                <p className={props.class + " no-margin-bottom"}> {props.value +  " " + props.label} </p>            
            </div>            
        );
    }else{
        return(
            <div className="kpi_gadget_content ">            
                <p className={props.class + " no-margin-bottom"}> {props.label +  " " + props.value} </p>            
            </div> 
        );           
    }    
}

export default Kpi_gadget_content;
