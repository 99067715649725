import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ListAdmin from './List_Admin';
import FormAdmin from './Form_Admin'

class Routes extends Component {

    render() {
        return (
            <Switch>
                <Route path="/:farm_id/administrator/add" component={FormAdmin} />} />
                <Route path="/:farm_id/administrator/" component={ListAdmin} />} />
            </Switch>

        )
    }
}

export default withRouter(Routes)