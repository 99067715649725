import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import myConfig from "../../configs/config";
import SendPassword from "./SendPassword";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

function ResetPasswordForm(props) {
  document.title = "Agriconnected - Recuperar Senha";

  const [email, setEmail] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  function getFormData() {
    let form_data = new FormData();

    form_data.append("email", email);

    return form_data;
  }

  function handleSubmit(e) {
    e.preventDefault();

    let RESET_PASSWORD = `${myConfig.API_URL}/accounts/reset_password/`;

    axios({
      baseURL: RESET_PASSWORD,
      method: "POST",
      data: getFormData(),
    })
      .then((response) => {
        // console.log(response.data);
        if (response.status === 201) {
          // console.log(response.data);
          setSendEmail(true);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        let error_msg = "Usuário não encontrado, por favor tente novamente.";

        toast.error(error_msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    window.scrollTo(0, 0);
  }

  return (
    <>
      {sendEmail ? (
        <SendPassword />
      ) : (
        <div className="row">
          <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6 col-xs-12">
            <div
              className="x_panel"
              style={{ borderRadius: "10px", paddingBottom: "10vh" }}
            >
              <div className="x_content">
                <form onSubmit={handleSubmit}>
                  <h3 align="center">{props.t("Recuperação de senha")}</h3>
                  <div className="col-xs-12 ">
                    <br />
                    <div className="input-group">
                      <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        id="email"
                        value={email}
                        placeholder="Digite o seu e-mail"
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                          paddingRight: "15px",
                          height: "32px",
                        }}
                      />{" "}
                      <span className="input-group-btn">
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value="Recuperar senha"
                          style={{
                            borderRadius: "8px",
                            marginLeft: "16px",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default withTranslation()(ResetPasswordForm);
