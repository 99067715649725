import React, { useState, useEffect } from "react";
import axios from "axios";
import myConfig from "../../configs/config";
import MDSpinner from "react-md-spinner";
import { withTranslation } from "react-i18next";
import ExportButton from "../../components/Export_button";
import AddButton from "../../components/Add_button";
import { isChildrenEmpty } from "../../helpers/utils";
import { Link } from "react-router-dom";

import { FaEdit, FaTrashAlt } from "react-icons/fa";

function ListImplement(props) {
  const farmId = props.match.params.farm_id;

  const [listImplements, setListImplements] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getImplements() {
    try {
      let IMPLEMENTS_URL = `${myConfig.API_URL}/implement/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: IMPLEMENTS_URL,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      let data = response.data;

      if (data) {
        setListImplements(data);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar implementos, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  function handleDelete(e, { id, ident }) {
    e.preventDefault();
    // console.log(id);
    let DELETE_IMPLEMENT = `${myConfig.API_URL}/implement/${id}/`;

    if (!window.confirm(`Realmente deseja excluir o implemento: ${ident} ?`)) {
      return 0;
    } else {
      axios({
        baseURL: DELETE_IMPLEMENT,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      }).then((res) => {
        window.scrollTo(0, 0);
        let allImplements = listImplements.filter(
          (implement) => implement.id !== id
        );

        setListImplements(allImplements);
      });
    }
  }

  useEffect(() => {
    document.title = "Agriconnected - Implementos";

    getImplements();
  }, []);

  return (
    <>
      {loading === false ? (
        <div id="implement_list_container">
          <div className="x_title">
            <h2>{props.t("Implementos")}</h2>
            <div className="clearfix"></div>
          </div>

          <AddButton
            url={`/${farmId}/equipment/implement/add/`}
            classname="btn btn-primary btn-default-add"
          />
          <ExportButton
            classname="btn btn-default-export"
            export={`${myConfig.API_URL}/implement/export_implement/?farm_id=${farmId}`}
          />

          {isChildrenEmpty(
            listImplements,
            <h4 align="center">
              {props.t(
                "Está fazenda ainda não possui nenhum implemento cadastrado."
              )}
            </h4>,
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{props.t("Identificação")}</th>
                  <th>{props.t("Marca")}</th>
                  <th className="text-center">{props.t("Ação")}</th>
                </tr>
              </thead>
              <tbody>
                {listImplements.map((implement) => (
                  <tr key={implement.id}>
                    <td scope="row">{implement.ident}</td>
                    <td>{implement.marca}</td>
                    <td align="center">
                      <Link
                        to={`/${farmId}/equipment/implement/${implement.id}/`}
                        className="btn"
                      >
                        <FaEdit />
                      </Link>
                      <a
                        onClick={(event) => handleDelete(event, implement)}
                        className="btn"
                      >
                        <FaTrashAlt />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <div
          style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}
        >
          <MDSpinner />
        </div>
      )}
    </>
  );
}

export default withTranslation()(ListImplement);
