import React from 'react';

const Kpi_gadget_footer = (props) => {
    return(
        <div className="kpi_gadget_footer">        
            <div><span className={props.class + " count_top"} >{props.label}</span><span className={props.class}>{props.value}</span></div>
        </div>
    );
}

export default Kpi_gadget_footer;
