import React from "react";

function Index(props) {
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {props.headers.map((item) => (
              <th>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </>
  );
}

export default Index;
