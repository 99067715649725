import React, { Component } from "react";
import Action from "../containers/Operation/Action";
import ProgressBar from "../components/Progress_bar";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

/*
 *   version : 0.2
 *   @object_name => name of state object
 *   @columns_name => columns of an table
 *   @columns_values => index of object values
 *   sample : <Table columns_name={['Operação', 'Inicio', 'Status', 'Ação']} object_name="operations" {...this.state} columns_values={['ident','dt_ini','status']}/>
 */

class Table extends Component {
  checkComponent(obj, values, props) {
    let { operations_checked } = props;

    let checked = false;

    if (operations_checked.length > 0) {
      for (let i = 0; i < operations_checked.length; i++) {
        if (operations_checked[i] === obj.id) {
          checked = true;
        }
      }
    }

    switch (values) {
      case "status":
        return (
          <td key="status" className="indicator-collumn w-90">
            <i className={obj.status.class + " status-indicator"}></i>
            <div className="hidden-xs hidden-sm">{obj.status.status}</div>
          </td>
        );
      case "action":
        return (
          <td>
            <Action
              handleFinished={props.handleFinished}
              farm_id={props.farm_id}
              operation_id={obj.id}
              operation_ident={obj.ident}
              type={obj.operation_type}
              status={obj.status.status}
            ></Action>
          </td>
        );
      case "finish":
        let button = "";
        let view_content_button = "";

        if (obj.status.status === "finalizado") {
          button = (
            <button
              disabled="True"
              className="btn btn-primary btn-finalizar btn-xs btn-lg btn-sm mt-5 disabled"
            >
              {this.props.t("Finalizado")}
            </button>
          );
        } else {
          button = (
            <button
              onClick={(event) => props.handleFinished(event, obj.id)}
              className="btn btn-primary btn-finalizar mt-5 btn-xs btn-lg btn-sm"
            >
              {this.props.t("Finalizar")}
            </button>
          );
        }

        view_content_button = (
          <div className="pull-left">
            <Link
              to={`/${props.farm_id}/operation/${obj.id}/progress`}
              className="btn"
            >
              <FaEye />
            </Link>
          </div>
        );

        return (
          <td key="">
            {view_content_button}
            {button}
          </td>
        );
      case "progress":
        return (
          <td>
            {" "}
            <ProgressBar
              operation_id={obj.id}
              progress={obj["worked"]}
              farm_id={props.farm_id}
              color={obj.status.class}
              has_effect="true"
            />
          </td>
        );
      case "ident-created":
        return (
          <>
            <td key={0}>
              <input
                type="checkbox"
                className="check"
                onChange={(event) => {
                  props.handleChecked(event, obj.id);
                }}
                name={obj.id}
                checked={checked}
              />
            </td>
            <td key={1}>
              <Link
                className="operation_item_list"
                to={`/${props.farm_id}/operation/${obj.id}/progress`}
              >
                {obj["ident"]} <br />{" "}
                {new Date(obj["created"]).toLocaleString("pt-BR")}
              </Link>
            </td>
          </>
        );
      case "date-init-time":
        return (
          <td key="dt_ini">
            {new Date(obj["dt_ini"]).toLocaleString("pt-BR")}{" "}
          </td>
        );
      default:
        return <td>{obj[values]}</td>;
    }
  }

  setStyleOfElement(el, prop, val) {
    if (el.length > 0) {
      for (let i = 0; i < el.length; i++) {
        el[i].style[prop] = val;
      }
    } else {
      el.style[prop] = val;
    }
  }

  componentDidMount() {
    let size = window.innerWidth;
    let mobile_items = document.getElementsByClassName("mobile-items");
    let desktop_items = document.getElementsByClassName("desktop-items");

    if (mobile_items.length > 0 && desktop_items.length > 0) {
      if (size < 700) {
        this.setStyleOfElement(mobile_items, "display", "block");
        this.setStyleOfElement(desktop_items, "display", "none");

        let status_label = document.getElementsByClassName("indicator-collumn");

        for (let $i = 0; $i < status_label.length; $i++) {
          let class_name = status_label[$i].childNodes[0].className;
          status_label[$i].innerHTML = "<i class='" + class_name + "'></i>";
        }
      } else {
        this.setStyleOfElement(mobile_items, "display", "none");
        this.setStyleOfElement(desktop_items, "display", "block");
      }
    }
  }

  render() {
    if (this.props.errors.length > 0) {
      return (
        <div>
          {this.props.errors.map((error) => (
            <div>{error}</div>
          ))}
        </div>
      );
    }

    if (this.props[this.props.object_name].length === 0) {
      return (
        <table className="table">
          <thead>
            <tr>
              {this.props.columns_name.map((name) => (
                <th>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>{this.props.t("Nenhuma operação encontrada")}.</tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <div style={{ overflowX: "auto" }}>
          <table className="table table_operation_list">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="check"
                    onChange={(event) => {
                      this.props.handleCheckedAllOperations(event);
                    }}
                  />
                </th>
                {this.props.columns_name.map((name) => (
                  <th key={name}>{name}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {this.props[this.props.object_name].map((obj) => (
                <tr key={obj.id}>
                  {this.props.columns_values.map((values) =>
                    this.checkComponent(obj, values, this.props)
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default withTranslation()(Table);
