import React from "react";

const CardContent = ({ data }) => (
  <div className="col-md-6 col-sm-12 col-xs-12">
    <div className="x_panel mt-10">
      {data.map((item) => (
        <p key={item.title}>
          {item.title}: <b>{item.value}</b>
        </p>
      ))}
    </div>
  </div>
);

export default CardContent;
