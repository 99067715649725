import React, { Component } from "react";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { any } from "prop-types";

import {
  FaCaretUp,
  FaCaretDown,
  FaFilter,
  
} from "react-icons/fa";

class Toggle_component extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.handleShowContent = this.handleShowContent.bind(this);
  }

  handleShowContent() {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  }

  render() {
    const contentClass = this.state.isVisible ? "showContent" : "hiddenContent";
    const icon = this.state.isVisible
      ? "fas fa-caret-up "
      : "fas fa-caret-down";
    let subtitle = this.state.isVisible ? this.props.subtitle : "";
    // let search;

    // let arrow =
    //  ;

    // if (this.props.hide_search !== "True") {
    //   search = (
       
    //   );
    // } else {
    //   search = (
    //     <div onClick={this.handleShowContent}>
    //       <FaFilter
    //         className="glyphicon glyphicon-filter"
    //         id="collapse-button"
    //       />
    //       <span>{this.props.t("Filtrar")}</span>
    //       <FaCaretDown />
    //     </div>
    //   ); ;
    // }

    let size = window.innerWidth;

    if (size < 700) {
      subtitle = "";
    }

    return (
      <div className="toggle-component">
        <h5 className="d-inline">{this.props.title}</h5>
        {/* {search} */}
        { <div  onClick={this.handleShowContent}>
          <FaFilter
           
            className="glyphicon glyphicon-filter"
            id="collapse-button"
          />
          <span>{this.props.t("Filtrar")}</span>
          {/* {arrow} */}
          {this.state.isVisible ? <FaCaretUp /> : < FaCaretDown/>}
          <span className="toggle-subtitle">{subtitle}</span>

        </div>
        }
        <div
          className={"content" + contentClass}
          style={{ display: this.state.isVisible ? "block" : "none" }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default withTranslation()(Toggle_component);
