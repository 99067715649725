import React, { Component } from "react";
import Input from "../../Input";
import StepControl from "./StepControl";
import { withTranslation } from "react-i18next";
import AgriDateTime from "../../AgriDateTime";
import { getTime } from "../../../helpers/utils";

class ThirdOperationStep extends Component {
  validateThirdStep() {
    return;
  }
  render() {
    let error_component;
    if (this.props.error_fields !== "") {
      error_component = this.validate();
    }

    return (
      <div className="col-xs-12 col-md-12 col-lg-12">
        <div className="form-group">
          <AgriDateTime
            datetimeformat="true"
            selectedDate={this.props.init_date}
            time={getTime(this.props.init_date)}
            handleTimeChange={this.props.handleTimeChange}
            handleDateChange={this.props.handleDateChange}
            label={this.props.t("Data de inicio da operação")}
          />
        </div>

        <div className="form-group">
          <Input
            handleChange={this.props.handleChangeText}
            class="form-control"
            value={this.props.daily_hours}
            type="number"
            label={this.props.t("Jornada (horas trabalhadas por dia)")}
            name="daily_hours"
          />
          {error_component}
        </div>
        <StepControl
          handleNext={this.props.handleNext}
          handleBack={this.props.handleBack}
          lastStep={this.props.lastStep}
          currentStep={this.props.currentStep}
        />
      </div>
    );
  }

  validate() {
    let fields = [
      {
        field: "daily_hours",
        error: this.props.t(
          "Horas trabalhadas por dia o valor precisa ser maior que zero"
        ),
      },
    ];

    let component_err = "";

    this.props.error_fields.map((error) => {
      if (fields[0].field === error) {
        component_err = (
          <div className="alert alert-danger">{fields[0].error}</div>
        );
      }
    });
    return component_err;
  }
}

export default withTranslation()(ThirdOperationStep);
