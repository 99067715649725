import React from "react";

// CORE
import { Grid, Paper, Hidden } from "@material-ui/core/";

//
import { Link, withRouter, useParams } from "react-router-dom";

// import { withTranslation } from "react-i18next";

// ICONS
import ExploreIcon from "@material-ui/icons/Explore";
import MenuIcon from "@material-ui/icons/Menu";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BuildIcon from "@material-ui/icons/Build";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";

// STYLES
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "#4d4b4b",
    height: "20vh",
    "&:hover": {
      border: "4px solid #2c995b",
      transition: 0.2,
    },
  },
  icon: {
    marginTop: theme.spacing(3),
    color: "#fff",
  },
  textCards: {
    color: "#fff",
  },
}));

function Index() {
  const classes = useStyles();
  const params = useParams();

  return (
    <div className={classes.root}>
      <Hidden only={["sm", "lg", "md"]}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/dashboard/map`}>
              <Paper className={classes.paper}>
                <ExploreIcon
                  className={classes.icon}
                  style={{ fontSize: 50 }}
                />

                <Typography variant="h6" noWrap className={classes.textCards}>
                  Mapa
                </Typography>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/dashboard/kpis`}>
              <Paper className={classes.paper}>
                <MenuIcon className={classes.icon} style={{ fontSize: 50 }} />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Painel
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>

          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/operation/`}>
              <Paper className={classes.paper}>
                <AssignmentTurnedInIcon
                  className={classes.icon}
                  style={{ fontSize: 50 }}
                />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Operações
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/equipment/machine/report/daily`}>
              <Paper className={classes.paper}>
                <VisibilityIcon
                  className={classes.icon}
                  style={{ fontSize: 50 }}
                />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Diário
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/report/path/`}>
              <Paper className={classes.paper}>
                <AssignmentIcon
                  className={classes.icon}
                  style={{ fontSize: 50 }}
                />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Personalizado
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/appointments/`}>
              <Paper className={classes.paper}>
                <BuildIcon className={classes.icon} style={{ fontSize: 50 }} />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Apontamentos
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/registers/`}>
              <Paper className={classes.paper}>
                <ControlPointIcon
                  className={classes.icon}
                  style={{ fontSize: 50 }}
                />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Novo
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} lg={4} sm={6}>
            <Link to={`/${params.farm_id}/lists/`}>
              <Paper className={classes.paper}>
                <PermContactCalendarIcon
                  className={classes.icon}
                  style={{ fontSize: 50 }}
                />
                <Typography variant="h6" noWrap className={classes.textCards}>
                  Cadastros
                </Typography>{" "}
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
}
export default withRouter(Index);
