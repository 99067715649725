import React, { useState, useEffect } from "react";
import SelectBox from "../../components/SelectBox";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";
import myConfig from "../../configs/config";
import Switch from "react-switch";
import { withTranslation } from "react-i18next";
import { Link, withRouter, useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { ToastContainer, toast } from "react-toastify";

import "./Account.css"; //CSS

function FormUpdateUser(props) {
  document.title = "Agriconnected - Perfil";

  const history = useHistory();

  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [cell_phone, setCellphone] = useState("");
  const [picture, setPicture] = useState("");
  const [listCities, setListCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [listState, setListStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [notification, setNotification] = useState([
    {
      sms_init: false,
      sms_landplot_enter: true,
      sms_landplot_exit: true,
      sms_speed_below: true,
      sms_speed_above: true,
      sms_stopped: false,
      push_init: true,
      push_landplot_enter: true,
      push_landplot_exit: true,
      push_speed_above: true,
      push_speed_below: true,
      push_stopped: true,
      daily_summary: true,
      sms_powercut: false,
    },
  ]);
  const timezones = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];
  const [selectedTimezone, setSelectedTimezone] = useState("");
  
  async function handleGetAllStates() {
    try {
      const STATE_URL = `${myConfig.API_URL}/state/`;

      let response = await axios({
        baseURL: STATE_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      let data = response.data;
      setListStates(data);
    } catch (error) {
      let error_msg =
        "Falha ao carregar lista de Estados, recarregue a página ou tente novamente mais tarde.";

      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // SHOW TOAST
    }
  }

  async function handleGetAllCities() {
    try {
      let CITY_URL = `${myConfig.API_URL}/city/?state=${selectedState}`;

      if (listState.length > 0) {
        let response = await axios({
          baseURL: CITY_URL,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("client-token")}`,
          },
        });
        let data = response.data;
        setListCities(data);
      }
    } catch (error) {
      let error_msg =
        "Falha ao carregar lista de cidades, recarregue a página ou tente novamente mais tarde.";

      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // SHOW TOAST
    }
  }

  async function handleGetDataFromCurrentUser() {
    try {
      const UPDATE_USER = `${myConfig.API_URL}/accounts/me/`;

      let response = await axios({
        baseURL: UPDATE_USER,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let {
        id,
        email,
        notification,
        first_name,
        last_name,
        cell_phone,
        state,
        city,
        picture,
        time_zone,
      } = response.data;

      setUserId(id);
      setEmail(email);
      setNotification(notification);
      setFirstName(first_name);
      setLastName(last_name);
      setCellphone(cell_phone);
      setSelectedState(state);
      setSelectedCity(city);
      setSelectedTimezone(time_zone);
      setPicture(picture);
    } catch (error) {
      let error_msg =
        "Falha ao carregar dados do usuário, recarregue a página ou tente novamente mais tarde.";

      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // SHOW TOAST
    }
  }

  useEffect(() => {
    handleGetAllCities();
  }, [selectedState]);

  useEffect(() => {
    handleGetDataFromCurrentUser();

    handleGetAllStates();
  }, []);

  async function handleSelected(e) {
    e.preventDefault();

    setSelectedState(e.target.value);

    try {
      let CITY_URL = `${myConfig.API_URL}/city/?state=${e.target.value}`;

      let response = await axios({
        baseURL: CITY_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      setListCities(data);
    } catch (error) {
      let error_msg =
        "Falha ao carregar lista de cidades, recarregue a página ou tente novamente mais tarde.";

      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleChecked(value, name) {
    setNotification((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function getFormData() {
    let form_data = new FormData();

    if (typeof picture === "object" && picture !== null) {
      form_data.append("picture", picture, picture.name);
    }
    form_data.append("first_name", first_name);
    form_data.append("last_name", last_name);
    form_data.append("cell_phone", cell_phone);
    form_data.append("city", selectedCity);
    form_data.append("state", selectedState);
    form_data.append("email", email);
    form_data.append("accepted_terms", true);
    form_data.append("notification", JSON.stringify(notification));
    form_data.append("time_zone", selectedTimezone);

    return form_data;
  }

  async function updateUserData() {
    let UPDATE_USER = `${myConfig.API_URL}/accounts/${userId}/`;
    try {
      let response = await axios({
        baseURL: UPDATE_USER,
        method: "PUT",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 200) {
        toast.success(`Dados atualizados com sucesso!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.go(`/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " - ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // SHOW TOAST
    }
  }

  function handleSave(e) {
    e.preventDefault();

    updateUserData();
  }

  return (
    <div id="profile_update_container">
      <form method="POST" onSubmit={handleSave} encType="multipart/form-data">
        <h2> {props.t("Editar perfil")} </h2>
        <div className="avatar-user">
          <AvatarEditor
            className="avatar"
            image={picture}
            width={120}
            height={120}
            style={{ border: "1px solid #f2f2f2f2" }}
            border={50}
            borderRadius={120 / (100 / 50)} //BORDER FOCUS 50%
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_picture">{props.t("Foto")}</label>
          <input
            type="file"
            name="picture"
            onChange={(e) => setPicture(e.target.files[0])}
            title={props.t("Insira uma foto")}
            id="id_picture"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_first_name">{props.t("Nome")}</label>
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            name="first_name"
            value={first_name}
            maxLength={35}
            className="form-control"
            placeholder={props.t("Nome")}
            required
            id="id_first_name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_last_name">{props.t("Sobrenome")}</label>
          <input
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            name="last_name"
            value={last_name}
            maxLength={35}
            className="form-control"
            placeholder={props.t("Sobrenome")}
            id="id_last_name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_cell_phone">{props.t("Celular")}</label>
          <MaskedInput
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            name="cell_phone"
            onChange={(e) => setCellphone(e.target.value)}
            value={cell_phone}
            maxLength={16}
            className="form-control"
            placeholder={props.t("Celular")}
          />
        </div>

        {listState.length > 0 ? (
          <SelectBox
            strong="true"
            data={listState}
            selected_value={selectedState}
            value={"name"}
            change={handleSelected}
            label="Estados"
            id="id_state"
          />
        ) : (
          <div>
            <label htmlFor="">{props.t("Estados")}</label>
            <select name="" className="form-control">
              <option value="">-------------</option>
            </select>
          </div>
        )}

        {listCities.length > 0 ? (
          <SelectBox
            strong="true"
            data={listCities}
            selected_value={selectedCity}
            value={"name"}
            change={(e) => setSelectedCity(e.target.value)}
            label={props.t("Cidades")}
          />
        ) : (
          <div>
            <label>{props.t("Cidades")}</label>
            <select className="form-control">
              <option value="">-------------</option>
            </select>
          </div>
        )}

        <SelectBox
            strong="true"
            data={timezones.map(it => { return {id: it, name: it}})}
            selected_value={selectedTimezone}
            value={"name"}
            change={(e) => setSelectedTimezone(e.target.value)}
            label={props.t("Time Zone")}
          />

        {/* TABELA */}
        <div className="form-group">
          <div className="">
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th>{props.t("Notificações")}</th>
                  <th> {props.t("WHATSAPP")}</th>
                  <th> {props.t("PUSH")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{props.t("Início")}</td>

                  <td>
                    <label>
                      <Switch
                        onChange={(value) => handleChecked(value, "sms_init")}
                        name="sms_init"
                        checked={notification.sms_init}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) => handleChecked(value, "push_init")}
                        name="push_init"
                        checked={notification.push_init}
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>{props.t("Entrada")}</td>

                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "sms_landplot_enter")
                        }
                        name="sms_landplot_enter"
                        checked={notification.sms_landplot_enter}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "push_landplot_enter")
                        }
                        value="push_landplot_enter"
                        checked={notification.push_landplot_enter}
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>{props.t("Saída")}</td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "sms_landplot_exit")
                        }
                        name="sms_landplot_exit"
                        checked={notification.sms_landplot_exit}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "push_landplot_exit")
                        }
                        value="push_landplot_exit"
                        checked={notification.push_landplot_exit}
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>{props.t("Acima da velocidade")}</td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "sms_speed_above")
                        }
                        name="sms_speed_above"
                        checked={notification.sms_speed_above}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "push_speed_above")
                        }
                        name="push_speed_above"
                        checked={notification.push_speed_above}
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>{props.t("Abaixo da velocidade")}</td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "sms_speed_below")
                        }
                        name="sms_speed_below"
                        checked={notification.sms_speed_below}
                      />
                    </label>
                  </td>
                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "push_speed_below")
                        }
                        name="push_speed_below"
                        checked={notification.push_speed_below}
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>{props.t("Parada")}</td>

                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "sms_stopped")
                        }
                        name="sms_stopped"
                        checked={notification.sms_stopped}
                      />
                    </label>
                  </td>

                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "push_stopped")
                        }
                        name="push_stopped"
                        checked={notification.push_stopped}
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>{props.t("Resumo Diário")}</td>

                  <td>
                    <label>
                      <Switch
                        onChange={(value) =>
                          handleChecked(value, "daily_summary")
                        }
                        name="daily_summary"
                        checked={notification.daily_summary}
                      />
                    </label>
                  </td>

                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div align="center">
          <input
            type="submit"
            className="btn btn-primary"
            value={props.t("Atualizar")}
          />
          <Link to={"/account/password_change/"} className="btn btn-default">
            {props.t("Trocar a senha")}
          </Link>
          <Link to={`/`} className="btn btn-default">
            {props.t("Cancelar")}
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
export default withTranslation()(withRouter(FormUpdateUser));
