import React, { useEffect } from "react";
import { Route, Switch, withRouter, useParams } from "react-router-dom";

// ROUTES
import OperationRoute from "./containers/Operation/Routes";
import MachineRoutes from "./containers/Machine/MachineRoutes";
import ImplementRoute from "./containers/Implements/ImplementRoute";
import OperatorRoutes from "./containers/Operadores/Routes";
import LandplotsRoutes from "./containers/Landplots/Routes";
import ReportRoutes from "./containers/Report/Routes";
import AdministratorRoutes from "./containers/Administrador/Routes";
import Home from "./containers/Dashboard/Home";
import FarmForm from "./containers/Propriedades/FarmForm";
import HomeMobile from './containers/Mobile/Panel'
import ListsMobile from './containers/Mobile/Lists'
import RegistersMobile from './containers/Mobile/Registers'
import ListAppointments from './containers/Mobile/Appointments'
import DashboardMap from './containers/Mobile/DashBoard/Map'
import DashboardKpis from './containers/Mobile/DashBoard/Kpis'
// REDUX
import { connect } from "react-redux";
import * as actions from "./store/actions/actions";

import PropTypes from "prop-types";

// SCREEN
import withWidth from "@material-ui/core/withWidth";

function Routes(props) {
  const params = useParams();

  useEffect(() => {
    props.fetchFarm(params.farm_id);
  }, []);


  return (
    <Switch>
      <Route path="/new/" component={FarmForm} />
      <Route path="/:farm_id/change/" component={FarmForm} />
      <Route path="/:farm_id/operation/*" component={OperationRoute} />
      <Route path="/:farm_id/equipment/machine/*" component={MachineRoutes} />
      <Route
        path="/:farm_id/equipment/implement/*"
        component={ImplementRoute}
      />
      <Route path="/:farm_id/operator/*" component={OperatorRoutes} />
      <Route path="/:farm_id/landplots/*" component={LandplotsRoutes} />
      <Route path="/:farm_id/report/*" component={ReportRoutes} />
      <Route path="/:farm_id/administrator/*" component={AdministratorRoutes} />

      {/* MOBILE */}
   
      <Route path="/:farm_id/home" component={HomeMobile} />
      <Route path="/:farm_id/dashboard/kpis" component={DashboardKpis} />
      <Route path="/:farm_id/lists/" component={ListsMobile} />
      <Route path="/:farm_id/appointments/" component={ListAppointments} />
      <Route path="/:farm_id/registers/" component={RegistersMobile} />

      <Route path="/:farm_id" component={props.width !== "xs" ? Home : DashboardMap} /> 
      
    </Switch>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFarm: (id) => dispatch(actions.fetchFarm(id)),
  };
};

const mapStateToProps = (state) => ({});

Routes.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withWidth()(Routes))
);
