import React from "react";

import KpiGadgetHeader from "../../../components/Dashboard/gadgets/Kpi_gadget_header";
import KpiGadgetContent from "../../../components/Dashboard/gadgets/Kpi_gadget_content";
import KpiGadgetFooter from "../../../components/Dashboard/gadgets/Kpi_gadget_footer";

import { Link, useParams } from "react-router-dom";

import { FaPlus } from "react-icons/fa";

function Kpi_gadget(props) {
  const params = useParams();
  let { farm_id } = params;

  const [period_id, setPeriodId] = React.useState(0);

  function renderMachines() {
    return (
      <>
        <ul className="list-group list-machines-with-link">
          {Object.keys(props.equipment).map((item) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={props.equipment[item].id}
              >
                <Link
                  to={`/${farm_id}/equipment/machine/${props.equipment[item].id}/report`}
                  className="machine-link-detail"
                >
                  <strong>{props.equipment[item].ident}</strong>
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  function renderOperations() {
    return (
      <>
        <ul className="list-group list-operations-with-link">
          {props.operations.map((item) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={item.id}
              >
                <Link
                  to={`/${farm_id}/operation/${item.id}/progress/`}
                  className="machine-link-detail"
                >
                  <strong>{item.ident}</strong>
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  function renderMachinesIdle() {
    console.log(period_id)
    console.log(props.periods[period_id].equipments)
    return (
      <div className="table-machines-idle">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Identificação</th>
              <th>Tempo ocioso</th>
            </tr>
          </thead>
          <tbody>
            {props.periods[period_id].equipments.map((item) => (
              <tr key={item.name}>
                <td scope="row">{item.equipment_name}</td>
                <td>{item.hours}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function get_content() {
    switch (props.identity) {
      case "machines":
        return (
          <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
            <div className="x_panel no-margin-top">
              <div
                className="kpi_gadget_header"
                onClick={props.handleModalMachine}
                style={{ cursor: "pointer" }}
              >
                <h3
                  className={`${props.header_class} bold pull-left no-margin`}
                >
                  {" "}
                  {props.label_header}{" "}
                </h3>
                {props.header_ico_class}
                <div className="clearfix"></div>
                <KpiGadgetContent
                  label={props.content_header}
                  value={props.content_value}
                  class={props.content_class}
                  first_value={props.first_value}
                />
                <KpiGadgetFooter
                  label={props.footer_header}
                  value={props.footer_value}
                  class={props.footer_class}
                />

                {props.modalMachine && (
                  <div className="x_panel-mobile" style={{ border: "none" }}>
                    {renderMachines()}

                    <Link
                      to={`/${farm_id}/equipment/machine/add/`}
                      className="btn btn-kpi-add"
                    >
                      {" "}
                      <FaPlus size={20} />{" "}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
        break;
      case "reports":
        return (
          <>
            <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
              <div className="x_panel no-margin-top">
                <Link to={props.main_link}>
                  <KpiGadgetHeader
                    label={props.label_header}
                    value={props.header_value}
                    class={props.header_class}
                    header_ico_class={props.header_ico_class}
                  />
                  <KpiGadgetContent
                    label={props.content_header}
                    value={props.content_value}
                    class={props.content_class}
                    first_value={props.first_value}
                  />
                  <KpiGadgetFooter
                    label={props.footer_header}
                    value={props.footer_value}
                    class={props.footer_class}
                  />
                </Link>
              </div>
            </div>
          </>
        );
        break;
      case "idle":
        return (
          <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
            <div className="x_panel no-margin-top">
              <div className="kpi_gadget_header">
                <h3
                  className={`${props.header_class} bold pull-left no-margin`}
                  onClick={props.handleModalHoursIdle}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {props.periods[period_id].pc}
                  {" % "}
                </h3>
                <div className="dropdown pull-right">
                  <button
                    className="btn  btn-sm dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    {props.periods[period_id].label}
                    <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu">
                    {props.periods.map((period) => {
                      return (
                        <li key={period.id}>
                          <a href="#" onClick={() => setPeriodId(period.id)}>
                            {period.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="clearfix"></div>
                <KpiGadgetContent
                  label={props.content_header}
                  value={props.content_value}
                  class={props.content_class}
                  first_value={props.first_value}
                />
                <KpiGadgetFooter
                  label={props.footer_header}
                  value={props.periods[period_id].hours}
                  class={props.footer_class}
                />

                <div className="modal-list-machine-idle-mobile">
                  {props.modalHoursIdle && (
                    <>
                      <div className="x_panel-idle-mobile">
                        {renderMachinesIdle()}
                      </div>
                    </>
                  )}
                </div>

              </div>
            </div>
          </div>
        );
        break;

      case "operations":
        return (
          <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
            <div className="x_panel no-margin-top">
              <div
                className="kpi_gadget_header"
                onClick={props.handleModalOperation}
                style={{ cursor: "pointer" }}
              >
                <h3
                  className={`${props.header_class} bold pull-left no-margin`}
                >
                  {" "}
                  {props.label_header}{" "}
                </h3>
                {props.header_ico_class}
                <div className="clearfix"></div>
                <KpiGadgetContent
                  label={props.content_header}
                  value={props.content_value}
                  class={props.content_class}
                  first_value={props.first_value}
                />
                <KpiGadgetFooter
                  label={props.footer_header}
                  value={props.footer_value}
                  class={props.footer_class}
                />

                {props.modalOperation && (
                  <>
                    <div
                      className="x_panel-operations-mobile"
                      style={{ border: "none" }}
                    >
                      {renderOperations()}
                      <Link
                        to={`/${farm_id}/operation/add/`}
                        className="btn btn-kpi-add"
                      >
                        {" "}
                        <FaPlus size={20} />{" "}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
        break;
      default:
        return <LoadingKPIS />;
    }
  }

  return <>{get_content()}</>;
}

export default Kpi_gadget;

function LoadingKPIS() {
  return (
    <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6 ">
      <div className="x_panel no-margin-top animate"></div>
    </div>
  );
}
