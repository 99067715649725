import React, { useState, useEffect } from "react";

// CONFIGS
import myConfig from "../../configs/config";

// HELPERS
import axios from "axios";
import { isEmpty } from "../../helpers/utils";
import { withTranslation } from "react-i18next";
import { getFormatedDate, getTime } from "../../helpers/utils";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";

// COMPONENTS
import AgriDateTime from "../../components/AgriDateTime";
import MDSpinner from "react-md-spinner";
import { ToastContainer, toast } from "react-toastify";
import SelectBox from "../../components/SelectBox";

// STYLES

function Edit(props) {
  const history = useHistory();
  const params = useParams();

  const operation_id = params.operation_id;

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [listOperators, setListOperators] = useState([]);
  const [listMachines, setMachines] = useState([]);
  const [listImplements, setListImplements] = useState([]);
  const [landplots, setLandplots] = useState([]);
  const [operation, setOperation] = useState([]);

  const [dt_ini, setDtIni] = useState("");
  const [ident, setIdent] = useState("");
  const [daily_hours, setDailyHours] = useState("");
  const [fuel_price, setFuelPrice] = useState("");
  const [fineshed_at, setFineshedAt] = useState("");
  const [width, setWidth] = useState("");
  const [speed, setSpeed] = useState("");
  const [operator, setOperator] = useState("");
  const [machine, setMachine] = useState("");
  const [implement, setImplement] = useState("");
  const [landplot, setLandplot] = useState("");
  const [fineshed, setFineshed] = useState(false);
  const [created, setCreated] = useState("");
  const [machine_classification, setMachineClassification] = useState("");
  const [op_type, setOpType] = useState("covering");

  const [loading, setLoading] = useState(true);

  async function loadOperators() {
    try {
      let OPERATOR_URL = `${myConfig.API_URL}/operator/?farm_id=${params.farm_id}`;

      const response = await axios({
        baseURL: OPERATOR_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setListOperators(data);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar operadores, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getMachines() {
    try {
      let MACHINE_LIST_URL = `${myConfig.API_URL}/equipment/?farm_id=${params.farm_id}`;

      const response = await axios({
        baseURL: MACHINE_LIST_URL,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setMachines(data);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar máquinas, recarregue a página e tente novamente";
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getImplements() {
    try {
      let IMPLEMENTS_URL = `${myConfig.API_URL}/implement/?farm_id=${params.farm_id}`;

      let response = await axios({
        baseURL: IMPLEMENTS_URL,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      let data = response.data;

      if (data) {
        setListImplements(data);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar implementos, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getLandplots() {
    try {
      let LANDPLOTS_URL = `${myConfig.API_URL}/landplot/?farm_id=${params.farm_id}`;

      let response = await axios({
        baseURL: LANDPLOTS_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setLandplots(data);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar talhões, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getDatafromOperation() {
    try {
      let OPERATION_URL = `${myConfig.API_URL}/operation/${operation_id}/?farm_id=${params.farm_id}`;
      let response = await axios({
        baseURL: OPERATION_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      setOperation(data);
      setDtIni(data.dt_ini);
      setIdent(data.ident);
      setDailyHours(data.daily_hours);
      setFuelPrice(data.fuel_price);
      setFineshed(data.fineshed);
      setFineshedAt(data.fineshed_at);
      setWidth(data.width);
      setOpType(data.operation_type);
      setSpeed(data.speed);
      setOperator(data.operator);
      setMachine(data.machine);
      setImplement(data.impl);
      setLandplot(data.land_plot);
      setCreated(data.created);

      setLoading(false);
    } catch (e) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar dados da operação, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  useEffect(() => {
    document.title = "Agriconnected - Operações";
    
    loadOperators();
    getMachines();
    getImplements();
    op_type === "covering" && getLandplots();
    getDatafromOperation();

  }, []);

  function getFormData() {
    let form_data = new FormData();

    if (operator !== null) {
      form_data.append("operator", operator);
    }
    if (machine !== null) {
      form_data.append("machine", machine);
    }
    if (landplot !== null) {
      form_data.append("land_plot", landplot);
    }
    if (implement !== null) {
      form_data.append("impl", implement);
    }
    if (fuel_price !== null) {
      form_data.append("fuel_price", fuel_price);
    }
    if (fineshed_at !== null) {
      form_data.append("fineshed_at", fineshed_at);
    }
    form_data.append("ident", ident);
    form_data.append("operation_type", op_type);
    form_data.append("dt_ini", dt_ini);
    form_data.append("daily_hours", daily_hours);
    form_data.append("width", width);
    form_data.append("speed", speed);
    form_data.append("created", created);
    form_data.append("farm_id", params.farm_id);
    form_data.append("fineshed", fineshed);

    return form_data;
  }

  async function handleSave(e) {
    e.preventDefault();

    try {
      let UPDATE_OPERATION = `${myConfig.API_URL}/operation/${operation_id}/?farm_id=${params.farm_id}`;

      let response = await axios({
        baseURL: UPDATE_OPERATION,
        method: "PUT",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let { status } = response;

      if (status === 200) {
        toast.success(
          `${props.t("Operação")}  ${props.t("atualizada com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${params.farm_id}/operation/`);
        }, 2000);
      }
    } catch (error) {
      // console.error(e)
      let error_msg;
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " - ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleChangeSelect(e, component_name) {
    console.log('chegou aqui handleChangeSelect');
    let index = e.target.selectedIndex;

    if (component_name === "implement") {
      setImplement(e.target.value);
      setSpeed(e.target[index].getAttribute("speed"));
      setIdent("");
    }

    if (component_name === "machine") {
      setMachine(e.target.value);
      setMachineClassification(e.target[index].getAttribute("classification"));
      setSpeed(e.target[index].getAttribute("speed"));
      setIdent("");
    }

    if (component_name === "landplot") {
      setLandplot(e.target.value);
    }

    if (component_name === "operator") {
      console.log('chegou aqui setOperator');
      console.log(e.target.value);
      setOperator(e.target.value);
    }
  }

  function mountOperationInvolved() {
    let content = [];
    let fields = [
      {
        field: "landplot",
        value: "name",
        state_name: landplots,
        label: props.t("Talhão"),
        component_name: 'landplot',
        selected_value: landplot,
      },
      {
        field: "operator",
        value: "nome",
        state_name: listOperators,
        label: props.t("Operador"),
        component_name: 'operator',
        selected_value: operator,
      },
      {
        field: "machine",
        value: "ident",
        state_name: listMachines,
        label: props.t("Máquina"),
        component_name: 'machine',
        selected_value: machine,
      },
      {
        field: "implement",
        value: "ident",
        state_name: listImplements,
        label: "Implemento",
        component_name: 'implement',
        selected_value: implement,
      },
    ];

    if (op_type !== "covering") {
      fields[0] = {};
    }

    Object.keys(fields).map(function (key, index) {
      if (isEmpty(fields[index])) {
        return;
      }

      let state_name = fields[index].state_name;

      if (!state_name) return;

      let selected_value = fields[index].selected_value;
      let field = fields[index].field;
      let field_value = fields[index].value;
      let show = false;

      const data = state_name.map((item) => ({
        id: item.id,
        ident: item[field_value],
        extra:
          field === "implement" || field === "machine"
            ? {
                classification: item.classification,
                category: item.category_name,
                length: item.length,
                speed: item.speed,
              }
            : {},
      }));

      if (field === "implement") {
        show = true;
        if (machine_classification === "trator") {
          show = false;
        }
      }

      content.push(
        <div className="form-group col-md-12 col-xs-12">
          <SelectBox
            strong="true"
            show={show}
            data={data}
            selected_value={selected_value}
            value={"ident"}
            change={handleChangeSelect}
            label={fields[index].label}
            component_name={fields[index].component_name}
          />
        </div>
      );
    }, this);

    return content;
  }

  function handleChangeDateEnd(e) {
    setFineshedAt(getFormatedDate(e));
    setFineshed(true);
  }

  function handleDateChange(e) {
    setDtIni(getFormatedDate(e));
  }

  function handleTimeChange(e) {
    let data = new Date(dt_ini);
    data = new Date(
      data.toLocaleDateString("en-US", options) + " " + e.target.value
    );
    setDtIni(getFormatedDate(data));
  }

  function handleTimeEndChange(e) {
    let data = new Date(fineshed_at);
    data = new Date(
      data.toLocaleDateString("en-US", options) + " " + e.target.value
    );
    setFineshedAt(getFormatedDate(data));
  }

  function handleChecked(e) {
    let fineshed_at = new Date();

    setFineshedAt(getFormatedDate(fineshed_at));
    setFineshed(e.target.checked);
  }

  return (
    <div id="operation_update_container">
      {loading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <h2>{props.t("Editar Operações")}</h2>
          <form method="POST" onSubmit={handleSave}>
            <div className="form-group">
              <label htmlFor="id_ident">
                {props.t("Identificação da operação")}
              </label>
              <input
                type="text"
                name="ident"
                onChange={(e) => setIdent(e.target.value)}
                value={ident}
                maxLength={150}
                className="form-control"
                placeholder="Identificação da operação"
                required
                id="id_ident"
              />
            </div>

            <div className="form-group">
              <label htmlFor="id_operation_type">{props.t("Tipo")}</label>
              <select
                name="op_type"
                onChange={(e) => setOpType(e.target.value)}
                className="form-control"
                id="id_operation_type"
              >
                <option value={"covering"} selected={op_type === "covering"}>
                  {props.t("Padrão")}
                </option>
                <option value={"recurrent"} selected={op_type === "recurrent"}>
                  {props.t("Recorrente")}
                </option>
                <option
                  value={"general_services"}
                  selected={op_type === "general_services"}
                >
                  {props.t("Serviços Gerais")}
                </option>
              </select>
            </div>

            {mountOperationInvolved()}

            <div className="form-group col-md-12 col-xs-12">
              <AgriDateTime
                time={getTime(dt_ini)}
                label={props.t("Data de inicio da operação")}
                datetimeformat="true"
                selectedDate={dt_ini}
                handleDateChange={handleDateChange}
                handleTimeChange={handleTimeChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="id_daily_hours">
                {props.t("Jornada (horas trabalhadas por dia)")}
              </label>
              <input
                type="text"
                name="daily_hours"
                onChange={(e) => setDailyHours(e.target.value)}
                value={daily_hours}
                step="0.01"
                className="form-control"
                placeholder={props.t("Jornada (horas trabalhadas por dia)")}
                id="id_daily_hours"
              />
            </div>

            <div className="form-group">
              <label htmlFor="id_fuel_price">
                {props.t("Custo do Combustivel (R$/L)")}
              </label>
              <input
                type="text"
                name="fuel_price"
                onChange={(e) => setFuelPrice(e.target.value)}
                value={fuel_price}
                step="0.01"
                className="form-control"
                placeholder={props.t("Custo do Combustivel (R$/L)")}
                id="id_fuel_price"
              />
            </div>

            <div className="form-group">
              <label htmlFor="id_width">{props.t("Largura de trabalho")}</label>
              <input
                type="text"
                name="width"
                onChange={(e) => setWidth(e.target.value)}
                value={width}
                step="0.01"
                className="form-control"
                placeholder={props.t("Largura de trabalho")}
                id="id_width"
              />
            </div>

            <div className="form-group">
              <label htmlFor="id_speed">
                {props.t("Velocidade da operação")}
              </label>
              <input
                type="text"
                name="speed"
                onChange={(e) => setSpeed(e.target.value)}
                value={speed}
                step="0.01"
                className="form-control"
                placeholder={props.t("Velocidade da operação")}
                id="id_speed"
              />
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  type="checkbox"
                  name="fineshed"
                  onChange={handleChecked}
                  className="form-check-input"
                  id="id_fineshed"
                  defaultChecked={fineshed}
                />
                <label className="form-check-label" htmlFor="id_fineshed">
                  {props.t("Finalizada")}
                </label>
              </div>
            </div>

            <div className="form-group">
              <AgriDateTime
                time={getTime(fineshed_at)}
                label={props.t("Finalizada em")}
                datetimeformat="true"
                selectedDate={fineshed_at}
                handleDateChange={handleChangeDateEnd}
                handleTimeChange={handleTimeEndChange}
              />
            </div>

            <div align="center">
              <input
                type="submit"
                className="btn btn-primary "
                value={props.t("Salvar")}
              />
              <Link
                to={`/${params.farm_id}/operation/${operation_id}/progress`}
                className="btn btn-default"
              >
                {props.t("Cancelar")}
              </Link>
            </div>
          </form>
        </>
      )}

      <ToastContainer />
    </div>
  );
}

function Loading() {
  return (
    <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
      <MDSpinner />
    </div>
  );
}

export default withTranslation()(withRouter(Edit));
