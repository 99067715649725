import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ListLandplots from './ListLandplots';
import FormLandplot from './FormLandplot';


class Routes extends Component {

    render() {
        return (
            <Switch>
                <Route path="/:farm_id/landplots/:landplot_id/change" component={FormLandplot} />} >
                <Route path="/:farm_id/landplots/add" component={FormLandplot}/>}/>
                <Route path="/:farm_id/landplots/" component={ListLandplots} />} />
            </Switch>
        )
    }
}

export default withRouter(Routes)
