import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import Ico from "../../assets/images/icon.png";
import logoName from "../../assets/images/logo_name.png";

import {
    FaDesktop,
    FaCalendarCheck,
    FaAddressBook,
    FaFileAlt,
    FaChevronDown,
} from "react-icons/fa";

import "./Base.css";

class Sidebar extends Component {
  state = {
    user: [],
    collapseReport: false,
    collapseRegister: false,
    activeOperation: false,
    activePanel: false,
  };

  componentDidMount() {
    if (localStorage.getItem("client") !== null) {
      this.setState({
        user: JSON.parse(localStorage.getItem("client")),
      });
    } else {
      // console.log(localStorage.getItem("client"));
      setTimeout(() => {
        this.setState({
          user: JSON.parse(localStorage.getItem("client")),
        });
      }, 2000);
    }
  }

  render() {
    const {
      user,
      collapseRegister,
      collapseReport,
      activeOperation,
      activePanel,
    } = this.state;

    let farm_id = this.props.farm_id;
    const location = window.location;

    if (user.length === 0) return false;

    return (
      <>
      
        <div className="col-md-3 left_col fixed menu_fixed">
          <div className="left_col">
            <div className="navbar nav_title " style={{ border: 0 }}>
              <Link to={`/${farm_id}`} className="site_title">
                <img
                  src={Ico}
                  alt="Home"
                  style={{ height: "85%" }}
                  align="center"
                />
                <img src={logoName} style={{ width: "150px" }} alt="Home" />
              </Link>
            </div>

            <div className="clearfix"></div>

            <div className="profile clearfix">
              <div className="profile_pic">
                <img
                  src={user.thumbnail}
                  className="img-circle profile_img"
                  alt=""
                />
              </div>

              <div className="profile_info">
                <span>{this.props.t("Bem vindo")}</span>
                <h2>
                  {user.first_name} {user.last_name}
                </h2>
              </div>
            </div>

            <div
              id="sidebar-menu"
              className="main_menu_side hidden-print main_menu"
            >
              <div className="menu_section">
                <Nav>
                  <ul className="nav side-menu">
                    <li
                      onClick={() =>
                        this.setState({
                          activePanel: !activePanel,
                          activeOperation:
                            activeOperation === true ? false : activeOperation,
                        })
                      }
                      className={
                        location.pathname === `/${farm_id}/`
                          ? "current-page"
                          : ""
                      }
                    >
                      <Link to={`/${farm_id}/`}>
                        <FaDesktop color="#E7E7E7" />
                        <span className="span"></span>
                        <span>{this.props.t("Painel")}</span>
                      </Link>
                    </li>

                    <li
                      onClick={() =>
                        this.setState({
                          activeOperation: !activeOperation,
                        })
                      }
                      className={
                        activeOperation === true ||
                        location.pathname.indexOf(`/${farm_id}/operation/`) ===
                          0
                          ? "current-page"
                          : ""
                      }
                    >
                      <Link to={`/${farm_id}/operation/`}>
                        <FaCalendarCheck />
                        <span className="span"></span>
                        <span>{this.props.t("Operações")}</span>
                      </Link>
                    </li>

                    <li
                      onClick={() =>
                        this.setState({
                          collapseRegister: !collapseRegister,
                        })
                      }
                      className={
                        collapseRegister === true ||
                        location.pathname.indexOf(`/${farm_id}/equipment/`) ===
                          0 ||
                        location.pathname.indexOf(`/${farm_id}/operator`) ===
                          0 ||
                        location.pathname.indexOf(`/${farm_id}/landplots`) === 0
                          ? "current-page"
                          : ""
                      }
                    >
                      <a>
                        <FaAddressBook />
                        <span className="span"></span>
                        {this.props.t("Cadastros")}
                        <FaChevronDown />
                      </a>

                      <Collapse isOpen={collapseRegister}>
                        <ul
                          className="nav child_menu"
                          style={{
                            display: "block",
                          }}
                        >
                          <li>
                            <Link to={`/${farm_id}/equipment/machine/`}>
                              <span>{this.props.t("Máquinas")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${farm_id}/equipment/implement/`}>
                              <span>{this.props.t("Implementos")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${farm_id}/operator/`}>
                              <span>{this.props.t("Operadores")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${farm_id}/landplots/`}>
                              <span>{this.props.t("Talhões")}</span>
                            </Link>
                          </li>
                        </ul>
                      </Collapse>
                    </li>

                    <li
                      onClick={() =>
                        this.setState({
                          collapseReport: !collapseReport,
                        })
                      }
                      className={
                        collapseReport === true ||
                        location.pathname.indexOf(`/${farm_id}/report/`) === 0
                          ? "current-page"
                          : ""
                      }
                    >
                      <a>
                        <FaFileAlt />
                        <span className="span"></span>
                        <span>{this.props.t("Relatórios")}</span>
                        <FaChevronDown />
                      </a>

                      <Collapse isOpen={collapseReport}>
                        <ul
                          className="nav child_menu"
                          style={{
                            display: "block",
                          }}
                        >
                          <li>
                            <Link to={`/${farm_id}/report/fleet/`}>
                              <span>{this.props.t("Máquinas")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${farm_id}/report/activity/`}>
                              <span>{this.props.t("Talhões")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${farm_id}/report/path/`}>
                              <span>{this.props.t("Personalizado")}</span>
                            </Link>
                          </li>
                        </ul>
                      </Collapse>
                    </li>
                  </ul>
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(Sidebar);
