import React, { useState, useEffect } from "react";

import { withTranslation } from "react-i18next";

import SelectBox from "../../../../components/SelectBox";
import MDSpinner from "react-md-spinner";
import StepControl from "../StepControl";

import { FaPlus, FaTrashAlt } from "react-icons/fa";

import Alert from "react-bootstrap/Alert";

import "./index.css";

function SecondOperationStep(props) {
  return (
    <div className="second-op-step-container">
      <div className="container">
        <div className="row-steps">
          <div className="col step-second-select">
            <SelectBox
              strong="true"
              data={props.FakedLandplots}
              selected_value={props.landplot}
              value={"name"}
              change={props.handleChange}
              label={props.t("Talhão")}
              component_name={"landplot"}
            />
          </div>
          <div className="col step-first-btn">
            <button className="btn btn-in-row" onClick={props.pushLandplots}>
              <FaPlus />
            </button>
          </div>
        </div>
      </div>

      <br />

      {props.error_fields !== ""
        ? props.error_fields.map((field) => {
            return (
              <>
                <Alert variant="danger">
                  Campo {field} não pode ser nulo, por favor preencha para
                  prosseguir.
                </Alert>
              </>
            );
          })
        : null}
      <br />
      <br />
      {props.listLandplots.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Talhão</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {props.listLandplots.map((data, index) => (
              <tr key={index}>
                <td>
                  {props.landplots.map((landplot) => (
                    <>
                      {landplot.id === parseInt(data) && (
                        <p className="" key={data}>
                          {landplot.name}
                        </p>
                      )}
                    </>
                  ))}
                </td>

                <td>
                  <button
                    className="btn btn-primary btn-in-row"
                    onClick={() => props.popLandplots(data, index)}
                  >
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <br />
      <br />
      <br />

      {props.listLandplots.length > 0 && (
        <StepControl
          handleNext={props.handleNext}
          handleBack={props.handleBack}
          lastStep={props.lastStep}
          currentStep={props.currentStep}
        />
      )}
    </div>
  );
}

function Spinner() {
  return (
    <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
      <MDSpinner />
    </div>
  );
}

export default withTranslation()(SecondOperationStep);
