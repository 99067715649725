import React from "react";

import Input from "../../../Input";
import StepControl from "../StepControl";
import AgriDateTime from "../../../AgriDateTime";

import { getTime } from "../../../../helpers/utils";
import { withTranslation } from "react-i18next";

function ThirdOperationStep(props) {
  let error_component;
  if (props.error_fields !== "") {
    error_component = validate(props);
  }

  return (
    <div className="col-xs-12 col-md-12 col-lg-12">
      <div className="form-group">
        <AgriDateTime
          datetimeformat="true"
          selectedDate={props.init_date}
          time={getTime(props.init_date)}
          handleTimeChange={props.handleTimeChange}
          handleDateChange={props.handleDateChange}
          label={props.t("Data de inicio da operação")}
        />
      </div>
      <div className="form-group">
        <Input
          handleChange={props.handleChangeText}
          class="form-control"
          value={props.daily_hours}
          type="number"
          label={props.t("Jornada (horas trabalhadas por dia)")}
          name="daily_hours"
        />
        {error_component}
      </div>
      <StepControl
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        lastStep={props.lastStep}
        currentStep={props.currentStep}
        handleSave={props.handleSave}
      />
    </div>
  );
}

function validate(props) {
  let fields = [
    {
      field: "daily_hours",
      error: props.t(
        "Horas trabalhadas por dia o valor precisa ser maior que zero"
      ),
    },
  ];

  let component_err = "";

  props.error_fields.map((error) => {
    if (fields[0].field === error) {
      component_err = (
        <div className="alert alert-danger">{fields[0].error}</div>
      );
    }
  });
  return component_err;
}

export default withTranslation()(ThirdOperationStep);
