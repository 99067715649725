import React, { Component } from "react";
import ReactPasswordStrength from "react-password-strength";
import myConfig from "../../configs/config";
import axios from "axios";
import { Alert } from "reactstrap";
// import cookies from '../../configs/cookies';
// import interceptor from '../../configs/interceptor';
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/Loading";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.match.params.user_id,
      old_password: "",
      new_password: "",
      confirm_new: "",
      loading: false,
    };
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  getUserInfo() {
    let USER_URL = myConfig.API_URL + "/accounts/me/";
    axios({
      baseURL: USER_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      // axios.get(ME_USER)
      .then((res) => {
        // (res.data)
        this.setState({
          user_id: res.data.id,
        });
      });
  }

  componentDidMount() {
    document.title = "Agriconnected - Perfil";

    this.getUserInfo();
  }

  handleChangeText(e, name) {
    let state = {};
    state[name] = e.password;
    this.setState(state);
  }

  getFormatData() {
    let form_data = new FormData();
    form_data.append("new_password", this.state.new_password);
    form_data.append("old_password", this.state.old_password);

    return form_data;
  }

  handleSave(e) {
    e.preventDefault();
    const PASSWORD_URL = `${myConfig.API_URL}/accounts/${this.state.user_id}/set_password/`;

    if (this.state.new_password !== this.state.confirm_new) {
      let error_msg =
        "Nova senha e Confirmar senha devem ser exatamente iguais.";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return
    }

    if (
      this.state.new_password === "" ||
      this.state.confirm_new === "" ||
      this.state.old_password === ""
    ) {
      let error_msg = "Preencha todos os campos para realizar a solicitação";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return
    }

    this.setState({
      loading: true,
    });

    axios({
      baseURL: PASSWORD_URL,
      method: "put",
      data: this.getFormatData(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
          });

          let success_msg = "Nova senha cadastrada com sucesso!";

          toast.success(success_msg, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            this.props.history.push("/");
          }, 2500);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        this.setState({
            loading: false,
        })
        
        let error_msg =
          "Todos os campos devem estar preenchidos e  Nova senha e Confirmar senha devem ser exatamente iguais.";

        toast.error(error_msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <div id="profile_reset_password">
        <h2>{this.props.t("Editar  senha")}</h2>

        <form
          method="POST"
          onSubmit={this.handleSave}
          encType="multipart/form-data"
        >
          <label htmlFor="old_password">{this.props.t("Senha antiga")}</label>
          <ReactPasswordStrength
            minLength={4}
            minScore={2}
            scoreWords={["Fraca", "Razoável", "Bom", "Forte", "Muito forte"]}
            changeCallback={(event) =>
              this.handleChangeText(event, "old_password")
            }
            name="old_password"
            inputProps={{
              name: "old_password",
              autoComplete: "off",
              className: "form-control pass-strength",
              placeholder: "Senha antiga",
            }}
          />
          <label htmlFor="new_password">{this.props.t("Nova senha")}</label>
          <ReactPasswordStrength
            minLength={4}
            minScore={2}
            scoreWords={["Fraca", "Razoável", "Bom", "Forte", "Muito forte"]}
            changeCallback={(event) =>
              this.handleChangeText(event, "new_password")
            }
            inputProps={{
              name: "new_password",
              autoComplete: "off",
              className: "form-control pass-strength",
              placeholder: "Digite aqui sua nova senha",
            }}
          />
          <label htmlFor="confirm_new">{this.props.t("Confirmar senha")}</label>
          <ReactPasswordStrength
            minLength={4}
            minScore={2}
            scoreWords={["Fraca", "Razoável", "Bom", "Forte", "Muito forte"]}
            changeCallback={(event) =>
              this.handleChangeText(event, "confirm_new")
            }
            inputProps={{
              name: "confirm_new",
              autoComplete: "off",
              className: "form-control pass-strength",
              placeholder: "Confirme aqui sua nova senha",
            }}
          />{" "}
          <br />
          <div align="center">
            <input type="submit" className="btn btn-default" value="Editar" />
            <Link
              to={`/account/update/${this.state.user_id}`}
              className="btn btn-default"
            >
              Voltar
            </Link>
          </div>
        </form>
        <ToastContainer />
      </div>
    );
  }
}
export default withTranslation()(withRouter(ResetPassword));
