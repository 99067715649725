import React, { useState } from "react";
import Modal_header from "../../components/Modal_header";
import Progress_bar from "../../components/Progress_bar";
import Modal_footer from "../../components/Modal_footer";
import myConfig from "../../configs/config";
import axios from "axios";
// import interceptor from '../../configs/interceptor';
import { withTranslation } from "react-i18next";
import Button from "../../components/Button";
import Modal from "@material-ui/core/Modal";

import { FaSyncAlt,FaSpinner } from "react-icons/fa";

const OperationReprocess = (props) => {
  const [open, setOpen] = useState(false);

  let REPROCESS_URL =
    myConfig.API_URL + "/operation/" + props.operation_id + "/reprocess/";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleReprocess(e) {
    // console.log('handleReprocess')
    e.preventDefault();
    axios({
      method: "POST",
      url: REPROCESS_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((resp) => {
        console.log(resp);
        console.log("Reprocessando ... ");
      })
      .catch((error) => {
        console.log(error);
        alert("Erro ao reprocessar, por favor tente novamente mais tarde")
      });
  }

  const { farm_id, operation_id } = props;

  return (
    <>
      <Button
        class="btn btn-default pull-left btn-reprocess"
        click={handleOpen}
      >
        <i className="fas fa-sync-alt mr-5"></i>
        <FaSyncAlt className="mr-5" />
        {props.t("Reprocessar")}
      </Button>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <>
          <div className="modal-size">
            <Modal_header title={props.t("Reprocessamento")} />
            <div className="modal-body">
              <div>
                <p>{props.t("Status de reprocessamento")}</p>
                <Progress_bar
                  operation_id={operation_id}
                  farm_id={farm_id}
                  reprocess="true"
                  color="green"
                  has_effect="true"
                />
              </div>
            </div>
            <Modal_footer>
              <button
                type="button"
                onClick={handleReprocess}
                className="btn btn-default"
              >
                {/* <i className="fas fa-spinner mr-5"></i> */}
                <FaSpinner className="mr-5" />
                {props.t("Reprocessar")}
              </button>
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-default"
                data-dismiss="modal"
              >
                {props.t("Voltar")}
              </button>
            </Modal_footer>
          </div>
        </>
      </Modal>
    </>
  );
};

export default withTranslation()(OperationReprocess);
