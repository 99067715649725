import React from "react";
import { useParams, withRouter, Link } from "react-router-dom";

//HELPERS
import { withTranslation } from 'react-i18next';

// COMPONENTS
import Table from "../components/Table";
import Title from "../components/Title";

function Index(props) {
  const params = useParams();

  let items = [
    {
      name: "Máquinas",
      to: `/${params.farm_id}/equipment/machine/`,
    },
    {
      name: "Implementos",
      to: `/${params.farm_id}/equipment/implement/`,
    },
    {
      name: "Operadores",
      to: `/${params.farm_id}/operator/`,
    },
    {
      name: "Talhões",
      to: `/${params.farm_id}/landplots/`,
    },
  ];

  let tableHeaders = [
    { title: props.t("Item") },
    { title: props.t("Visualizar") },
  ];

  return (
    <>
      <Title title="Cadastros" />

      <Table headers={tableHeaders}>
        {items.map((item) => (
          <tr>
            <td scope="row">{item.name}</td>
            <td>
              <Link to={item.to} className="btn btn-default btn-primary">
              {props.t("Escolher")}
              </Link>
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}

export default withTranslation()(withRouter(Index));
