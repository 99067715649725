import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import axios from 'axios';
import myConfig from '../configs/config';
import '../../src/App.css';
import {getErrorComponent} from '../helpers/utils';

class Progress_bar extends Component{

    REPROCESS_URL = myConfig.API_URL+"/operation/"+this.props.operation_id+"/reprocess/"

    constructor(props){
        super(props);

        this.state = {
            progress : 0,
            color : "success",            
            errors : [],
        }    
    }


    timer() {
        axios.get(this.REPROCESS_URL)
        .then(
            res=>{  
                if(res.status === 200){          
                    let progress_status = parseInt(res.data.status);      
                    this.setState({progress:progress_status})                
                    if(res.data.status === 100) { 
                        clearInterval(this.intervalId);                                        
                    }
                }else{
                    this.setState({
                        errors : this.state.errors.concat(getErrorComponent(res.data.detail))
                      });
                }
            }
        )                                
    }

    componentWillUnmount(){
        if(this.props.reprocess === "true"){
            clearInterval(this.intervalId);
        }
    }

    componentDidMount() {
        if(this.props.reprocess === "true"){
            this.intervalId = setInterval(this.timer.bind(this), 5000);
        }
    }

    componentColor(color){
        switch(color){
            case "green" , "progress":
                return "success";
            case "blue":
                return "info";
            case "yellow" , "pending":
                return "warning";
            case "red":
                return "danger";
            default:
                return "finished";
        }
    }

    componentWillMount(){  
        if(this.props.reprocess === "true"){
            axios.get(this.REPROCESS_URL)
            .then(
                res=>{      
                    let progress_status = parseInt(res.data.status);      
                    this.setState({progress:progress_status})
                }
            )        
            
        }else{
            if(this.props.progress !== ""){            
                this.setState({
                    progress : this.props.progress
                });
            }
        }
        this.setState({color:this.componentColor(this.props.color)}); 
    }

    render(){
        if(this.state.errors.length > 0){
            return(
                <div>                    
                    {this.props.errors.map(error => <div>{error}</div>)}                    
                </div> 
            )
        }                    
        if(this.props.has_effect === "true"){
            return(
                <div>
                    <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} variant={this.state.color} striped />
                </div>
            );
        }else{
            return(
                <div>
                    <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} variant={this.state.color}/>
                </div>
            );
        }
    }

}
export default Progress_bar;