import React from "react";

import KpiGadget from "../../components/Dashboard/gadgets/Kpi_gadget";

import { withTranslation } from "react-i18next";

import { FaCogs, FaTractor, FaFileAlt } from "react-icons/fa";

function KpisGadgets(props) {
  const { farm_id, periods, period_id, kpi } = props;

  let contentGadgets = [];

  let kpi_data = [
    {
      identity: "operations",
      label_header: "Operações",
      header_value: "",
      header_class: "green",
      header_ico_class: <FaCogs className="fa-2x fa pull-right" />,
      content_header: "em andamento",
      content_value: kpi.operation_status.ment,
      content_class: "green",
      footer_header: `${kpi.operation_status.pending}  ${props.t(
        "pendentes"
      )}    ${props.t("e")} ${kpi.operation_status.scheduled} 
      ${props.t("agendados")}`,
      footer_value: "",
      footer_class: "yellow",
      first_value: "true",
    },
    {
      identity: "machines",
      label_header: `${props.t("Máquinas")}`,
      header_value: `${props.t("Ligadas")}`,
      header_class: "red",
      header_ico_class: <FaTractor className="fa-2x fa pull-right" />,
      content_header: `${props.t("Ligadas")} :`,
      content_value: kpi.online_machines,
      content_class: "red",
      footer_header: `${props.t("Total")}  :`,
      footer_class: "yellow",
      footer_value: kpi.total_machines,
    },
    {
      main_link: `/${farm_id}/report/fleet/?year=${kpi.last_report_data.year}&month=${kpi.last_report_data.month_value}`,
      identity: "reports",
      label_header: `${props.t("Relatórios")}`,
      header_value: "",
      header_class: "blue",
      header_ico_class: <FaFileAlt className="fa-2x fa pull-right" />,
      content_header: `${kpi.last_report_data.month_name}   ${props.t(
        "Disponível para analise"
      )}`,
      content_value: "",
      content_class: "blue ",
    },
    {
      identity: "idle",
      label_header: kpi.idle_info[period_id].pc,
      header_value: `${props.t(`Percentual ocioso`)}`,
      header_class: "dark",
      period_id: period_id,
      periods: kpi.idle_info,
      content_header: "Percentual ocioso",
      content_value: "",
      content_class: "dark",
      footer_header: `${props.t("Tempo ocioso")}: `,
      footer_class: "yellow",
      footer_value: kpi.idle_info[period_id].hours,
    },
  ];

  function KPIOperations() {
    let kpiOperations = kpi_data[0];

    contentGadgets.push(
      <KpiGadget
        key={0}
        identity={kpiOperations.identity}
        handleModalOperation={props.handleModalOperation}
        label_header={kpiOperations.label_header}
        header_value={kpiOperations.header_value}
        header_ico_class={kpiOperations.header_ico_class}
        first_value={kpiOperations.first_value}
        content_header={kpiOperations.content_header}
        content_value={kpiOperations.content_value}
        content_class={kpiOperations.content_class}
        footer_header={kpiOperations.footer_header}
        footer_value={kpiOperations.footer_value}
        footer_class={kpiOperations.footer_class}
        header_class={kpiOperations.header_class}
      />
    );
  }
  function KPIMachines() {
    let kpiMachines = kpi_data[1];

    contentGadgets.push(
      <KpiGadget
        key={1}
        identity={kpiMachines.identity}
        handleModal={props.handleModal}
        label_header={kpiMachines.label_header}
        header_value={kpiMachines.header_value}
        header_ico_class={kpiMachines.header_ico_class}
        first_value={kpiMachines.first_value}
        content_header={kpiMachines.content_header}
        content_value={kpiMachines.content_value}
        content_class={kpiMachines.content_class}
        footer_header={kpiMachines.footer_header}
        footer_value={kpiMachines.footer_value}
        footer_class={kpiMachines.footer_class}
        header_class={kpiMachines.header_class}
      />
    );
  }
  function KPIReports() {
    let kpiReports = kpi_data[2];

    contentGadgets.push(
      <KpiGadget
        key={2}
        identity={kpiReports.identity}
        main_link={kpiReports.main_link}
        label_header={kpiReports.label_header}
        header_value={kpiReports.header_value}
        header_ico_class={kpiReports.header_ico_class}
        first_value={kpiReports.first_value}
        content_header={kpiReports.content_header}
        content_value={kpiReports.content_value}
        content_class={kpiReports.content_class}
        footer_header={kpiReports.footer_header}
        footer_value={kpiReports.footer_value}
        footer_class={kpiReports.footer_class}
        header_class={kpiReports.header_class}
      />
    );
  }

  function KPIidle() {
    let kpiIdle = kpi_data[3];

    contentGadgets.push(
      <KpiGadget
        key={3}
        handleModalHoursIdle={props.handleModalHoursIdle}
        identity={kpiIdle.identity}
        label_header={kpiIdle.label_header}
        header_value={kpiIdle.header_value}
        header_ico_class={kpiIdle.header_ico_class}
        first_value={kpiIdle.first_value}
        content_header={kpiIdle.content_header}
        content_value={kpiIdle.content_value}
        content_class={kpiIdle.content_class}
        footer_header={kpiIdle.footer_header}
        footer_value={kpiIdle.footer_value}
        footer_class={kpiIdle.footer_class}
        header_class={kpiIdle.header_class}
        setPeriod={props.setPeriod}
        periods={periods}
        period_id={period_id}
      />
    );
  }

  function renderGadgets() {
    KPIidle();
    KPIOperations();
    KPIMachines();
    KPIReports();

    return contentGadgets;
  }

  return <div>{renderGadgets()}</div>;
}

export default withTranslation()(KpisGadgets);
