import React from 'react';

const Kpi_gadget_content = (props) => {
    return(
        <div className="kpi_gadget_content ">
            <div><span className="count_top count">{props.label}</span><span className={props.class + " count"}>{props.value}</span></div>
        </div>
    );
}

export default Kpi_gadget_content;
