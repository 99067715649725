import React from 'react';

const Modal_footer = (props) => {

    return(
        <div className="modal-footer">
            {props.children}                                   
        </div>
    );
}

export default Modal_footer;
