import React from 'react'
import Modal from '@material-ui/core/Modal';
import axios from 'axios'
import myConfig from '../configs/config';
import { withTranslation } from 'react-i18next';
import './tooltip.css'

function PoupUp(props) {

    // STATE 
    const [open, setOpen] = React.useState(true);
    const [check, setCheck] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getFormData = () => {
        let form_data = new FormData();

        form_data.append('accepted_terms', check);
        form_data.append('id', props.user_id);
        
        return form_data
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(props.farm_id)
        
        let UPDATE_USER = myConfig.API_URL + "/accounts/" + props.user_id + "/";

        axios({
            baseURL: UPDATE_USER,
            method: 'PATCH',
            data: getFormData(),
        })
            .then(response => {
                // console.log(response.status)
                if (response.status === 200) {
                    // console.log(response.data);
                    // console.log(response.data.accepted_terms);
                    window.location.href = "/" + props.farm_id;
                }
                // console.log(response)
            })
    }

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div className='poupup-size'>
                    <div className="x_title">
                        <h5 align="center">
                            A AgriConnected atualizou seus termos de uso. Favor ler e aceitar os termos para continuar.
                        </h5>
                        <div className="clearfix"></div>
                    </div>
                    <div style={{ padding: "16px" }}>

                        <form onSubmit={handleSubmit}>
                            <p>
                                <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    onChange={e => setCheck(!check)}
                                    value={check}
                                />

                        Eu li e concordo com os  <a
                            href="https://agriconnected.com/termos-de-uso/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        Termos de uso
                        </a>  da AGRICONNECTED e a <a
                            href="https://agriconnected.com/politica-de-privacidade/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Política de Privacidade
                        </a>
                            </p>

                            <input
                            style={{width:"100%"}}
                                type="submit"
                                className="btn btn-primary"
                                value="Li e concordo com os termos e condições"
                            />

                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default withTranslation()(PoupUp);
