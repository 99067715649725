import React from "react";

import FirstOperationStep from "../../../components/MultOperations/Steps/FirstOperationStep/index";
import SecondOperationStep from "../../../components/MultOperations/Steps/SecondOperationStep/index";
import ThirdOperationStep from "../../../components/MultOperations/Steps/ThirdOperationStep/index";
import FourthOperationStep from "../../../components/MultOperations/Steps/FourthOperationStep/index";

import { ToastContainer, toast } from "react-toastify";
import Stepper from "react-stepper-horizontal";
import Alert from "react-bootstrap/Alert";
import Title from "../../../components/Title";

import axios from "axios";

import myConfig from "../../../configs/config";
import { withTranslation } from "react-i18next";
import { getFormatedDate } from "../../../helpers/utils";

class MultOperations extends React.Component {
  farmId = this.props.match.params.farm_id;

  constructor(props) {
    super(props);

    this.state = {
      op_id: "",
      currentStep: 0,
      landplot: "",
      operator: "",
      implement: "",
      machine: "",
      machine_classification: "",
      op_type: "covering",
      op_type_label: "Operação em Talhão",
      landplot_label: "",
      operator_label: "",
      machine_label: "",
      implement_label: "",
      daily_hours: 8,
      op_date: new Date(),
      ident: "",
      error_fields: "",
      error_save: "",
      isempty: false,
      sets: [],
      listLandplots: [],
      landplots: [],
      implements: [],
      machines: [],
      operators: [],
      FakedLandplots: [],
      FakedImplements: [],
      FakedMachines: [],
      FakedOperators: [],
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.pushSetsData = this.pushSetsData.bind(this);
    this.popSetsData = this.popSetsData.bind(this);
    this.popLandplots = this.popLandplots.bind(this);
    this.pushLandplots = this.pushLandplots.bind(this);

    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  async loadImplementData() {
    const IMPLEMENT_URL = `${myConfig.API_URL}/implement/?farm_id=${this.farmId}`;

    let response = await axios({
      baseURL: IMPLEMENT_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    this.setState({
      implements: data,
      FakedImplements: data,
    });
  }
  async loadMachineData() {
    const MACHINE_URL = `${myConfig.API_URL}/equipment/?farm_id=${this.farmId}`;

    let response = await axios({
      baseURL: MACHINE_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    this.setState({
      machines: data,
      FakedMachines: data,
    });
  }
  async loadLanplotData() {
    const LANDPLOT_URL = `${myConfig.API_URL}/landplot/?farm_id=${this.farmId}`;

    let response = await axios({
      baseURL: LANDPLOT_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    this.setState({ landplots: data, FakedLandplots: data });
  }
  async loadOperatorData() {
    const OPERATOR_URL = `${myConfig.API_URL}/operator/?farm_id=${this.farmId}`;

    let response = await axios({
      baseURL: OPERATOR_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    this.setState({
      operators: data,
    });
  }

  componentDidMount() {
    this.loadOperatorData();
    this.loadImplementData();
    this.loadMachineData();
    this.loadLanplotData();
  }

  getSteps() {
    let Maquinas = this.props.t("Máquinas");
    let Talhões = this.props.t("Talhões");
    let Quando = this.props.t("Quando");
    // let Resumo = this.props.t("Resumo");

    return [
      { title: Maquinas },
      { title: Talhões },
      { title: Quando },
      // { title: Resumo },
    ];
  }

  handleDateChange(e) {
    // console.log(e);
    this.setState({
      op_date: e,
    });
  }

  handleTimeChange(e) {
    // console.log(e.target.value);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(this.state.op_date);
    data = new Date(
      data.toLocaleDateString("en-US", options) + " " + e.target.value
    );
    this.setState({ op_date: new Date(getFormatedDate(data)) });
  }

  removeDataFromList(id, stateName, stateData) {
    let filteredList = stateData.filter((data) => data.id !== id);

    this.setState({
      [stateName]: filteredList,
    });
  }

  handleChangeSelect(e, component_name) {
    let component_label = component_name + "_label";
    let component_classification = component_name + "_classification";
    let index = e.target.selectedIndex;
    let label = e.target[index].text;

    if (component_name === "implement" || component_name === "machine") {
      e.target[index].getAttribute("classification") !== "trator" &&
        this.setState({
          implement_label: "",
          implement: "",
        });

      this.setState({
        [component_name]: e.target.value,
        [component_label]: label,
        [component_classification]: e.target[index].getAttribute(
          "classification"
        ),
        ident: "",
      });
    } else {
      this.setState({
        [component_name]: e.target.value,
        [component_label]: label,
      });
    }
  }

  pushSetsData() {
    let newArr = [];

    let {
      machine,
      implement,
      operator,
      sets,
      FakedMachines,
      FakedImplements,
    } = this.state;

    if (machine.trim() !== "" || operator.trim() !== "") {
      newArr.push({
        machine: machine,
        impl: implement,
        operator: operator,
      });

      this.removeDataFromList(
        parseInt(machine),
        "FakedMachines",
        FakedMachines
      );
      this.removeDataFromList(
        parseInt(implement),
        "FakedImplements",
        FakedImplements
      );

      let joined = sets.concat(newArr);

      this.setState({
        sets: joined,
        implement: "",
        machine: "",
        operator: "",
      });
    }
  }

  popSetsData(set, index) {
    let { sets, machines, FakedMachines, FakedImplements } = this.state;
    let data = sets;

    let implementData = this.state.implements.filter(
      (implement) => implement.id === parseInt(set.impl)
    );

    let machineData = machines.filter(
      (machine) => machine.id === parseInt(set.machine)
    );

    let joinedImplement = FakedImplements.concat(implementData);
    let joinedMachine = FakedMachines.concat(machineData);

    data.splice(index, 1);

    this.setState({
      sets: data,
      FakedImplements: joinedImplement,
      FakedMachines: joinedMachine,
    });
  }

  pushLandplots() {
    let newArr = [];

    let { landplot, listLandplots, FakedLandplots } = this.state;

    if (landplot.trim() !== "") {
      newArr.push(landplot);

      this.removeDataFromList(
        parseInt(landplot),
        "FakedLandplots",
        FakedLandplots
      );

      this.setState({
        listLandplots: [...listLandplots, newArr], // [ "206","207", "208", ...]
        landplot: "",
      });
    }
  }

  popLandplots(land, index) {
    let { listLandplots, FakedLandplots, landplots } = this.state;

    let data = listLandplots;

    let landplotData = landplots.filter(
      (landplot) => landplot.id === parseInt(land)
    );

    let joinedLandplot = FakedLandplots.concat(landplotData);

    data.splice(index, 1);

    this.setState({ listLandplots: data, FakedLandplots: joinedLandplot });
  }

  handleChangeText(e, component_name) {
    if (component_name === "ident" && e.target.value === "") {
      this.setState({
        isempty: true,
        [component_name]: e.target.value,
      });
    } else {
      this.setState({
        [component_name]: e.target.value,
      });
    }
  }

  getStepContent(step) {
    let return_button, next_button, last_step;

    last_step = this.getSteps().length - 1;

    switch (step) {
      case 0:
        return (
          <FirstOperationStep
            currentStep={this.state.currentStep}
            lastStep={last_step}
            farm_id={this.farmId}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            current_type={this.state.op_type}
            handleChange={this.handleChangeSelect}
            {...this.state}
            pushSetsData={this.pushSetsData}
            popSetsData={this.popSetsData}
          />
        );
      case 1:
        return (
          <SecondOperationStep
            currentStep={this.state.currentStep}
            lastStep={last_step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            {...this.state}
            error_fields={this.state.error_fields}
            farm_id={this.farmId}
            handleChange={this.handleChangeSelect}
            listLandplots={this.state.listLandplots}
            pushLandplots={this.pushLandplots}
            popLandplots={this.popLandplots}
          />
        );
      case 2:
        return (
          <ThirdOperationStep
            currentStep={this.state.currentStep}
            lastStep={last_step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            {...this.state}
            init_date={this.state.op_date}
            daily_hours={this.state.daily_hours}
            handleChangeText={this.handleChangeText}
            handleDateChange={this.handleDateChange}
            handleTimeChange={this.handleTimeChange}
            handleSave={this.handleSave}
          />
        );
      // case 3:
      //   this.operationIdent();
      //   return (
      //     <FourthOperationStep
      //       ident={this.state.ident}
      //       handleSave={this.handleSave}
      //       currentStep={this.state.currentStep}
      //       lastStep={last_step}
      //       handleNext={this.handleNext}
      //       handleBack={this.handleBack}
      //       button={next_button}
      //       return={return_button}
      //       {...this.state}
      //       handleChangeText={this.handleChangeText}
      //     />
      //   );
      default:
        return "Unknown step";
    }
  }

  operationIdent() {
    let {
      ident,
      implement_label,
      landplot_label,
      machine_label,
      isempty,
    } = this.state;

    if (ident === "") {
      let ident_arr = [];

      implement_label.trim() !== "" && ident_arr.push(implement_label);
      landplot_label.trim() !== "" && ident_arr.push(landplot_label);
      machine_label.trim() !== "" && ident_arr.push(machine_label);

      let ident_name = isempty === true ? " " : ident_arr.join(" - ");

      this.setState({
        ident: ident_name,
      });
    }
  }

  async handleSave(e) {
    e.preventDefault();

    let {
      ident,
      sets,
      op_date,
      daily_hours,
      op_type,
      listLandplots,
    } = this.state;

    let SAVE_URL = `${myConfig.API_URL}/operation/create_multi/`;
    let self = this;

    let arrayLandplots = listLandplots;

    let land_plots = [];

    for (let i = 0; i < arrayLandplots.length; i++) {
      land_plots.push(arrayLandplots[i][0]);
    }

    const data = {
      ident: ident,
      farm: this.farmId,
      farm_id: this.farmId,
      sets,
      dt_ini: op_date,
      daily_hours,
      land_plots,
      operation_type: op_type,
    };

    let response = await axios({
      baseURL: SAVE_URL,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    if (response.status === 201) {
      toast.success(
        `${this.props.t("Operações")}  ${this.props.t(
          "cadastradas com sucesso"
        )}!`,
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTimeout(() => {
        window.location.href = `/${this.farmId}/operation/`;
      }, 2000);
    }

    if (response.status !== 201) {
      let errors_component = [];
      for (let i = 0; i < response.data.msg.length; i++) {
        errors_component.push(
          <Alert variant="danger">{response.data.msg[i]}</Alert>
        );
      }
      self.setState({
        error_save: errors_component,
      });
    }
  }

  setActiveStep(step) {
    this.setState({
      currentStep: step,
    });
  }

  handleBack() {
    this.setState({
      error_fields: "",
      error_save: "",
    });
    this.setActiveStep(this.state.currentStep - 1);
  }

  validateThirdStep() {
    let error_fields = [];
    let { daily_hours } = this.state;

    if (daily_hours === "0" || daily_hours === "") {
      error_fields.push("daily_hours");
    }

    if (error_fields.length === 0) {
      return true;
    } else {
      this.setState({
        error_fields,
      });
      return false;
    }
  }

  validateStep() {
    let isValid = true;

    let { currentStep } = this.state;

    switch (currentStep) {
      case 2:
        isValid = this.validateThirdStep();

        if (isValid) {
          this.setState({
            error_fields: "",
          });
        }

        return isValid;

        break;
      default:
        return true;
    }
  }

  handleNext(e) {
    let isValid = false;
    e.preventDefault();
    isValid = this.validateStep();
    if (!isValid) {
      return false;
    }
    this.setActiveStep(this.state.currentStep + 1);
  }

  render() {
    const steps = this.getSteps();
    const main_step_color = "#3EAF5C";
    let { currentStep } = this.state;

    return (
      <div className="x_content">
        <Title title={"Multiplas Operações"}></Title>
        <Stepper
          steps={steps}
          activeStep={currentStep}
          activeColor={main_step_color}
          completeColor={main_step_color}
        />
        <div className="operation_register_content">
          {this.getStepContent(currentStep)}
        </div>

        <ToastContainer />
      </div>
    );
  }
}
export default withTranslation()(MultOperations);
