import React  from 'react';

const Modal_header = (props) => {
    return(
        <div className="modal-header">
            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span> */}
            {/* </button> */}
            <h4 className="modal-title" align="center" id="modal_label">{props.title}</h4>
        </div>
    );
}

export default Modal_header;