import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import myConfig from "../../configs/config";
import { Form, FormGroup, Label, Input, Alert } from "reactstrap";

import logoIcon from "../../assets/images/logo.png";

const Login = (props) => {
  document.title = "Agriconnected - Entrar";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  function formData() {
    const form_data = new FormData();

    form_data.append("username", email);
    form_data.append("password", password);

    form_data.append("grant_type", "password");
    form_data.append("client_id", "api");
    form_data.append(
      "client_secret",
      "OdcffQR0bZVl4NDW1cXuvGpf1CmRxjyMyDxL7yWrGeIpbpSv5thpJKGDFWYkVUkTQfbllgJPmsixmM07yaozCcbWzuCXVoZ5qUpyKrH7gBI5KuyIAiMK1lcj4hzSPUQC"
    );

    return form_data;
  }

  function onSubmit(e) {
    e.preventDefault();

    const LOGIN_AGRICONNECTED = myConfig.BE_URL + "/o/token/";

    axios({
      baseURL: LOGIN_AGRICONNECTED,
      method: "post",
      data: formData(),
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("client-token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          let urlParams = new URLSearchParams(window.location.search);
          let next = urlParams.get('next');
          if (next == null) {
            next = '/';
          } else {
            next = decodeURIComponent(next);
          }
          window.location.href = next;
        }
      })
      .catch((error) => {
        setError(`Error: ${error.response.data["error_description"]}`);
      });
    setTimeout(() => {
      setError(null);
    }, 3500);
  }

  let formError =
    error !== null ? <Alert color="danger"> {error} </Alert> : null;

  return (
    <div id="login_container">
      <div className="row" style={{ marginTop: "1vh" }}>
        <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_content"></div>
            {formError}

            <h1 align="center">Entrar</h1>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label for="id_email">Endereço de e-mail</Label>
                <Input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  value={email}
                  className="form-control"
                  placeholder="Endereço de e-mail"
                  required
                  id="id_email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePid_passwordassword">Senha</Label>
                <Input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  value={password}
                  maxLength="35"
                  className="form-control"
                  placeholder="Senha"
                  required
                  id="id_password"
                />
              </FormGroup>

              <div align="center">
                <Input
                  type="submit"
                  className="botao_login btn btn-primary"
                  value="Entrar"
                />
              </div>
            </Form>

            <div align="center">
              <Link
                to="/account/recovery/"
                className="to_register"
                align="center"
              >
                Esqueci minha senha
              </Link>

              <p className="change_link">
                Novo por aqui?
                <Link to="/account/signup/" className="to_register">
                  Crie uma conta
                </Link>
              </p>

              <div className="separator"></div>

              <div className="logo-agri">
                <img src={logoIcon} alt="logo-mini" className="logo-mini" />
              </div>

              <p>
                ©2019 Todos Direitos Reservados. Política de Privacidade e
                Termos de Uso
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
