import React from 'react';
import { FaFileDownload } from "react-icons/fa";

export default (props) => {
    const bodyRef = React.createRef();
    const createPdf = () => props.createPdf(bodyRef.current);

    return (
        <section className="pdf-container">
            <section className="pdf-toolbar">
                <button
                 className="btn btn-default-export"
                  onClick={createPdf}
                   disabled={props.disabled}
                   >
                       {/* <i className="fas fa-file-download"></i> */}
                       <FaFileDownload />
                </button>
            </section>
            <section className="pdf-body" ref={bodyRef}>
                {props.children}
            </section>
        </section>
    )
}