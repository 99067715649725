import React from "react";
import { withTranslation } from "react-i18next";

import Title from "../../components/Title";

import { useParams } from "react-router-dom";
import "./Machine.css";

function DailyDetail(props) {
  const params = useParams();
  let current_color;

  return (
    <>
      {props.title === "true"
        ? (props.colors.map((color) => {
            if (color.equipment === props.machine.ident) {
              current_color = color.color;
            }
          }),
          (<Title flag_color={current_color} title={props.machine.ident} />))
        : null}
      <div className="col-xs-12 col-md-12 col-lg-12">
        <div className="x_panel_no_width col-xs-12 z-depth-2">
          <table className="table table-striped">
            <thead>
              <tr>
                <th colSpan={2}>{props.t("Velocidade")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-right">{props.t("Máxima")}: </td>
                <td>
                  {" "}
                  <strong> {props.machine.c.speed_max} km/h </strong>{" "}
                </td>
              </tr>

              <tr>
                <td className="text-right">{props.t("Média")}:</td>
                <td>
                  {" "}
                  <strong>{props.machine.c.speed_avg} km/h</strong>
                </td>
              </tr>

              <tr>
                <td className="text-right">{props.t("Kms rodados")}: </td>
                <td>
                  {" "}
                  <strong> {props.machine.c.km_rolled} km </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-xs-12 col-md-12 col-lg-12">
        <div className="x_panel_no_width col-xs-12 z-depth-2">
          <table className="table table-striped">
            <thead>
              <tr>
                <th colSpan={2}>{props.t("Horários")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-right">{props.t("Início")}: </td>
                <td>
                  {" "}
                  <strong> {props.machine.c.date_time_ini} </strong>{" "}
                </td>
              </tr>

              <tr>
                <td className="text-right">{props.t("Término")}:</td>
                <td>
                  {" "}
                  <strong>{props.machine.c.date_time_end}</strong>
                </td>
              </tr>

              <tr>
                <td className="text-right">{props.t("Trabalhadas")}: </td>
                <td>
                  {" "}
                  <strong> {props.machine.c.hours_work} </strong>
                </td>
              </tr>

              <tr>
                <td className="text-right">{props.t("Ocioso")}: </td>
                <td>
                  {" "}
                  <strong> {props.machine.c.hours_idle} </strong>
                </td>
              </tr>
              <tr>
                <td className="text-right">{props.t("Total")}: </td>
                <td>
                  {" "}
                  <strong> {props.machine.c.hours_totaly} </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {params.farm_id === 57 && (
        <>
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="x_panel_no_width col-xs-12 z-depth-2">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th colSpan={2}>{props.t("Ciclo")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-right">{props.t("T1")}: </td>
                    <td>
                      {" "}
                      <strong> {props.machine.c.cycle_info.T1} </strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{props.t("T2")}:</td>
                    <td>
                      {" "}
                      <strong>{props.machine.c.cycle_info.T2}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-right">{props.t("T3")}: </td>
                    <td>
                      {" "}
                      <strong> {props.machine.c.cycle_info.T3} </strong>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-right">{props.t("T4")}: </td>
                    <td>
                      {" "}
                      <strong> {props.machine.c.cycle_info.T4} </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default withTranslation()(DailyDetail);
