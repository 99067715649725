import React from 'react';
import {Link} from 'react-router-dom'

import {FaPlus} from 'react-icons/fa'

import '../App.css'

// btn-default-add-float
// classname='btn btn-default-export'
// classname='btn btn-primary btn-default-add'
const AddButton = props => {    
    // console.log(props.url)
    // console.log(props.classname)
        return(
            <div>
                <Link to={props.url} className={props.classname}>
                    {/* <i className="fas fa-plus"></i> */}
                    <FaPlus />
                </Link>
            </div>
        );    
}

export default AddButton;