import React , { Component } from 'react';

class Form extends Component{

    render(){
        return(
            <form class={this.props.class} name={this.props.name} method={this.props.method} action={this.props.action} >
                {this.props.children}
            </form>
        )
    }
}

export default Form;