import React from "react";
import KpiGadgetHeader from "./Kpi_gadget_header";
import KpiGadgetContent from "./Kpi_gadget_content";
import KpiGadgetFooter from "./Kpi_gadget_footer";
import { Link } from "react-router-dom";

function Kpi_gadget(props) {
  let { period_id } = props;

  function get_content() {
    switch (props.identity) {
      case "machines":
        return (
          <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
            <div className="x_panel no-margin-top">
              <div
                className="kpi_gadget_header"
                onClick={props.handleModal}
                style={{ cursor: "pointer" }}
              >
                <h3
                  className={`${props.header_class} bold pull-left no-margin`}
                >
                  {" "}
                  {props.label_header}{" "}
                </h3>
                {props.header_ico_class}
                <div className="clearfix"></div>
                <KpiGadgetContent
                  label={props.content_header}
                  value={props.content_value}
                  class={props.content_class}
                  first_value={props.first_value}
                />
                <KpiGadgetFooter
                  label={props.footer_header}
                  value={props.footer_value}
                  class={props.footer_class}
                />
              </div>
            </div>
          </div>
        );
        break;
      case "reports":
        return (
          <>
            <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
              <div className="x_panel no-margin-top">
                <Link to={props.main_link}>
                  <KpiGadgetHeader
                    label={props.label_header}
                    value={props.header_value}
                    class={props.header_class}
                    header_ico_class={props.header_ico_class}
                  />
                  <KpiGadgetContent
                    label={props.content_header}
                    value={props.content_value}
                    class={props.content_class}
                    first_value={props.first_value}
                  />
                  <KpiGadgetFooter
                    label={props.footer_header}
                    value={props.footer_value}
                    class={props.footer_class}
                  />
                </Link>
              </div>
            </div>
          </>
        );
        break;
      case "idle":
        return (
          <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
            <div className="x_panel no-margin-top">
              <div className="kpi_gadget_header">
                <h3
                  className={`${props.header_class} bold pull-left no-margin`}
                  onClick={props.handleModalHoursIdle}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {props.periods[period_id].pc}
                  {" % "}
                </h3>
                <div className="dropdown pull-right">
                  <button
                    className="btn  btn-sm dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    {props.periods[period_id].label}
                    <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu">
                    {props.periods.map((period) => {
                      return (
                        <li key={period.id}>
                          <a
                            href="#"
                            onClick={() => props.setPeriod(period.id)}
                          >
                            {period.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="clearfix"></div>
                <KpiGadgetContent
                  label={props.content_header}
                  value={props.content_value}
                  class={props.content_class}
                  first_value={props.first_value}
                />
                <KpiGadgetFooter
                  label={props.footer_header}
                  value={props.periods[period_id].hours}
                  class={props.footer_class}
                />
              </div>
            </div>
          </div>
        );
        break;

      case "operations":
        return (
          <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6">
            <div className="x_panel no-margin-top">
              <div
                className="kpi_gadget_header"
                onClick={props.handleModalOperation}
                style={{ cursor: "pointer" }}
              >
                <h3
                  className={`${props.header_class} bold pull-left no-margin`}
                >
                  {" "}
                  {props.label_header}{" "}
                </h3>
                {props.header_ico_class}
                <div className="clearfix"></div>
                <KpiGadgetContent
                  label={props.content_header}
                  value={props.content_value}
                  class={props.content_class}
                  first_value={props.first_value}
                />
                <KpiGadgetFooter
                  label={props.footer_header}
                  value={props.footer_value}
                  class={props.footer_class}
                />
              </div>
            </div>
          </div>
        );
        break;
      default:
        return <LoadingKPIS />;
    }
  }

  return <>{get_content()}</>;
}

export default Kpi_gadget;

function LoadingKPIS() {
  return (
    <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6 ">
      <div className="x_panel no-margin-top animate"></div>
    </div>
  );
}
