import Alert from "react-bootstrap/Alert";
import React from "react";
// import config from '../configs/config';
import { useTranslation } from "react-i18next";
import m from "moment";

export const default_error_message =
  "O servidor não conseguiu processar sua solicitação, por favor entre em contato com o suporte.";

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getErrorComponent = (msg) => {
  const { t } = useTranslation();
  return <Alert variant="danger">Erro : {t(msg)} </Alert>;
};

export const deltaDate = () => {
  var date = new Date();
  date.setDate(-1);
  return date;
};

export const formatTime = (time) => {
  let data = new Date(time),
    dia = data.getDate().toString(),
    diaF = dia.length === 1 ? "0" + dia : dia,
    mes = (data.getMonth() + 1).toString(),
    mesF = mes.length === 1 ? "0" + mes : mes,
    anoF = data.getFullYear(),
    hour = ("0" + data.getHours()).slice(-2).toString(),
    minutes = ("0" + data.getMinutes()).slice(-2).toString(),
    seconds = ("0" + data.getSeconds()).slice(-2).toString();

  return `${diaF}/${mesF}/${anoF} - ${hour}:${minutes}`; //  09/09/2000 -  12:54
};

export const getFormatedDate = (dt) => {
  let data = new Date(dt),
    dia = dt.getDate().toString(),
    diaF = dia.length === 1 ? "0" + dia : dia,
    mes = (data.getMonth() + 1).toString(),
    mesF = mes.length === 1 ? "0" + mes : mes,
    anoF = data.getFullYear(),
    hour = ("0" + data.getHours()).slice(-2).toString(),
    minutes = ("0" + data.getMinutes()).slice(-2).toString(),
    seconds = ("0" + data.getSeconds()).slice(-2).toString();

  return (
    anoF + "-" + mesF + "-" + diaF + "T" + hour + ":" + minutes + ":" + seconds
  ); // 2020-02-05T12:54:00
};

const unformatDateAndTime = (str) => {
  let date = str.slice(0, 10).split("/"),
    time = str.slice(11, 16).split(":"),
    d =
      date[2] +
      "-" +
      date[1] +
      "-" +
      date[0] +
      "T" +
      time[0] +
      ":" +
      time[1] +
      "-03:00";
  return new Date(d);
};

export const changeToBrowserTimezone = (date) => {
  if (!date) return null;
  const parsedDate = unformatDateAndTime(date);
  return m(parsedDate).format("DD/MM/YYYY HH:mm");
};

export const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

export const getTime = (time) => {
  let data = new Date(time),
    hour = ("0" + data.getHours()).slice(-2).toString(),
    minutes = ("0" + data.getMinutes()).slice(-2).toString();
  // seconds = ("0" + data.getSeconds()).slice(-2).toString()

  return hour + ":" + minutes; // 12:54
};

export const formatedDate = (dt) => {
  let data = new Date(dt),
    dia = data.getUTCDate().toString(),
    diaF = dia.length === 1 ? "0" + dia : dia,
    mes = (data.getUTCMonth() + 1).toString(),
    mesF = mes.length === 1 ? "0" + mes : mes,
    anoF = data.getUTCFullYear(),
    hour = ("0" + data.getHours()).slice(-2).toString(),
    minutes = ("0" + data.getMinutes()).slice(-2).toString(),
    seconds = ("0" + data.getSeconds()).slice(-2).toString();

  return `${diaF}/${mesF}/${anoF}`; // 11/11/2019
};
export const get_farm_id_from_url = () => {
  let pathname = window.location.pathname;
  if (pathname.length <= 1) {
    return 0;
  }
  let farm_id = Number(pathname[1]);
  return isNaN(farm_id) ? 0 : farm_id;
};

export const isChildrenEmpty = (data, text, content) => {
  // return map of the component
  // or auxiliar text, case is empty
  return (Object.keys(data).length === 0) === true ? text : content;
};
export const secondsToFormateTime = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds; // "5:39"
};

export const secondToMinute = (seconds) => {
  return (seconds / 60.0).toFixed(2);
};

// calculate distance between two points in kilometers
export function distance(lat1, lon1, lat2, lon2) {
  // haversine formula
  const toRadians = (degree) => (degree * Math.PI) / 180;

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers

  return distance;
}
