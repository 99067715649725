import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import Register from "./Register";
import Edit from "./Edit";
import MultOperations from "./MultOperations";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          path="/:farm_id/operation/:operation_id/change"
          component={Edit}
        />
        <Route path="/:farm_id/operation/add" component={Register} />
        <Route
          path="/:farm_id/operation/mult-operations"
          component={MultOperations}
        />
        <Route path="/:farm_id/operation/:operation_id" component={Detail} />
        <Route path="/:farm_id/operation" component={List} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
