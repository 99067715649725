import React from 'react';   
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';  

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: 5,
      minWidth: 195,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



  
export default function asMultipleSelect(props) {
    const classes = useStyles();    
    
    
    
    return (
        <div className={classes.root + " col-xs-12 col-md-3"}>          
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-chip">{props.title}</InputLabel>
            <Select
              multiple
              value={props.selected}
              onChange={props.handle}                            
              name="multi-select"
              input={<Input id="select-multiple-chip" />}              
              renderValue={selected => (
                <div className={classes.chips}>
                  {props.items.map(item => (   
                    selected.map(value => {      
                      if(item.id === value){
                        return <Chip key={value} label={props.isLandplot ? item.name :  item.ident} className={classes.chip} />
                      }
                    })
                  ))}                                         
                </div>
              )}
              MenuProps={MenuProps}
            >
              {props.items.map(item => (                                
                <MenuItem key={item.id} value={item.id} >
                  {props.isLandplot ? item.name : item.ident}
                </MenuItem>
              ))}
            </Select>
          </FormControl>          
        </div>
      );
    }