import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class CardActivity extends Component {
  render() {
    return (
      <div>
        <div className="col-xs-12 col-md-6 col-lg-6">
          <div className="x_panel_no_width col-xs-12 z-depth-2 cardfixed">
            <h2 className="x_title">
              {this.props.t("Talhão")} - {this.props.activity.name}
            </h2>

            <div className="clearfix"></div>
            <div className="x_content">
              <div className="col-xs-12  fixed-height-140">
                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id={"activities-r-" + this.props.activity.id}
                  >
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th colSpan="2" className="text-center">
                            {this.props.t("Atividades Realizadas")}:{" "}
                            {this.props.activity.ativi_realizadas}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.activity.atividades.length !== 0 ? (
                          <>
                            {this.props.activity.atividades.map((activity) => (
                              <tr>
                                <td className="text-right">
                                  {" "}
                                  {activity.name} :
                                </td>{" "}
                                <td>
                                  <strong> {activity.duration}</strong>{" "}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td className="text-right">
                            {this.props.t("Total de horas")}:{" "}
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {this.props.activity.horas_atividades}{" "}
                            </strong>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CardActivity);
