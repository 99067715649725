import React from "react";
import Input from "../../../../components/Input";
import StepControl from "../StepControl";
import { withTranslation } from "react-i18next";

function FourthOperationStep(props) {
  const [initial_ident] = React.useState("initialState");

  let ident = "";
  let error_component = "";

  if (props.ident === "") {
    ident = initial_ident;
  } else {
    ident = props.ident;
  }

  if (props.error_save !== "") {
    error_component = props.error_save;
  }

  return (
    <div className="col-xs-12 col-md-12 col-lg-12">
      <div>{props.error_save}</div>
      <div className="form-group">
        <strong>{props.t("Tipo de Operação")} :</strong>
        <span>{props.op_type_label}</span>
      </div>
      <div className="form-group">
        <Input
          handleChange={props.handleChangeText}
          strong="true"
          value={props.ident}
          type="text"
          class="form-control"
          label={props.t("Identificação da operação")}
          name="ident"
        />
      </div>
      {/* <div className="form-group">
          <Input
            handleChange={this.props.handleChangeText}
            strong="true"
            value={this.props.width}
            type="number"
            class="form-control"
            label={this.props.t("Largura de Trabaho")}
            name="width"
          />
        </div>
        <div className="form-group">
          <Input
            handleChange={this.props.handleChangeText}
            strong="true"
            value={parseInt(this.props.speed.replace(" km/h", ""))}
            type="number"
            class="form-control"
            label={this.props.t("Velocidade Adequada da Operação (km/h)")}
            name="speed"
          />
        </div> */}
      {/* {landplot} */}
      {/* <div className="form-group">
          <strong> {this.props.t("Operador")} :</strong>
          <span>{this.props.operator_label}</span>
          <br></br>
        </div>
        <div className="form-group">
          <strong>{this.props.t("Máquina")} :</strong>
          <span>{this.props.machine_label}</span>
          <br></br>
        </div> */}
      <StepControl
        handleSave={props.handleSave}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
        lastStep={props.lastStep}
        currentStep={props.currentStep}
      />
    </div>
  );
}

export default withTranslation()(FourthOperationStep);
