import React from "react";
import myConfig from "../../configs/config";
import axios from "axios";
import { withTranslation } from "react-i18next";

import { FaTrashAlt } from "react-icons/fa";

import "./Administrador.css";

function CardAdmin(props) {
  let { admin, farm_id } = props;

  function handleDelete(e) {
    const ADMIN_DELETE = `${myConfig.API_URL}/accounts/${admin.id}/?farm_id=${farm_id}`;

    if (
      !window.confirm(
        `Realmente deseja excluir o administrador: ${admin.first_name}  ?`
      )
    ) {
      return 0;
    } else {
      axios({
        baseURL: ADMIN_DELETE,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      }).then((res) => {
        alert("Administrador deletado com sucesso !");
        window.scrollTo(0, 0);
        window.location.href = `/${farm_id}/administrator/`;
      });
    }
  }

  return (
    <div>
      <div className="col-sm-12  col-md-6 col-lg-6">
        <div className="container">
          <div className="x_panel_no_width col-xs-12 z-depth-2 cardfix">
            <a className="pull-left border-aero">
              <img
                src={admin.thumbnail}
                className="img-circle profile_img"
                id="img-circle"
                alt="..."
              />
            </a>
            <div className="media-body">
              <div className="pull-right">
                <a onClick={handleDelete}>
                  <FaTrashAlt />
                </a>
              </div>
              <div className="container-fluid col-md-offset-1">
                <h4>
                  {admin.first_name} {admin.last_name}
                </h4>

                <p>
                  {props.t("Email")}: <b>{admin.email}</b>
                </p>
                <p>
                  {props.t("Telefone")}: <b>{admin.cell_phone}</b>
                </p>
                <p>
                  {props.t("Estado")}: <b>{admin.estado}</b>
                </p>
                <p>
                  {props.t("Cidade")}: <b>{admin.cidade}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(CardAdmin);
