import React, {  useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import myConfig from "../../configs/config";
import axios from "axios";
import SelectBox from "../../components/SelectBox";
import { withTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Link, withRouter, useParams, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

function FarmForm(props) {
  document.title = "Agriconnected - Painel";

  let params = useParams();
  let history = useHistory();

  let farmId = params.farm_id === undefined ? -1 : params.farm_id;

  let isValidFarmId = farmId !== -1;

  const [nome_da_fazenda, setNomeDaFazenda] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [tamanho_da_propriedade, setTamanhoDaPropriedade] = useState("");
  const [location, setLocation] = useState("");
  const [picture, setPicture] = useState("");

  const [geo_coords, setGeoCoords] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [gmapsLoaded, setMapsLoaded] = useState(false);
  const [country, setCountry] = useState(32);
  // const [point, setPoint] = useState(1);
  const [changed, setChanged] = useState(1);

  useEffect(() => {
    initGoogleMaps();

    getAllCountries();

    if (isValidFarmId) {
      getFarmData();
    }
  }, []);

  function initGoogleMaps() {
    window.initMap = initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA4vJdu3wgGjcEoLUz4sMaceo1deZbw4Gk&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  async function getAllCountries() {
    try {
      let COUNTRIES_URL = `${myConfig.API_URL}/countries`;

      let response = await axios({
        baseURL: COUNTRIES_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

   
      setListCountries(data);
   
    } catch (error) {
      // console.error(error)
      alert(
        "Falha ao carregar lista de países, recarregue a página e tente novamente"
      );
    }
  }

  async function getFarmData() {
    try {
      let GET_FARM = `${myConfig.API_URL}/farm/${farmId}/`;
      let response = await axios({
        baseURL: GET_FARM,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      let data = response.data;

      let country = data.country === 0 ? 32 : data.country;

      setNomeDaFazenda(data.nome_da_fazenda);
      setCep(data.cep);
      setEndereco(data.endereco);
      setNumero(data.numero);
      setCnpj(data.cnpj);
      setTamanhoDaPropriedade(data.tamanho_da_propriedade);
      setLocation(data.location);
      setGeoCoords(data.farm_center);
      setPicture(data.picture);
      setCountry(country);
    } catch (error) {
      // console.error(error);
      alert(
        "Falha ao carregar dados da fazenda, recarregue a página e tente novamente"
      );
    }
  }


  function initMap() {
    setMapsLoaded(true);
  }

  function getFormData() {
    let form_data = new FormData();
    
    if (typeof picture === "object" && picture !== null) {
      form_data.append("picture", picture, picture.name);
    }

    form_data.append("nome_da_fazenda", nome_da_fazenda);
    form_data.append("cep", cep);
    form_data.append("endereco", endereco);
    form_data.append("numero", numero);
    form_data.append("cnpj", cnpj);
    form_data.append("tamanho_da_propriedade", tamanho_da_propriedade);
    form_data.append("location", location);
    form_data.append("country", country);
    form_data.append(
      "geo_coords",
      `SRID=4326;POINT (${changed === true ? geo_coords.lat : geo_coords.ltd} ${
        geo_coords.lng
      })`
    );

    return form_data;
  }

  async function updateFarm() {
    try {
      let UPDATE_FARM = `${myConfig.API_URL}/farm/${farmId}/`;

      let form_data = getFormData();
      let response = await axios({
        baseURL: UPDATE_FARM,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
        data: form_data,
      });

      let { status } = response;

      if (status === 200) {
        toast.success(
          `${props.t("Fazenda")} ${props.t("atualizada com sucesso")} !`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {

          history.push(`/${farmId}/`);
        }, 2050);
      }

    } catch (error) {

      // console.log(error.response.data)
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " - ";
      });


      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  async function createFarm() {
    try {
      let CREATE_FARM = `${myConfig.API_URL}/farm/`;

      let form_data = getFormData();

      let response = await axios({
        baseURL: CREATE_FARM,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
        data: form_data,
      });

      let { status, data } = response;

      if (status === 201) {
        toast.success(
          `${props.t("Fazenda")} ${props.t("cadastrada com sucesso")} !`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          window.location.href = `/${data.id}/`;
        }, 2050);
      }

    } catch (error) {

      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " - ";
      });

      // setPoint(0);
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleSave(e) {
    e.preventDefault();

    if (isValidFarmId) {
      //PUT
      updateFarm();
    } else {
      //POST
      createFarm();
    }
  }
  function handleChange(location) {
    setLocation(location);
  }

  function handleSelect(location) {
    setLocation(location);

    geocodeByAddress(location)
      .then((results) =>

        getLatLng(results[0])
      )
      .then(
        (latLng) => setGeoCoords(latLng),
        setChanged(true)
      )
      .catch((error) => console.error("Error", error));
  } 

  return (
    <div id="propriedades_create_container">
  
      <form method="POST" onSubmit={handleSave} encType="multipart/form-data">
        <div className="avatar-user">
          <AvatarEditor
            className="avatar"
            image={picture}
            width={120}
            height={120}
            style={{ border: " 1px solid #f2f2f2f2" }}
            border={50}
            borderRadius={120 / (100 / 50)} //BORDER FOCUS 50%
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_picture">{props.t("Foto")}</label>
          <input
            type="file"
            onChange={(e) => setPicture(e.target.files[0])}
            name="picture"
            title={props.t("Insira uma foto")}
            id="id_picture"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_nome_da_fazenda">
            {props.t("Nome da fazenda")}
          </label>
          <input
            type="text"
            value={nome_da_fazenda}
            onChange={(e) => setNomeDaFazenda(e.target.value)}
            name="nome_da_fazenda"
            maxLength={35}
            className="form-control"
            placeholder={props.t("Nome")}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_cep">{props.t("CEP")}</label>
          <input
            type="text"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
            name="cep"
            maxLength={9}
            placeholder="00000-000"
            className="form-control"
            id="cep"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_endereco">{props.t("Endereço")}</label>
          <input
            type="text"
            value={endereco}
            onChange={(e) => setEndereco(e.target.value)}
            name="endereco"
            maxLength={35}
            className="form-control"
            placeholder={props.t("Endereço")}
            id="endereco"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_numero">{props.t("Número")}</label>
          <input
            type="text"
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
            name="numero"
            className="form-control"
            placeholder="Número"
            id="numero"
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_cnpj">{props.t("CNPJ ou CPF")}</label>
          <input
            type="text"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            name="cnpj"
            maxLength={15}
            className="form-control"
            placeholder="00.000.000/0000-00"
            id="cnpj"
          />
        </div>

        {
          listCountries.length > 0 ? (
            <div className="form-group">
              <SelectBox
                strong="true"
                data={listCountries}
                selected_value={country}
                value={"name"}
                change={(e) => setCountry(e.target.value)}
                label={props.t("Países")}
                id="select_countries"
              />
            </div>
          ) : (
  
            <div className="form-group">
              <label htmlFor="">{props.t("Países")}</label>
              <select className="form-control">
                <option value="">-------------</option>
              </select>
            </div>
          )
        }

        {gmapsLoaded && (
          <PlacesAutocomplete
            value={location}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div className="form-group">
                <label htmlFor="search_place"> {props.t("Localidade")}</label>
                <input
                  {...getInputProps({
                    placeholder: "Procurar localidade.",
                    className: "location-search-input form-control",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}

        <div className="form-group">
          <label htmlFor="id_area">{props.t("Área da Propriedade (ha)")}</label>
          <input
            type="text"
            value={tamanho_da_propriedade}
            onChange={(e) => setTamanhoDaPropriedade(e.target.value)}
            name="tamanho_da_propriedade"
            maxLength={35}
            className="form-control"
            placeholder={props.t("Área da Propriedade (ha)")}
            id="id_area"
            required
          />
        </div>

        <div align="center">
          <input
            type="submit"
            className="btn btn-primary btn-large"
            value={props.t("Salvar")}
            // disabled={point === 0 ? "disabled" : null}
          />
          <Link to={`/${farmId}/`} className="btn btn-default">
            {props.t("Cancelar")}
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );

}
export default withTranslation()(withRouter(FarmForm));
