import React from "react";

const Kpi_gadget_header = (props) => {
  return (
    <div className="kpi_gadget_header">
      <h3
        className={props.class + " bold pull-left no-margin"}
        onClick={props.class === "red" ? this.props.handleModal : null}
      >
        {" "}
        {props.label}{" "}
      </h3>
      {props.header_ico_class}
      <div className="clearfix"></div>
    </div>
  );
};

export default Kpi_gadget_header;
