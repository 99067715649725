import "./Machine.css";
import React, { Component } from "react";
// import { default_error_message, getErrorComponent } from '../../helpers/utils';
import BasicDate from "../../components/Date";
import DailyDetail from "./DailyDetail";
import Doc from "../../components/DocService";
import Line_map from "../../components/LineMap/index";
import MDSpinner from "react-md-spinner";
import PdfContainer from "../../components/PdfContainer";
import axios from "axios";
import myConfig from "../../configs/config";
import { withTranslation } from "react-i18next";

class MachineDailyReport extends Component {
  farmId = this.props.match.params.farm_id;
  machineId = this.props.match.params.machine_id;

  MACHINE_URL = `${myConfig.API_URL}/equipment/${this.machineId}/daily_report/?farm_id=${this.farmId}`;

  constructor(props) {
    super(props);
    this.state = {
      machine: [],
      loading: true,
      errors: [],
      last_point: "",
    };
    this.handChangeDate = this.handChangeDate.bind(this);
  }

  componentDidMount() {
    axios({
      baseURL: this.MACHINE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      // axios.get(this.MACHINE_URL)
      .then((res) => {
        const machine = res.data;
        this.setState({
          machine,
          loading: false,
          last_point: res.data.last_point[0],
        });
      })
      .catch((error) => {
        console.log(error);
        alert(
          "Falha ao carregar dados do equipamento, tente novamente mais tarde"
        );
      });
  }
  handleMapLoaded(e) {
    // console.log('handleMapLoaded')
  }

  getLineMap() {
    let map_attr = {
      equipment: this.state.machine,
    };

    return (
      <Line_map
        farm_id={this.props.match.params.farm_id}
        list_equipments={[map_attr]}
        last_point={this.state.last_point}
        handleLoaded={this.handleMapLoaded}
      />
    );
  }

  handChangeDate(e) {
    this.setState({ loading: true });

    let data = new Date(e),
      dia = e.getDate().toString(),
      diaF = dia.length === 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(),
      mesF = mes.length === 1 ? "0" + mes : mes,
      anoF = data.getFullYear();

    const DATE_REFRESH =
      this.MACHINE_URL + "&dt=" + anoF + "-" + mesF + "-" + diaF;

    axios({
      method: "GET",
      url: DATE_REFRESH,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((res) => {
        // console.log(res);
        const machine = res.data;
        this.setState({ machine, loading: false });
      })
      .catch((e) => {
        if (e.response.status !== 200) {
          this.setState({
            loading: false,
            // errors: this.state.errors.concat(getErrorComponent(default_error_message))
          });
        }
      });
  }

  createPdf = (html) => Doc.createPdf(html);

  render() {
    let content = "",
      loading = "",
      currrent_content = "";

    if (this.state.errors.length > 0) {
      return (
        <div
          style={{
            position: "absolute",
            marginTop: "25%",
            marginLeft: "25%",
            minHeight: "200px",
          }}
        >
          {this.state.errors.map((error) => (
            <div>{error}</div>
          ))}
        </div>
      );
    }

    content = (
      <div id="machine_daily_report">
        <PdfContainer createPdf={this.createPdf}>
          <div className="x_title">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-6">
                  <h1>
                    {" "}
                    {this.props.t("Trajeto")} - {this.state.machine.ident}
                  </h1>
                </div>
                <div
                  className="labelDate col-md-6 col-sm-12 col-xs-4"
                  id="labelDate"
                >
                  <BasicDate
                    date={this.state.machine.date}
                    handChangeDate={this.handChangeDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="map-container">{this.getLineMap()}</div>
          <DailyDetail
            handChangeDate={this.handChangeDate}
            machine={this.state.machine}
          />
        </PdfContainer>
      </div>
    );

    loading = (
      <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
        <MDSpinner />
      </div>
    );

    currrent_content = this.state.loading === true ? loading : content;

    return <div>{currrent_content}</div>;
  }
}
export default withTranslation()(MachineDailyReport);
