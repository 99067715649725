import React , {Component} from 'react';

class RadioButton extends Component{    

    render(){    
        let check = "";
        if(this.props.check === "true"){
            check = "checked";
        }

        return(
            <div className="radio">
                <label>{this.props.label}<input type="radio" label={this.props.label} className={this.props.class} onChange={this.props.change} name={this.props.name} value={this.props.value} checked={check} ></input></label>
            </div>
        );
    }
}

export default RadioButton;