import React from "react";
import myConfig from "../../configs/config";
// import cookies from '../../configs/cookies';
// import interceptor from '../../configs/interceptor';
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const Card_operator = (props) => {
  const { operator, farm_id } = props;

  function handleDelete(e) {
    e.preventDefault();

    const DELETE_URL = `${myConfig.API_URL}/operator/${operator.id}/?farm_id=${farm_id}`;

    if (
      !window.confirm(`Realmente deseja excluir o operador: ${operator.nome}`)
    ) {
      return 0;
    } else {
      axios.delete(DELETE_URL).then((res) => {
        alert("Operador deletado com sucesso !");
        window.scrollTo(0, 0);
        window.location.href = `/${farm_id}/operator`;
      });
    }
  }

  return (
    <div className="col-sm-12  col-md-6 col-lg-6">
      <div className="container">
        <div className="x_panel_no_width col-xs-12 z-depth-2">
          <a className="pull-left border-aero">
            <img
              src={operator.thumbnail}
              className="img-circle profile_img"
              id="img-circle"
              alt="..."
            />
          </a>
          <div className="media-body">
            <div className="pull-right">
              <Link to={operator.id + "/change"}>
                <FaEdit />
              </Link>
              <a onClick={handleDelete}>
                <FaTrashAlt />
              </a>
            </div>
            <div className="container-fluid col-md-offset-1">
              <h4>{operator.nome}</h4>

              <p>
                {props.t("Identificação")} : <b>{operator.ident}</b>
              </p>
              <p>
                {props.t("Telefone")} : <b>{operator.telefone}</b>
              </p>
              <p>
                {props.t("Custo")} {" (R$/h)"}: <b>{operator.horas_semanais}</b>
              </p>

              <div className="" align="right">
                <small>
                  {props.t("Cadastrado na fazenda")}:{" "}
                  <b>{operator.farm_name}</b>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Card_operator);
