import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class CardFleet extends Component {
  render() {
    return (
      <div>
        <div className="col-xs-12 col-md-6 col-lg-6">
          <div className="x_panel_no_width col-xs-12 z-depth-2 cardfixed">
            <h2 className="x_title"> {this.props.report.name_machine}</h2>
            <div className="clearfix"></div>
            <div className="x_content">
              <div className="col-xs-12 col-md-12h">
                {/* MOBILE OPÇÕES DENTRO DO CARD*/}
                <ul className="nav nav-tabs bar_tabs" role="tablist">
                  <li className="active">
                    <Link
                      to={"#hours-r-" + this.props.report.id}
                      data-toggle="tab"
                      role="tab"
                    >
                      {this.props.t("Horas")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"#activities-r-" + this.props.report.id}
                      data-toggle="tab"
                      role="tab"
                    >
                      {this.props.t("Atividades")}
                    </Link>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id={"hours-r-" + this.props.report.id}
                  >
                    <div className="col-xs-12">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th colSpan="2" className="text-center">
                              {this.props.t("Horas de motor")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-right">
                              {this.props.t("Total Ligado")}:{" "}
                            </td>
                            <td>
                              <strong> {this.props.report.online_time} </strong>{" "}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-right">
                              {this.props.t("Trabalhadas")}:
                            </td>
                            <td>
                              <strong> {this.props.report.hours_work} </strong>{" "}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-right">
                              {this.props.t("Ocioso")}:{" "}
                            </td>
                            <td>
                              <strong> {this.props.report.hours_idle} </strong>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-right">
                              {this.props.t("Km Rodados")}:{" "}
                            </td>
                            <td>
                              {" "}
                              <strong>
                                {" "}
                                {this.props.report.km_rolled} Km{" "}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* ATIVIDADES */}
                  <div
                    className="tab-pane"
                    id={"activities-r-" + this.props.report.id}
                  >
                    <div className="col-xs-12  fixed-height-140">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th colSpan="2" className="text-center">
                              {this.props.t("Atividades Realizadas")}:{" "}
                              {this.props.report.ativi_realizadas}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.report.atividades.length !== 0 ? (
                            this.props.report.atividades.map((reports) => (
                              <tr key={reports.name}>
                                <td className="text-right">
                                  {" "}
                                  {reports.name} :
                                </td>{" "}
                                <td>
                                  <strong> {reports.duration}</strong>{" "}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}

                          <tr>
                            <td className="text-right">
                              {this.props.t("Horas em atividades")}:{" "}
                            </td>
                            <td>
                              <strong>
                                {" "}
                                {this.props.report.horas_atividades}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(CardFleet);
