import React from 'react';
// import AddButton from './Add_button';
import '../App.css'

const Title = props =>{
    
        // let has_add_button = props.add_button;
        // let button = "";
        let content = "";
        // let flag = "";
        
        // if(has_add_button === 'true'){
        //     button = <AddButton url={`/${localStorage.farm}/operation/add`} classname="btn btn-primary btn-default-add"/>;
        // }

        

        if(props.size === "h1"){
            content = <h1>{props.title}<small>{props.farm_name}</small></h1>
        }else{
            content = <h2>{props.title}<small>{props.farm_name}</small></h2>
        }

        if(props.flag_color !== undefined){

            const flag_style = {                
                background: props.flag_color,
                opacity: 0.8
              };

            let title = content;
            content = <React.Fragment><i className='status-indicator' style={flag_style}></i> {title}</React.Fragment>;
        }


        return(
            <div className="x_title">
                {content}
                <div className="clearfix"></div>
                {/* {button}                 */}
                {props.children}
            </div>
        );    
}

export default Title;