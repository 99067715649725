import React, { useState, useEffect } from "react";

import axios from "axios";
import myConfig from "../../configs/config";
import cookies from "../../configs/cookies";
import { withTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";

import FloatingDropdown from "../../components/FloatingDropdown";
import ImportButton from "../../components/Import_button";
import CardLandplot from "./CardLandplot";
import MDSpinner from "react-md-spinner";
import { ToastContainer, toast } from "react-toastify";
import { Upload, Icon } from "antd";
import Modal from "../../components/Modal";

function ListLandplots(props) {
  const params = useParams();
  const history = useHistory();

  const farmId = params.farm_id;
  const EXPORT_LANDPLOTS_URL = `${myConfig.BE_URL}/${farmId}/landplots/farm.kml`;

  const [landplots, setLandplots] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { Dragger } = Upload;

  const getFiles = {
    name: "files",
    multiple: true,
    action: `${myConfig.API_URL}/landplot/${farmId}/import_landplots/`,
    accept: ".kml, .kmz, .shx, .shp, .dbf",
    headers: { "x-csrftoken": cookies },
    method: "post",
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        toast.success(`${info.file.name} Arquivo carregado com suscesso.`);
      } else if (status === "error") {
        toast.error(`${info.file.name} Falha ao carregar arquivo.`);
      }
    },
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLoading(true);
    getLandplots();
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0,0.5)",
    },
  };

  async function getLandplots() {
    try {
      let LANDPLOTS_URL = `${myConfig.API_URL}/landplot/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: LANDPLOTS_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setLandplots(data);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar talhões, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  useEffect(() => {
    document.title = "Agriconnected - Talhões";

    getLandplots();
  }, []);

  return (
    <div>
      {loading === false ? (
        <div>
          {isModalOpen && (
            <>
              <Modal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                style={modalStyle}
              >
                <form>
                  <Dragger
                    {...getFiles}
                    style={{ color: "#a0d911", borderColor: "#3EAF5C" }}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" style={{ color: "#a0d911" }} />
                    </p>
                    <p className="ant-upload-text">
                      Clique ou arraste o arquivo para importar
                    </p>
                    <p className="ant-upload-hint">
                      Tipos de arquivos suportados: .kml, .kmz e shapefile
                    </p>
                  </Dragger>
                </form>
              </Modal>

              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </>
          )}
          <div className="x_title">
            <h2>{props.t("Talhões")}</h2>
            <div className="clearfix"></div>
          </div>

          <FloatingDropdown>
            <li>
              <Link to={`/${farmId}/landplots/add`}>
                {props.t("Adicionar")}
              </Link>
            </li>
            <li>
              <Link onClick={openModal}>{props.t("Importar")}</Link>
            </li>
            <li>
              <a href={EXPORT_LANDPLOTS_URL}>{props.t("Exportar")}</a>
            </li>
          </FloatingDropdown>

          {landplots.map((landplot) => (
            <CardLandplot
              key={landplot.id}
              farm_id={farmId}
              landplot={landplot}
            />
          ))}
        </div>
      ) : (
        <LoadingLandplots />
      )}
    </div>
  );
}

function LoadingLandplots() {
  return (
    <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
      <MDSpinner />
    </div>
  );
}
export default withTranslation()(ListLandplots);
