import React from "react";
import TopNavigation from "./Navbar";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";

import logoIcon from "../../assets/images/logo.png";

import "./Base.css";
import { Hidden, Paper } from "@material-ui/core";

function Layout(props) {
  return (
    <div className="container body">
      <div className="main_container">
        {props.farm.id !== undefined ? (
          <>
            <TopNavigation
              farm_id={props.farm.id}
              farm_name={
                props.farm !== undefined ? props.farm.nome_da_fazenda : ""
              }
            />
            <Hidden xsDown>
              <Sidebar farm_id={props.farm.id} />
            </Hidden>
            <main
              className="right_col"
              role="main"
              style={{ minHeight: "595px" }}
            >
              <Paper className={`x_panel`} style={{ height: "auto" }}>
                {props.children}
              </Paper>
            </main>
          </>
        ) : (
          <div className="initial-loading">
            <img src={logoIcon} alt="..." className="logo-loading" />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (store) => ({
  farm: store.current_farm,
});

export default connect(mapStateToProps)(Layout);
