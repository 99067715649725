import React, { useState, useEffect } from "react";

// COMPONENTS
import KpisGadgets from "../KpiGadgets";

// CONFIGS
import { useParams, Link } from "react-router-dom";

// HELPERS
import PropTypes from "prop-types";

//SCREEN
import withWidth from "@material-ui/core/withWidth";

// ICONS

function Index(props) {
  const params = useParams();
  let { farm_id } = params;

  return (
    <div className="dashboard">
      <div className="row tile_count ind-container">
        <KpisGadgets farm_id={farm_id} />
      </div>
    </div>
  );
}

Index.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(Index);
