import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import { withTranslation } from "react-i18next";

registerLocale("pt-BR", ptBR);

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleCleanFilters = this.handleCleanFilters.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state === "") {
      this.setState(nextProps.states);
    }
  }

  handleCleanFilters() {
    this.setState(this.props.states);
    this.refs.form.reset();
  }

  handleChangeDate(dateName, date) {
    let { startDate, endDate } = this.state;

    if (dateName === "start") {
      startDate = date;
    } else {
      endDate = date;
    }

    this.setState({
      startDate,
      endDate,
    });
  }

  mountComponent(filter) {
    switch (filter.type) {
      case "date-start":
        return (
          <div>
            <DatePicker
              id={filter.name}
              selected={this.state.startDate}
              onChange={(date) => this.handleChangeDate(filter.date_type, date)}
              className="form-control"
              autoComplete="off"
              name={filter.name}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
            />
          </div>
        );
      case "date-end":
        return (
          <div>
            <DatePicker
              id={filter.name}
              selected={this.state.endDate}
              onChange={(date) => this.handleChangeDate(filter.date_type, date)}
              className="form-control"
              autoComplete="off"
              name={filter.name}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
            />
          </div>
        );
      default:
        return (
          <div>
            <input
              type={filter.type}
              value={this.state[filter.name]}
              onChange={(event) =>
                this.setState({ [filter.name]: event.target.value })
              }
              className="form-control"
              name={filter.name}
              id="formSearchField"
              placeholder={"Digite o " + filter.label}
            ></input>
          </div>
        );
    }
  }

  render() {
    return (
      <form
        className="form searchForm"
        method="GET"
        ref="form"
        onSubmit={this.props.handleSubmit}
      >
        <input type="hidden" name="farm_id" value={this.props.farm_id} />
        {this.props.filters.map((filter, index) => (
          <div className="form-group col-md-3 col-xs-12" key={filter.name}>
            <label htmlFor={filter.name}>{filter.label}</label>
            {this.mountComponent(filter)}
          </div>
        ))}
        {this.props.children}
        <input
          type="submit"
          className="btn btn-primary btnSearch"
          value={this.props.t("Buscar")}
        ></input>
        <button
          className="btn btn-default btnCleanSearch"
          onClick={() => {
            this.handleCleanFilters();
          }}
          value="Limpar"
        >
          {this.props.t("Limpar")}
        </button>
      </form>
    );
  }
}

export default withTranslation()(Search);
