import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import FormImplement from './FormImplement';
import ListImplement from './ListImplement';

class ImplementRoute extends Component {

    render() {
        return (
            <Switch>
                <Route path="/:farm_id/equipment/implement/:implement_id" component={FormImplement} />} />
                <Route path="/:farm_id/equipment/implement/add" component={FormImplement} />} />
                <Route path="/:farm_id/equipment/implement/" component={ListImplement} />} />
            </Switch>
        );
    }
}
export default withRouter(ImplementRoute)