import React, { useState, useEffect } from "react";
import axios from "axios";
import SelectBox from "../../components/SelectBox";
import myConfig from "../../configs/config";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function FormImplement(props) {
  const farmId = props.match.params.farm_id;
  const implementId =
    props.match.params.implement_id === "add"
      ? -1
      : props.match.params.implement_id;

  let hasImplement = implementId !== -1;

  let history = useHistory();

  //STATE
  const [ident, setIdent] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [manufacturies, setManufacturies] = useState([]);
  const [manufacturer, setManufacturer] = useState("");
  const [length, setLength] = useState("");

  async function loadCategories() {
    let CATEGORY_URL = `${myConfig.API_URL}/equipment_category/?classification=implemento`;

    let response = await axios({
      baseURL: CATEGORY_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    if (data) {
      setCategories(data);
    }
  }

  async function loadManufacturies() {
    let MANUFACTURER_URL = `${myConfig.API_URL}/manufacturer/`;

    let response = await axios({
      baseURL: MANUFACTURER_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setManufacturies(data);
  }

  async function loadSubCategories() {
    if (categories.length > 0) {
      let SUBCATEGORY_URL = `${myConfig.API_URL}/equipment_subcategory/?category=${category}`;
      let response = await axios({
        baseURL: SUBCATEGORY_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      // console.log("*****************");
      // console.log(response.data);
      // console.log("*****************");
      let data = response.data;

      setSubcategories(data);
    }
  }

  async function handleChangeCategory(e) {
    e.preventDefault();

    setCategory(e.target.value);

    let SUBCATEGORY_URL = `${myConfig.API_URL}/equipment_subcategory/?category=${e.target.value}`;

    let response = await axios({
      baseURL: SUBCATEGORY_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setSubcategories(data);
  }

  async function getCurrentImplement() {
    let IMPLEMENT_URL = `${myConfig.API_URL}/implement/${implementId}/`;

    let response = await axios({
      baseURL: IMPLEMENT_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setIdent(data.ident);
    setCategory(data.category);
    setSubcategory(data.sub_category);
    setManufacturer(data.manufacturer);
    setLength(data.length);
  }

  async function createImplement() {
    let CREATE_IMPLEMENT = `${myConfig.API_URL}/implement/`;

    try {
      let response = await axios({
        baseURL: CREATE_IMPLEMENT,
        method: "POST",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 201) {
        toast.success(
          `${props.t("Implemento")}  ${props.t("cadastrada com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/equipment/implement/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function updateImplement() {
    try {
      let UPDATE_IMPLEMENT = `${myConfig.API_URL}/implement/${implementId}/`;

      let response = await axios({
        baseURL: UPDATE_IMPLEMENT,
        method: "put",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 200) {
        toast.success(
          `${props.t("Implemento")}  ${props.t("atualizada com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/equipment/implement/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function getFormData() {
    let form_data = new FormData();
    form_data.append("ident", ident);
    form_data.append("farm", farmId);
    form_data.append("length", length.replace(",", "."));
    form_data.append("manufacturer", manufacturer);
    form_data.append("category", category);
    form_data.append("sub_category", subcategory);
    return form_data;
  }

  function handleSave(e) {
    e.preventDefault();

    if (hasImplement) {
      // PUT
      updateImplement();
    } else {
      //POST
      createImplement();
    }
  }

  useEffect(() => {
    document.title = "Agriconnected - Implementos";

    loadCategories();

    loadManufacturies();

    if (hasImplement) {
      getCurrentImplement();
    }
  }, []);

  useEffect(() => {
    if (hasImplement) loadSubCategories();
  }, [categories]);

  return (
    <div id="implement_form_container">
      <form method="POST" onSubmit={handleSave}>
        <div className="form-group">
          <label htmlFor="id_ident">{props.t("Identificação")}</label>
          <input
            type="text"
            value={ident}
            onChange={(e) => setIdent(e.target.value)}
            name="ident"
            maxLength={35}
            className="form-control"
            placeholder={props.t("Identificação")}
            required
          />
        </div>

        {categories.length > 0 ? (
          <SelectBox
            strong="true"
            data={categories}
            selected_value={category}
            value={"name"}
            change={handleChangeCategory}
            label="Categoria"
            id="id_category"
          />
        ) : (
          <div>
            <label htmlFor="">{props.t("Categoria")}</label>
            <select name="" className="form-control">
              <option value="">-------------</option>
            </select>
          </div>
        )}
        {subcategories.length > 0 ? (
          <SelectBox
            strong="true"
            data={subcategories}
            selected_value={subcategory}
            value={"desc"}
            change={(e) => setSubcategory(e.target.value)}
            label="Sub Categoria"
            id="id_subcategory"
          />
        ) : (
          <div>
            <label htmlFor="">{props.t("Sub Categoria")}</label>
            <select name="" className="form-control">
              <option value="">-------------</option>
            </select>
          </div>
        )}

        {manufacturies.length > 0 ? (
          <SelectBox
            strong="true"
            data={manufacturies}
            selected_value={manufacturer}
            value={"name"}
            change={(e) => setManufacturer(e.target.value)}
            label="Fabricantes"
            id="id_manufacturer"
          />
        ) : (
          <div>
            <label htmlFor="">{props.t("Fabricantes")}</label>
            <select name="" className="form-control">
              <option value="">-------------</option>
            </select>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="id_length">{props.t("Largura (metros)")}</label>
          <input
            type="text"
            value={length}
            onChange={(e) => setLength(e.target.value)}
            name="length"
            maxLength={7}
            className="form-control"
            placeholder={props.t("Largura (metros)")}
            required
          />
        </div>

        <div align="center">
          <input
            type="submit"
            className="btn btn-primary"
            value={props.t("Salvar")}
          />
          <Link
            to={`/${farmId}/equipment/implement/`}
            className="btn btn-default"
          >
            {props.t("Cancelar")}
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default withTranslation()(withRouter(FormImplement));
