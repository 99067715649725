import React, { Component } from 'react';
import Kpi_gadget_header from './Kpi_gadget_header';
import Kpi_gadget_content from './Kpi_gadget_content';
import Kpi_gadget_footer from './Kpi_gadget_footer';

class Kpi_gadget extends Component{

    render(){
        return(            
                <div className="col-md-6ths col-sm-4 col-xs-12 tile_stats_count">                
                    <Kpi_gadget_header label={this.props.label_header} value={this.props.header_value} class={this.props.header_class} />
                    <Kpi_gadget_content label={this.props.content_header} value={this.props.content_value} class={this.props.content_class}/>
                    <Kpi_gadget_footer label={this.props.footer_header} value={this.props.footer_value}/>
                </div>            
        );
    }


}

export default Kpi_gadget;