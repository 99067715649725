import axios from 'axios';
import myConfig from './config';
// import cookies from './cookies';

// export const interceptor = axios.interceptors.request.use(async (config) => {    
    
//     config.headers['x-csrftoken'] = cookies;        
//     config.headers['accept'] = 'application/json';
//     config.headers['content-type'] = 'application/json';
    
//     return config;       

//   }, (error) => {    
//     return Promise.reject(error);
// });

export default function addInterceptors() {
  axios.interceptors.request.use((request) => {
    let accessToken = localStorage.getItem("client-token");
    if(accessToken) {
        request.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return request;
  });
  axios.interceptors.response.use(function (response) {
      return response;
  }, function (error) {
      
    if(error.response){
        
        if (error.response.data.error !== "invalid_grant"){
          
          if (401 === error.response.status) {
            
            const refresh_token = localStorage.getItem('refresh_token')
            
            if (refresh_token) {

              const data = new FormData()
              data.append('refresh_token', refresh_token)
              data.append('grant_type', 'refresh_token')
              data.append('client_id', 'api')
              data.append('client_secret', 'OdcffQR0bZVl4NDW1cXuvGpf1CmRxjyMyDxL7yWrGeIpbpSv5thpJKGDFWYkVUkTQfbllgJPmsixmM07yaozCcbWzuCXVoZ5qUpyKrH7gBI5KuyIAiMK1lcj4hzSPUQC')

              axios({
                  baseURL: myConfig.BE_URL + "/o/token/",
                  method: 'post',
                  data: data,
              }).then(res => {
                  if (res.status === 200) {
                      localStorage.setItem('client-token', res.data.access_token);
                      localStorage.setItem('refresh_token', res.data.refresh_token);
                      window.location.reload(false);
                  }
              }).catch(error2 => {
                localStorage.clear();
                window.location.href = '/';     
              });

            }
          }
        }
      }
      return Promise.reject(error);
  });
}