import React from 'react';

import { FaFileDownload } from "react-icons/fa";

import '../App.css'
// btn-default-add-float
// btn-default-add-float
// {props.classname}
// classname='btn btn-default-export'
const ExportButton = props => {    
        return(
            <div>
                <a href={  props.export  } className={props.classname} title={props.title}>
                    {/* <i className="fas fa-file-download"></i> */}
                    <FaFileDownload />
                </a>
            </div>
        );    
}

export default ExportButton;