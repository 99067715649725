import React, { useState, useEffect } from "react";
import axios from "axios";
import myConfig from "../../configs/config";
import MDSpinner from "react-md-spinner";
import { withTranslation } from "react-i18next";
import ExportButton from "../../components/Export_button";
import AddButton from "../../components/Add_button";
import { isChildrenEmpty } from "../../helpers/utils";
import { Link } from "react-router-dom";

import { FaEdit, FaTrashAlt, FaEye, FaTools } from "react-icons/fa";

function ListMachine(props) {
  const farmId = props.match.params.farm_id;

  const [listMachines, setMachines] = useState([]);
  const [loading, setLoding] = useState(true);

  useEffect(() => {
    document.title = "Agriconnected - Máquinas";
    getMachines();
  }, []);

  async function getMachines() {
    try {
      let MACHINE_LIST_URL = `${myConfig.API_URL}/equipment/?farm_id=${farmId}`;

      const response = await axios({
        baseURL: MACHINE_LIST_URL,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setMachines(data);
        setLoding(false);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar máquinas, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  async function handleDelete(e, { id, ident }) {
    e.preventDefault();
    let DELETE_MACHINE = `${myConfig.API_URL}/equipment/${id}/`;

    if (!window.confirm(`Realmente deseja excluir a  máquina: ${ident} ?`)) {
      return 0;
    } else {
      await axios({
        baseURL: DELETE_MACHINE,
        method: "delete",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      }).then((res) => {
        window.scrollTo(0, 0);
        let machines = listMachines.filter((machines) => machines.id !== id);
        setMachines(machines);
      });
    }
  }

  return (
    <>
      {loading === false ? (
        <div id="machine_list_container">
          <div className="x_title">
            <h2>{props.t("Máquinas")}</h2>
            <div className="clearfix"></div>
          </div>

          <AddButton
            url={`/${farmId}/equipment/machine/add/`}
            classname="btn btn-primary btn-default-add"
          />
          <ExportButton
            classname="btn btn-default-export"
            export={`${myConfig.API_URL}/equipment/export_equipment/?farm_id=${farmId}`}
          />

          {isChildrenEmpty(
            listMachines,
            <h4 align="center">
              {props.t(
                "Está fazenda ainda não possui nenhuma máquina cadastrada."
              )}
            </h4>,
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{props.t("Identificação")}</th>
                  <th>{props.t("Marca")}</th>
                  <th className="text-center"> {props.t("Ação")}</th>
                </tr>
              </thead>
              <tbody>
                {listMachines.map((machine) => (
                  <tr key={machine.ident}>
                    <td>{machine.ident}</td>
                    <td>{machine.marca}</td>
                    <td align="center">
                      <div className="btn-group">
                        <Link to={`${machine.id}/report`} className="btn">
                          <FaEye />
                        </Link>
                        <Link to={`${machine.id}/`} className="btn">
                          <FaEdit />
                        </Link>
                        <a
                          onClick={(event) => handleDelete(event, machine)}
                          className="btn"
                        >
                          <FaTrashAlt />
                        </a>
                        <Link to={`${machine.id}/appointments`} className="btn">
                          <FaTools />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <div
          style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}
        >
          <MDSpinner />
        </div>
      )}
    </>
  );
}

export default withTranslation()(ListMachine);
