import React, { useState } from 'react';
import Modal_header from '../../components/Modal_header';
import Modal_footer from '../../components/Modal_footer';
import myConfig from '../../configs/config';
import { withTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Button from "../../components/Button";


const OperationReport = props => {
    const [open, setOpen] = React.useState(false);

    let REPORT_URL = myConfig.ABSOLUTE_URL + props.farm_id + "/operation/" + props.operation_id + "/report"


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // render() {
    return (
        <>
            <Button
                class="btn btn-default pull-left btn-report"
                click={handleOpen}
                disabled
            >
                <i className="far fa-file-alt mr-5"></i>
                {props.t('Gerar Relatório')}
            </Button>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div className='modal-size'>
                    <Modal_header title={props.t('Relatório')} />
                    <div className="modal-body">
                        <form method="GET" action={REPORT_URL} id="report_form">
                            <input
                                type="hidden"
                                name="operation_id"
                                value={props.operation_id}
                            />
                            <div className="form-group">
                                <label htmlFor="client">{props.t('Cliente')}: </label>
                                <input
                                    type="text"
                                    name="client"
                                    className="form-control"
                                    placeholder={props.t('Cliente')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="locale">{props.t('Local')}</label>
                                <input
                                    type="text"
                                    name="locale"
                                    className="form-control"
                                    placeholder={props.t('Local')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="km_value">{props.t('Valor por KM')}: </label>
                                <input
                                    type="number"
                                    step="0.01"
                                    name="km_value"
                                    className="form-control"
                                    placeholder={props.t('Valor por KM')}
                                />
                            </div>
                        </form>
                    </div>
                    <Modal_footer>
                        <button type="submit" className="btn btn-default" form="report_form">
                            <i className="far fa-file-alt mr-5"></i>
                            {props.t('Gerar Relatório')}
                        </button>
                        <button 
                            type="button"
                            className="btn btn-default" 
                            data-dismiss="modal"
                            onClick={handleClose}
                        >
                            {props.t('Voltar')}
                        </button>                          
                    </Modal_footer>
                </div>
            </Modal>
            </>
        );  
    }
export default withTranslation()(OperationReport);
