import React, { Component } from "react";
import myConfig from "../../configs/config";
import MDSpinner from "react-md-spinner";
import { Collapse } from "reactstrap";

import GoogleMapReact from "google-map-react";
import {
  secondToMinute,
  secondsToFormateTime,
  formatTime,
} from "../../helpers/utils";
import { withTranslation } from "react-i18next";

import stoppedIcon from "../../assets/icons/stopped.ico";
import offlineIcon from "../../assets/icons/offline.ico";
import alertIcon from "../../assets/icons/alert_24x24.ico";
import axios from "axios";
import { getErrorComponent } from "../../helpers/utils";

import "./index.css";
import { withTheme } from "@material-ui/core";

export class Line_map extends Component {
  isMultiple = this.props.multiple === "true";
  isSearched = this.props.searched === true;
  LANDPLOT_URL = `${myConfig.API_URL}/landplot/?farm_id=`;

  constructor(props) {
    super(props);
    this.state = {
      //- Azul - Vermelho - Amarelo - Laranja - Roxo - Magenta - Cyano - Ocre - Bordo - Petróleo - Azul naval - Quentão - Rosa
      colors: [
        "#031AA5",
        "#DB0C03",
        "#F5B002",
        "#F36924",
        "#69029B",
        "#F100F7",
        "#23A1C6",
        "#857012",
        "#740224",
        "#0F565C",
        "#040244",
        "#AE4E46",
        "#DF1B5F",
      ],
      equipments: [],
      cordenates: [],
      lines: [],
      loading: true,
      tilesloaded: null,
      center: "",
      multiple_lines: "",
      last_equipment: "",
      icon: "",
      collapsePoints: false,
      mapZoom: 16,
      isActiveStops: false,
      isActiveSpeeds: false,
      isActiveOthers: false,
      showPolygonInfoWindow: {},
      landplots: [],
    };
  }

  async componentWillMount() {
    this.load_landplot_data();
  }

  componentDidMount() {
    this.setState({ center: this.props.last_point });
  }

  loadEquipmentsData() {
    let equipments = [],
      lines = [],
      multiple_lines = "";

    if (this.isSearched) {
      equipments = [];
    }

    this.renderEquipments(equipments, lines);

    if (this.isMultiple) {
      multiple_lines = this.methodMultipleLines(multiple_lines, lines);
    }

    this.setState({
      multiple_lines: multiple_lines,
      tilesloaded: this.isMultiple ? equipments : null,
    });
  }

  load_landplot_data() {
    axios({
      baseURL: this.LANDPLOT_URL + this.props.farm_id,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((res) => {
        let landplots = res.data;
        if (res.status === 200) {
          this.setState({
            landplots: landplots,
            loading: false,
          });
        } else {
          this.setState({
            errors: this.state.errors.concat(
              getErrorComponent(res.data.detail)
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderEquipments(equipments: any[], lines: any[]) {
    let icon = "",
      cordenates = [],
      multiple_lines = "",
      last_equip = "",
      line_color = "";

    this.getDatafromEquipments(line_color, last_equip, equipments, lines);
    ({ icon } = this.returnDatafromEquipment(
      lines,
      cordenates,
      icon,
      equipments
    ));

    this.setState({
      cordenates,
      equipments,
      lines,
      multiple_lines,
    });
  }

  getDatafromEquipments(
    line_color: string,
    last_equip: string,
    equipments: any[],
    lines: any[]
  ) {
    let { list_equipments } = this.props;
    for (let i = 0; i < list_equipments.length; i++) {
      line_color = this.equipmentRandomColor(line_color, i, last_equip);

      equipments.push({
        equipment: list_equipments[i].equipment.ident,
        color: line_color !== undefined ? line_color : "#0000FF",
        icon: list_equipments[i].equipment.traccar_ico,
        last_point: list_equipments[i].equipment.last_point,
        date: list_equipments[i].equipment.date,
        ident: list_equipments[i].equipment.ident,
        c: list_equipments[i].equipment.c,
      });

      list_equipments[i].equipment.c.path.map((item) => {
        let itens = {
          coords: item,
          color: line_color !== undefined ? line_color : "#0000FF",
          icon: list_equipments[i].equipment.traccar_ico,
        };

        lines.push(itens);
        return { lines, equipments };
      });
    }
    return line_color;
  }

  equipmentRandomColor(line_color: string, i: number, last_equip: string) {
    line_color = i === 0 ? this.getRandomColor(i) : "";
    line_color =
      last_equip !== this.props.list_equipments[i].equipment
        ? this.getRandomColor(i)
        : "";
    return line_color;
  }

  getRandomColor(i) {
    let color = this.state.colors[i];
    let { colors } = this.state;

    if (i >= colors.length) {
      color = colors[Math.round(Math.random(0, colors.length))];
    }
    return color;
  }

  returnDatafromEquipment(
    lines: any[],
    cordenates: any[],
    icon: string,
    equipments: any[]
  ) {
    lines.map((line) => {
      cordenates.push(line);
      icon = equipments[0].icon;
    });
    return { icon };
  }

  methodMultipleLines(multiple_lines: string, lines: any[]) {
    multiple_lines =
      lines[lines.length - 1].coords[
        lines[lines.length - 1].coords.length - 1
      ] === undefined
        ? lines[lines.length - 1].coords
        : lines[lines.length - 1].coords[
            lines[lines.length - 1].coords.length - 1
          ];
    return multiple_lines;
  }

  setMarker(google) {
    let icon = this.state.equipments[0].icon,
      equipments_len = this.state.equipments.length;

    ({ icon } = this.methodMarker(icon, equipments_len, google));
  }

  methodMarker(icon: any, equipments_len: any, google: any) {
    let { equipments } = this.state,
      marker,
      x = 0,
      center;

    equipments.map(function (equipment) {
      if (x < equipments_len) {
        x++;
        return (
          (marker = new google.maps.Marker({
            icon: require(`../../assets/traccar/online/${equipment.icon}.svg`),
            position: equipment.last_point[0],
          })),
          marker.setMap(google.map)
        );
      }
    });
    return { center, icon };
  }

  setPolyline = (google) => {
    let poly;
    let { cordenates } = this.state;

    if (cordenates.length > 0) {
      return cordenates.map((codernate) => {
        return (
          (poly = new google.maps.Polyline({
            path: codernate.coords,
            strokeColor: codernate.color,
            strokeOpacity: 0.5,
            strokeWeight: 4,
          })),
          poly.setMap(google.map)
        );
      });
    }
  };

  setPolygon = (google) => {
    this.state.landplots.forEach((lp) => {
      let geometry = JSON.parse(lp.geomap);
      let coords = geometry.coordinates[0].map(function (item) {
        return { lat: item[1], lng: item[0] };
      });
      let polygon = new google.maps.Polygon({
        paths: coords,
        strokeColor: "#00709E",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00709E",
        fillOpacity: 0.35,
      });
      polygon.setMap(google.map);

      let marker = new google.maps.Marker({
        position: lp.landplot_center,
        label: { text: lp.name, color: "white" },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0,
          labelOrigin: new google.maps.Point(0, 0),
        },
      });
      marker.setMap(google.map);
    });
  };

  setMarkersStoppedTime(google) {
    let { list_equipments } = this.props;

    this.methodMarkersStoppedTime(list_equipments, google);
  }

  methodMarkersStoppedTime(data: any, google: any) {
    data.forEach((item) => {
      this.renderMarkersStoppedTime(google, item.equipment.c.stopped_points);
    });
  }

  renderMarkersStoppedTime(google, equipment) {
    let marker;
    let markers = [];
    let infowindow = new google.maps.InfoWindow();

    equipment.forEach((data) => {
      if (secondToMinute(data.stopped_time) >= 5) {
        marker = new google.maps.Marker({
          icon: data.status === "start" ? offlineIcon : stoppedIcon,
          position: new google.maps.LatLng(data.lat, data.lng),
          map: google.map,
        });

        google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(
                `<div>
                    <strong style="font-weight: bold;">Equipamento:</strong> ${
                      data.ident
                    } <br> 
                    <strong style="font-weight: bold;">Tempo:</strong> ${secondsToFormateTime(
                      data.stopped_time
                    )} min
                    <br> 
                    <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                      data.time
                    )}
                  </div>`
              );
              infowindow.open(google.map, marker);
            };
          })(marker)
        );

        markers.push(marker);

        function clearMarkers() {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }

        google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("others-tab"),
          "click",
          clearMarkers
        );

        google.maps.event.addDomListener(
          document.getElementById("clearPoints"),
          "click",
          clearMarkers
        );
      }
    });
  }

  handleGoogleMapApi = (google) => {
    // console.log("handleGoogleMapApi!", google);

    window.google = google;

    this.loadEquipmentsData();
    this.setPolygon(google);
    this.setMarker(google);
    this.setPolyline(google);

    // FERRAMENTAS DE DESENHO DO POLIGONO
    let drawingManager = new google.maps.drawing.DrawingManager({
      drawingControlOptions: {
        drawingModes: ["polygon", "polyline"],
        position: window.google.maps.ControlPosition.TOP_CENTER,
      },
      drawingControl: true,
      polylineOptions: {
        editable: true,
        strokeColor: "#00e600",
        fillColor: "#00e600",
        fillOpacity: 0.4,
        strokeWeight: 6,
      },
      polygonOptions: {
        editable: true,
        strokeColor: " #ff9933",
        fillColor: " #ff9933",
        fillOpacity: 0.4,
        strokeWeight: 5,
      },
    });

    drawingManager.setMap(google.map);

    google.maps.event.addListener(
      drawingManager,
      "polylinecomplete",
      function (polyline) {
        let lengthInMeters = google.maps.geometry.spherical.computeLength(
          polyline.getPath()
        );
        alert(
          `O tamanho da linha em metros é de ${Math.round(lengthInMeters)} m`
        );

        function clearPolygon() {
          polyline.setMap(null);
        }

        google.maps.event.addDomListener(
          document.getElementById("CoordsButton"),
          "click",
          clearPolygon
        );
      }
    );

    // FUNÇÃO PARA TRABALHAR  OS DADOS DO POLIGONO
    google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      function (polygon) {
        let path = polygon.getPath();

        //METROS
        let area = google.maps.geometry.spherical.computeArea(path);
        area = Math.trunc(area);

        //HECTARE
        let ha = area / 10000;
        alert(ha.toFixed(2) + " ha");

        drawingManager.setMap(null); //SETAR AS OPÇÕES DE DESENHO

        function clearPolygon() {
          polygon.setMap(null);
          drawingManager.setMap(google.map);
        }
        google.maps.event.addDomListener(
          document.getElementById("CoordsButton"),
          "click",
          clearPolygon
        );
      }
    );

    this.forceUpdate();
  }; //FIM HANDLE

  getMapOptions = (maps) => {
    return {
      streetViewControl: true,
      scaleControl: true,
      fullscreenControl: true,
      fullscreenControlOptions: { position: maps.ControlPosition.TOP_LEFT },
      gestureHandling: "greedy",
      disableDoubleClickZoom: false,
      minZoom: 2,
      mapTypeId: maps.MapTypeId.SATELLITE,
      zoomControl: true,
      clickableIcons: false,
    };
  };

  methodMarkersSpeedData(data, google) {
    data.forEach((item) => {
      this.renderMarkersSpeedData(google, item);
    });
  }

  renderMarkersSpeedData(google, equipment) {
    let marker;
    let markers = [];
    let infowindow = new google.maps.InfoWindow();

    for (let i = 0; i < equipment.c.path.length; i++) {
      equipment.c.path[i].forEach((data) => {
        if (data.hasOwnProperty("speed")) {
          marker = new google.maps.Marker({
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              rotation: data.course,
              strokeColor: equipment.color,
              fillColor: equipment.color,
              scale: 3,
              strokeWeight: 3.5,
              fillOpacity: 0.9,
            },
            position: new google.maps.LatLng(data.lat, data.lng),
            map: google.map,
          });

          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              return function () {
                infowindow.setContent(
                  `<div>
                    <strong style="font-weight: bold;">Velocidade:</strong> ${
                      data.speed
                    } <br>   
                    <br> 
                    <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                      data.time
                    )} min
                    <br>          
                </div>`
                );
                infowindow.open(google.map, marker);
              };
            })(marker)
          );

          markers.push(marker);

          function clearMarkers() {
            for (let i = 0; i < markers.length; i++) {
              markers[i].setMap(null);
            }
          }

          google.maps.event.addDomListener(
            document.getElementById("stops-tab"),
            "click",
            clearMarkers
          );

          google.maps.event.addDomListener(
            document.getElementById("others-tab"),
            "click",
            clearMarkers
          );

          google.maps.event.addDomListener(
            document.getElementById("clearPoints"),
            "click",
            clearMarkers
          );
        }
      });
    }
  }

  setMarkersSpeedPoints() {
    let google = window.google;
    let { equipments } = this.state;

    this.methodMarkersSpeedData(equipments, google);
  }

  TabSpeed() {
    return (
      <li
        className={`nav-item`}
        onClick={() =>
          this.setState({
            isActiveSpeeds: true,
            isActiveStops: false,
            isActiveOthers: false,
          })
        }
      >
        <a
          className="nav-link active"
          id="speed-table"
          data-toggle="tab"
          href="#speed"
          role="tab"
          aria-controls="speed"
          aria-selected="true"
          onClick={() => this.setMarkersSpeedPoints()}
        >
          {this.props.t("Velocidade")}
        </a>
      </li>
    );
  }

  TabsSpeedContent() {
    return (
      <div
        className="  table-responsive"
        id="speed"
        role="tabpanel"
        aria-labelledby="speed-table"
      >
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col"> {this.props.t("Data/Hora")}</th>
              <th scope="col"> {this.props.t("Velocidade")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.list_equipments.map((eq) =>
              eq.equipment.c.path.map((item) =>
                this.returnRowsTableSpeedData(item)
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }

  handleMarkerCenter = (e, data) => {
    e.preventDefault();

    let { lat, lng } = data;

    this.setState({
      center: { lat: lat, lng: lng },
      mapZoom: 30,
    });
  };

  returnRowsTableSpeedData(durations: any) {
    return durations.map((item, index) => {
      return item.hasOwnProperty("speed") ? (
        <tr
          key={index}
          style={{ fontSize: "13px", cursor: "pointer" }}
          onClick={(e) => this.handleMarkerCenter(e, item)}
        >
          <td> {formatTime(item.time)}</td>
          <td> {item.speed}</td>
        </tr>
      ) : null;
    });
  }

  handleShowStoppedPoints() {
    let google = window.google;

    this.setMarkersStoppedTime(google);
  }

  TabStops() {
    return (
      <li
        className={`nav-item `}
        onClick={() =>
          this.setState({
            isActiveStops: true,
            isActiveSpeeds: false,
            isActiveOthers: false,
          })
        }
      >
        <a
          className="nav-link stopped-control"
          id="stops-tab"
          data-toggle="tab"
          href="#stops"
          role="tab"
          aria-controls="stops"
          aria-selected="false"
          onClick={() => this.handleShowStoppedPoints()}
        >
          {this.props.t("Paradas")}
        </a>
      </li>
    );
  }

  TableStoppedPoints() {
    return (
      <div
        className="  table-responsive"
        id="stops"
        role="tabpanel"
        aria-labelledby="stops-tab"
      >
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col"> {this.props.t("Data/Hora")}</th>
              <th scope="col"> {this.props.t("P/D")}</th>
              <th scope="col"> {this.props.t("Duração")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.list_equipments.map((eq) =>
              this.returnRowsTableStoppedPoints(eq.equipment.c.stopped_points)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  returnRowsTableStoppedPoints(stopped_points: any) {
    return (
      stopped_points.length > 0 &&
      stopped_points.map((data, index) => (
        <tr
          key={index}
          style={{ fontSize: "13px", cursor: "pointer" }}
          onClick={(e) => this.handleMarkerCenter(e, data)}
        >
          <td> {formatTime(data.time)}</td>
          <td> {data.status === "start" ? "D" : "P"}</td>
          <td> {secondsToFormateTime(data.stopped_time)}</td>
        </tr>
      ))
    );
  }

  handleControllCollapse() {
    let { collapsePoints } = this.state;

    this.setState({
      collapsePoints: !collapsePoints,
    });
  }

  verifyIsActiveTab() {
    this.setState({
      isActiveStops: false,
      isActiveSpeeds: false,
      isActiveOthers: false,
    });
  }

  handleShowOthersPoints() {
    this.setMarkersOthersTime();
  }

  setMarkersOthersTime() {
    let { list_equipments } = this.props;

    this.methodMarkersOthersTime(list_equipments);
  }

  methodMarkersOthersTime(data: any) {
    data.forEach((item) => {
      this.renderMarkersOthersTime(item.equipment.c);
    });
  }

  renderMarkersOthersTime(item) {
    this.returnAlertsMarkers(item);
    this.returnLastConnectionMarkers(item);
  }

  TabOthers() {
    return (
      <li
        className={`nav-item `}
        onClick={() =>
          this.setState({
            isActiveOthers: true,
            isActiveStops: false,
            isActiveSpeeds: false,
          })
        }
      >
        <a
          className="nav-link stopped-control"
          id="others-tab"
          data-toggle="tab"
          href="#others"
          role="tab"
          aria-controls="others"
          aria-selected="false"
          onClick={() => this.handleShowOthersPoints()}
        >
          {this.props.t("Outros")}
        </a>
      </li>
    );
  }

  returnLastConnectionMarkers(item) {
    let marker;
    let markers = [];
    let infowindow = new window.google.maps.InfoWindow();

    for (let i = 0; i < item.path.length; i++) {
      item.path[i].forEach((data) => {
        if (data.lost_connection === 0 || data.lost_connection === undefined) {
          return;
        }

        let lastConnectionMessage =
          data.lost_connection === 1
            ? this.props.t("Conexão perdida")
            : this.props.t("Conexão recuperada");

        marker = new window.google.maps.Marker({
          icon: alertIcon,
          position: new window.google.maps.LatLng(data.lat, data.lng),
          map: window.window.google.map,
        });
        window.google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(
                `<div>
                  <strong style="font-weight: bold;">Equipamento:</strong> ${
                    data.ident
                  } <br>
                  <strong style="font-weight: bold;">Mensagem: </strong>    ${lastConnectionMessage}<br> 
                  <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                    data.time
                  )}
                </div>`
              );
              infowindow.open(window.google.map, marker);
            };
          })(marker)
        );

        markers.push(marker);

        function clearMarkers() {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }

        window.google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );

        window.google.maps.event.addDomListener(
          document.getElementById("stops-tab"),
          "click",
          clearMarkers
        );

        window.google.maps.event.addDomListener(
          document.getElementById("clearPoints"),
          "click",
          clearMarkers
        );
        // }
      });
    }
  }

  returnAlertsMarkers(item) {
    let marker;
    let markers = [];
    let infowindow = new window.google.maps.InfoWindow();
    let alertMessage;

    for (let i = 0; i < item.path.length; i++) {
      item.path[i].forEach((data) => {
        if (data.alert === "" || data.alert === undefined) {
          return;
        }
        if (data.alert === "powerCut" || data.alert === "lowBattery") {
          alertMessage = this.props.t(
            "O Agri-S do equipamento foi desconectado da bateria"
          );
        } else {
          alertMessage = this.props.t(
            "O equipamento está em transporte ou com problema elétrico"
          );
        }

        marker = new window.google.maps.Marker({
          icon: alertIcon,
          position: new window.google.maps.LatLng(data.lat, data.lng),
          map: window.window.google.map,
        });
        window.google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(
                `<div>
                    <strong style="font-weight: bold;">Equipamento:</strong> ${
                      data.ident
                    } <br>
                    <strong style="font-weight: bold;">Mensagem: </strong>    ${alertMessage}<br> 
                    <strong style="font-weight: bold;">Horário:</strong> ${formatTime(
                      data.time
                    )}
                  </div>`
              );
              infowindow.open(window.google.map, marker);
            };
          })(marker)
        );
        markers.push(marker);
        function clearMarkers() {
          for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }
        window.google.maps.event.addDomListener(
          document.getElementById("speed-table"),
          "click",
          clearMarkers
        );
        window.google.maps.event.addDomListener(
          document.getElementById("stops-tab"),
          "click",
          clearMarkers
        );
        window.google.maps.event.addDomListener(
          document.getElementById("clearPoints"),
          "click",
          clearMarkers
        );
        // }
      });
    }
  }

  TableOthersPoints() {
    let { list_equipments } = this.props;
    return (
      <div
        className="  table-responsive"
        id="others"
        role="tabpanel"
        aria-labelledby="others-tab"
      >
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col"> {this.props.t("Equipamento")}</th>
              <th scope="col"> {this.props.t("Mensagem")}</th>
            </tr>
          </thead>
          <tbody>
            {list_equipments.map((eq) =>
              eq.equipment.c.path.map((item) =>
                this.returnRowsTableOthersPoints(item)
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }

  returnRowsTableOthersPoints(item: any) {
    return item.map((data, index) => {
      if (data.lost_connection !== 0) {
        let lastConnectionMessage =
          data.lost_connection === 1
            ? this.props.t("Conexão perdida")
            : this.props.t("Conexão recuperada");
        return (
          <>
            <tr
              key={`${index}+lost_connection`}
              style={{ fontSize: "13px", cursor: "pointer" }}
              onClick={(e) => this.handleMarkerCenter(e, data)}
            >
              <td> {data.ident}</td>
              <td> {lastConnectionMessage}</td>
            </tr>
          </>
        );
      }

      if (data.alert !== "") {
        let alertMessage =
          data.alert === "ADC0Error"
            ? this.props.t(
                "O equipamento está em transporte ou com problema elétrico"
              )
            : this.props.t(
                "O Agri-S do equipamento foi desconectado da bateria"
              );

        return (
          <>
            <tr
              key={`${index}+alert`}
              style={{ fontSize: "13px", cursor: "pointer" }}
              onClick={(e) => this.handleMarkerCenter(e, data)}
            >
              <td> {data.ident}</td>
              <td> {alertMessage}</td>
            </tr>
          </>
        );
      }
    });
  }

  render() {
    let {
      tilesloaded,
      loading,
      center,
      collapsePoints,
      mapZoom,
      isActiveStops,
      isActiveSpeeds,
      isActiveOthers,
    } = this.state;

    return (
      <div>
        {loading === false ? (
          <div className="map_style">
            <GoogleMapReact
              bootstrapURLKeys={{
                libraries: "drawing",
                key: myConfig.GOOGLE_API_KEY,
              }}
              options={this.getMapOptions}
              defaultCenter={center}
              center={center}
              zoom={mapZoom}
              yesIWantToUseGoogleMapApiInternals={true}
              onTilesLoaded={(e) => this.props.handleLoaded(e, tilesloaded)}
              onGoogleApiLoaded={this.handleGoogleMapApi.bind(this)}
            />

            <button
              className="btn btn-sm btn-ligth collapsed-button-map "
              type="button"
              id="clearPoints"
              onClick={this.handleControllCollapse.bind(this)}
            >
              {collapsePoints === true ? (
                <p onClick={() => this.verifyIsActiveTab()}>
                  {" "}
                  {this.props.t("Ocultar Pontos")}
                </p>
              ) : (
                this.props.t("Exibir Pontos")
              )}
            </button>

            <Collapse isOpen={collapsePoints}>
              <div className="col collapsed-content ">
                <div className="x_content">
                  <ul className="nav nav-tabs bar_tabs" role="tablist">
                    {this.TabSpeed()}
                    {this.TabStops()}
                    {this.TabOthers()}
                  </ul>
                  <div className="tab-content" id="">
                    {isActiveSpeeds && this.TabsSpeedContent()}
                    {isActiveStops && this.TableStoppedPoints()}
                    {isActiveOthers && this.TableOthersPoints()}
                  </div>
                </div>
              </div>
            </Collapse>
            <button id="CoordsButton" className="clear_map btn btn-default">
              {this.props.t("Limpar Área")}
            </button>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function Loading() {
  return (
    <div className="loading-spinner">
      <MDSpinner />
    </div>
  );
}
export default withTranslation()(Line_map);
