import React from "react";
import { withTranslation } from "react-i18next";
// import { FaEnvelope } from "react-icons/fa";

function SendPassword(props) {
  return (
    <div className="row">
      <div className="col-md-offset-4 col-md-8 col-xs-12">
        <div
          className="x_panel"
          style={{ borderRadius: "5px", height: "200px", width: "50%" }}
        >
          <div className="x_content" align="center">
            <h3 align="center">{props.t("Recuperar senha")}</h3>
            <p>
              {props.t(
                "Um e-mail foi enviado para você, verifique sua caixa de entrada, spam ou lixo eletrônico para as instruções de recuperação."
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(SendPassword);
