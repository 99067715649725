import React , {Component} from 'react';
import myConfig from '../../../configs/config';
import axios from 'axios';
import SelectBox from '../../../components/SelectBox';
import MDSpinner from "react-md-spinner";
import {isEmpty} from '../../../helpers/utils';
import StepControl from './StepControl';
import { withTranslation } from 'react-i18next';

class SecondOperationStep extends Component{

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            landplots : [],
            operators : [],
            machines : [],
            implements : [],
            required_fields : []
        }
    }

    loadLanplotData() {
        const LANDPLOT_URL = myConfig.API_URL+"/landplot/?farm_id="+this.props.farm_id; 
        try{
          axios({
            baseURL: LANDPLOT_URL,
            method: 'get',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
          })    
          // axios.get(LANDPLOT_URL)
              .then((res)=>{                            
                  this.setState({
                      landplots : res.data,
                      loading : false,
                  });                  
              }); 
          }catch(e){
              console.log(e);
          }        
    }

    loadImplementData(){        
        const IMPLEMENT_URL = myConfig.API_URL+"/implement/?farm_id="+this.props.farm_id; 
        try{
          axios({
            baseURL: IMPLEMENT_URL,
            method: 'get',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
          })    
          // axios.get(IMPLEMENT_URL)
              .then((res)=>{                            
                  this.setState({
                      implements : res.data,
                      loading : false,
                  });                  
              }); 
          }catch(e){
              console.log(e);
          }  
    }

    loadMachineData(){
        const MACHINE_URL = myConfig.API_URL+"/equipment/?farm_id="+this.props.farm_id; 
        try{
          axios({
            baseURL: MACHINE_URL,
            method: 'get',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
          })    
          // axios.get(MACHINE_URL)
              .then((res)=>{                            
                  this.setState({
                      machines : res.data,
                      loading : false,
                  });                  
              }); 
          }catch(e){
              console.log(e);
          }         
    }

    loadOperatorData(){
        const OPERATOR_URL = myConfig.API_URL+"/operator/?farm_id="+this.props.farm_id;               
        try{
          axios({
            baseURL: OPERATOR_URL,
            method: 'get',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('client-token')}`
            },
          })
          // axios.get(OPERATOR_URL)
          .then((res) => {
                this.setState({
                    operators:res.data 
                })
          });        
        }catch(e){
          console.log(e);
        }                
    }

    componentDidMount(){        
        if(this.props.op_type === "covering"){            
            this.loadLanplotData();
        }
        this.loadOperatorData(); 
        this.loadMachineData();       
        this.loadImplementData();        
    }    

    mountOperationInvolved(){       
        let content = [];
        // let errors = [];
        let fields = [
           {
            "field": "landplot",
            "value":"name",
            "state_name":"landplots",
            "label": this.props.t('Talhão'),
            "component_name":"landplot",
            "error": this.props.t('É necessário escolher o talhão'),
          },
          {
            "field": "operator",
            "value":"nome",
            "state_name":"operators",
            "label": this.props.t('Operador'),
            "component_name":"operator",
            "error": this.props.t('É necessário escolher um operador'),
          },
          {
            "field": "machine",
            "value":"ident",
            "state_name":"machines",
            "label": this.props.t('Máquina'),
            "component_name":"machine",
            "error": this.props.t('É necessário escolher o maquinário'),
          },                    
          {
            "field": "implement",
            "value":"ident",
            "state_name":"implements",
            "label":  this.props.t('Implemento'),
            "component_name":"implement",
            "error": this.props.t('É necessário escolher o implemento'),
          },                    
        ];      
        
        if(this.props.op_type !== "covering"){            
            //unset landplot field
            fields[0] = {};            
        }
        
        Object.keys(fields).map(function(key, index) {                        

          if(isEmpty(fields[index])){            
            return;
          }            

          let state_name = fields[index].state_name;        
          let state_data = this.state[state_name];       
          let selected_value = this.props[fields[index].component_name];
          let field = fields[index].field;
          let field_value = fields[index].value;
          let show = "true";                  
          
          const data = state_data.map(

            item => ({     
                "id": item.id,
                "ident": item[field_value],                
                "extra": field === 'implement' || field === "machine" ? {
                    "classification": item.classification,  
                    "category": item.category_name,
                    "speed": item.speed,    
                    "width":item.length,                   
                } : {}, }));
                        

          if(field === "implement"){            
            show = "false";
            if(this.props.machine_classification === "trator"){
                show = "true";                
            }
          }          

          let error_component = "";

          if(this.props.error_fields !== ""){
            this.props.error_fields.map(error => {
                if(field === error){
                    error_component = <div className="alert alert-danger">{fields[index].error}</div>
                }
            });
          }                    
                    
                  
          content.push(
            <div className="form-group col-md-12 col-xs-12">
                {error_component}
                <SelectBox strong="true" show={show} data={data} selected_value={selected_value} value={"ident"} change={this.props.handleChange} label={fields[index].label} component_name={fields[index].component_name} />
            </div>
          );              
        },this);          
              
        return content;  
      }

    render(){        
        let content = "";        

        if(this.state.loading === true){         
          content = <div style={{marginTop:"50px",marginLeft:"50%",minHeight:"200px"}}><MDSpinner /></div>;
        }else{          
          content = this.mountOperationInvolved()
        }        
        
        return(
            <div className="second-op-step-container">
                {content}           
                <StepControl handleNext={this.props.handleNext} handleBack={this.props.handleBack} lastStep={this.props.lastStep} currentStep={this.props.currentStep} />                     
            </div>
        )
    }

}

export default withTranslation()(SecondOperationStep);