import React, { Component } from "react";
import DailyDetail from "./DailyDetail";
import LineMap from "../../components/LineMap/index";
import axios from "axios";
import myConfig from "../../configs/config";
import MDSpinner from "react-md-spinner";
import Form from "../../components/Form";
import Title from "../../components/Title";
// import inteceptor from '../../configs/interceptor';
import "./Machine.css";
import MultipleSelect from "../../components/MultipleSelect";
import Button from "../../components/Button";
import { withTranslation } from "react-i18next";
import AgriDateTime from "../../components/AgriDateTime";
import { getTime } from "../../helpers/utils";
import Doc from "../../components/DocService";
import PdfContainer from "../../components/PdfContainer";
import { ToastContainer, toast } from "react-toastify";
import ExportButton from "../../components/Export_button";

class PathReport extends Component {
  farmId = this.props.match.params.farm_id;

  search = this.props.location.search;
  machineId = new URLSearchParams(this.search).get("machine_id");
  ini_period = new URLSearchParams(this.search).get("dt_ini");
  end_period = new URLSearchParams(this.search).get("dt_end");

  constructor(props) {
    super(props);
    this.state = {
      machine: [],
      loading: true,
      initial_date: new Date(),
      end_date: new Date(),
      machines_selected: [],
      landplots_selected: [],
      landplots: [],
      content_data: "",
      is_searched: false,
      map_path: "",
      line_color: "",
      mapLoaded: false,
      dt_init: "",
      dt_end: "",
      outLandplot: true,
      inLandplot: true,
    };
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleInitDateChange = this.handleInitDateChange.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRenderReport = this.handleRenderReport.bind(this);
    this.handleMapLoaded = this.handleMapLoaded.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleTimeEndChange = this.handleTimeEndChange.bind(this);
  }

  componentDidMount() {
    document.title = "Agriconnected - Relatórios";

    this.loadEquipmentData();
    this.getLandplots();

    if (
      this.machineId !== null &&
      this.ini_period !== null &&
      this.end_period !== null
    ) {
      console.log("end_period", this.end_period);
      this.weekSummary();
    }
  }

  async loadEquipmentData() {
    try {
      const URL = `${myConfig.API_URL}/equipment/?farm_id=${this.farmId}`;

      let response = await axios({
        baseURL: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      this.setState({
        machine: response.data,
      });
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar equipamentos, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async getLandplots() {
    try {
      let LANDPLOTS_URL = `${myConfig.API_URL}/landplot/?farm_id=${this.farmId}`;

      let response = await axios({
        baseURL: LANDPLOTS_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        this.setState({ landplots: data });
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar talhões, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  weekSummary() {
    this.state.machines_selected.push(parseInt(this.machineId));

    this.setState({
      initial_date: new Date(this.ini_period),
      end_date: new Date(this.end_period),
    });

    this.handleRenderReport("weekSummary");
  }

  handleInitDateChange(e) {
    this.setState({
      initial_date: e,
    });
  }

  handleTimeChange(e) {
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let time_changed = new Date(
      this.state.initial_date.toLocaleDateString("en-US", options) +
        " " +
        e.target.value
    );
    this.setState({ initial_date: time_changed });
  }

  handleTimeEndChange(e) {
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let time_changed = new Date(
      this.state.end_date.toLocaleDateString("en-US", options) +
        " " +
        e.target.value
    );
    this.setState({ end_date: time_changed });
  }

  handleMapLoaded(e, colors) {
    // e.preventDefault()
    if (this.state.mapLoaded === false) {
      this.setState({
        line_color: colors,
        mapLoaded: true,
      });
    }
  }

  handleEndDateChange(e) {
    this.setState({
      end_date: e,
    });
  }

  handleMultipleSelect(e, state_name) {
    this.setState({
      [state_name]: e.target.value,
    });
  }

  handleCheckBoxesChanged(e, state_name) {
    this.setState({ [state_name]: e.target.checked });
  }

  getFormatedDate(dt) {
    let data = new Date(dt),
      dia = dt.getDate().toString(),
      diaF = dia.length === 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(),
      mesF = mes.length === 1 ? "0" + mes : mes,
      anoF = data.getFullYear(),
      hour = ("0" + data.getHours()).slice(-2).toString(),
      minutes = ("0" + data.getMinutes()).slice(-2).toString(),
      seconds = ("0" + data.getSeconds()).slice(-2).toString();

    return (
      anoF +
      "-" +
      mesF +
      "-" +
      diaF +
      "T" +
      hour +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }

  async handleRenderReport(e) {
    let {
      initial_date,
      end_date,
      machines_selected,
      landplots_selected,
      outLandplot,
      inLandplot,
    } = this.state;

    this.setState({
      loading: true,
      is_searched: true,
      mapLoaded: false,
      dt_init: this.getFormatedDate(initial_date),
      dt_end: this.getFormatedDate(end_date),
    });

    let dt_init, dt_end;

    if (e === "weekSummary") {
      dt_init = this.getFormatedDate(new Date(this.ini_period));
      dt_end = this.getFormatedDate(new Date(this.end_period));
    } else {
      dt_init = this.getFormatedDate(initial_date);
      dt_end = this.getFormatedDate(end_date);
    }

    if (machines_selected.length === 0) {
      this.setState({
        loading: false,
        is_searched: false,
        mapLoaded: true,
      });
      let error_msg =
        "Selecione ao menos uma máquina para continuar com a solicitação";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const URL = `${myConfig.API_URL}/equipment/path_report/?farm_id=${this.farmId}&eq=${machines_selected}&dt_ini=${dt_init}&dt_end=${dt_end}&ldps=${landplots_selected}&in_ldp=${inLandplot}&out_ldp=${outLandplot}`;

      let response = await axios({
        baseURL: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      // console.log("response.data", response.data);
      this.setState({
        content_data: response.data,
        loading: false,
      });
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar relatório, recarregue a página e tente novamente";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 30000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  getHeader() {
    // console.log('>> ', typeof (this.state.initial_date));

    return (
      <div>
        <div className="container">
          <Title size="h1" farm_name={this.props.t("Relatório Personalizado")}>
            <Form className="form-report">
              <MultipleSelect
                selected={this.state.machines_selected}
                handle={(e) =>
                  this.handleMultipleSelect(e, "machines_selected")
                }
                item-key="id"
                item-value="ident"
                items={this.state.machine}
                title={`Selecione os equipamentos`}
              />
              <MultipleSelect
                selected={this.state.landplots_selected}
                handle={(e) =>
                  this.handleMultipleSelect(e, "landplots_selected")
                }
                item-key="id"
                item-value="name"
                isLandplot={true}
                items={this.state.landplots}
                title={`Selecione os talhões`}
              />
              <div className="col-xs-12 col-md-2">
                <div className="">
                  <input
                    type="checkbox"
                    name="out_landplot"
                    onChange={(e) =>
                      this.handleCheckBoxesChanged(e, "outLandplot")
                    }
                    className="check"
                    id="out_landplot"
                    defaultChecked={this.state.outLandplot}
                    style={{ marginRight: "7px" }}
                  />
                  <label className="form-check-label" htmlFor="out_landplot">
                    Fora do talhão
                  </label>
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    name="in_landplot"
                    onChange={(e) =>
                      this.handleCheckBoxesChanged(e, "inLandplot")
                    }
                    className="check"
                    id="in_landplot"
                    defaultChecked={this.state.inLandplot}
                    style={{ marginRight: "7px" }}
                  />
                  <label className="form-check-label" htmlFor="in_landplot">
                    Dentro do talhão
                  </label>
                </div>
              </div>
              <div className="form-group text-left col-xs-12 col-md-2">
                <AgriDateTime
                  time={getTime(this.state.initial_date)}
                  label={this.props.t("Data inicial")}
                  datetimeformat="true"
                  selectedDate={this.state.initial_date}
                  handleDateChange={this.handleInitDateChange}
                  handleTimeChange={this.handleTimeChange}
                />
                <br />
                {/* <DateTime label={this.props.t('Data inicial')} datetimeformat="true" date={this.state.initial_date} handleDateChange={this.handleInitDateChange} />                            */}
              </div>
              <div className="form-group text-left col-xs-12 col-md-2">
                <AgriDateTime
                  time={getTime(this.state.end_date)}
                  label={this.props.t("Data final")}
                  datetimeformat="true"
                  selectedDate={this.state.end_date}
                  handleDateChange={this.handleEndDateChange}
                  handleTimeChange={this.handleTimeEndChange}
                />
                <br />
                {/* <DateTime label={this.props.t('Data final')} datetimeformat="true" date={this.state.end_date} handleDateChange={this.handleEndDateChange} />                            */}
              </div>
              <div className="text-center">
                <Button
                  click={this.handleRenderReport}
                  class="btn-primary mt-25 ml-20"
                >
                  {this.props.t("Gerar Relatório")}
                </Button>
              </div>
            </Form>
          </Title>
        </div>
      </div>
    );
  }

  getMap() {
    if (this.state.loading === true) {
      return (
        <div
          style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}
        >
          <MDSpinner />
        </div>
      );
    } else {
      let { content_data } = this.state;

      let thepositon = { lat: 0, lng: 0 };

      if (content_data[0].c.path.length > 1) {
        let content_path = content_data[0].c.path;

        let lasts_indices_of_content_path =
          content_path[content_path.length - 1];

        let last_indice =
          lasts_indices_of_content_path[
            lasts_indices_of_content_path.length - 1
          ];

        thepositon = { lat: last_indice.lat, lng: last_indice.lng };
      } else {
        thepositon = content_data[0].last_point[0];
      }

      let map_attr = content_data.map(function (item) {
        return { equipment: item };
      });

      return (
        <div>
          <div className="map-container">
            <LineMap
              searched={this.state.is_searched}
              farm_id={this.farmId}
              multiple="true"
              onChange={this.handleMapLoaded}
              handleLoaded={this.handleMapLoaded}
              list_equipments={map_attr}
              last_point={thepositon}
              landplots={this.state.landplots}
            />
          </div>
        </div>
      );
    }
  }

  getContent() {
    if (this.state.loading === true) {
      return "";
    } else {
      if (this.state.line_color) {
        let color = this.state.line_color;

        let machineDetail = this.state.content_data.map(function (item) {
          return (
            <DailyDetail
              key={item.ident}
              title="true"
              machine={item}
              colors={color}
            />
          );
        });

        return machineDetail;
      }
    }
  }

  get_io_report_url() {
    let initial_date = this.state.initial_date.toISOString().substr(0, 19);
    let end_date = this.state.end_date.toISOString().substr(0, 19);
    // window.localStorage['initial_date'] = initial_date
    // window.localStorage['end_date'] = end_date
    return `${myConfig.API_URL}/equipment/ioreport/?farm_id=${this.farmId}&eq=${this.state.machines_selected}&dt_ini=${initial_date}&dt_end=${end_date}`;
  }

  get_work_report_url() {
    let {
      initial_date,
      end_date,
      machines_selected,
      landplots_selected,
      outLandplot,
      inLandplot,
    } = this.state;
    let dt_init = this.getFormatedDate(initial_date);
    let dt_end = this.getFormatedDate(end_date);
    return `${myConfig.API_URL}/equipment/workreport/?farm_id=${this.farmId}&eq=${machines_selected}&dt_ini=${dt_init}&dt_end=${dt_end}&ldps=${landplots_selected}&in_ldp=${inLandplot}&out_ldp=${outLandplot}`;
  }

  createPdf = (html) => Doc.createPdf(html);

  render() {
    let header = this.getHeader(),
      map = "",
      content = "",
      disabled = true;

    if (this.state.is_searched === true) {
      map = this.getMap();
      content = this.getContent();
      disabled = false;
    }

    return (
      <div className="path-report-box">
        {header}
        <PdfContainer createPdf={this.createPdf} disabled={disabled}>
          <h2 className="hidden_title">
            {this.props.t("Relatório Personalizado")}
          </h2>
          <div className="clearfix"></div>
          <ExportButton
            classname="btn btn-default-export btn-export-extra"
            title="E/S Talhões .xls"
            export={this.get_work_report_url()}
          />
          {map}
          {content}
        </PdfContainer>
        <ToastContainer />
      </div>
    );
  }
}
export default withTranslation()(PathReport);
