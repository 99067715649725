import React from "react";
import { FaEllipsisV } from "react-icons/fa";

import "./index.css";

function FloatingDropdown(props) {
  return (
    <div className="dropdown floating-dropdown">
      <button
        className="btn btn-floating-dropdown dropdown-toggle"
        type="button"
        data-toggle="dropdown"
      >
        <FaEllipsisV />
      </button>
      <ul className="dropdown-menu dropdown-floating-list">{props.children}</ul>
    </div>
  );
}
export default FloatingDropdown;
