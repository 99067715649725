import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class ResetDone extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-offset-4 col-md-4 col-xs-12">
            <div className="x_panel" style={{ borderRadius: "5px" }}>
              <div className="x_content" align="center">
                <h1>{this.props.t('Redefinição de senha completa')}</h1>

                <p>
                  {this.props.t('Sua senha foi definida. Você pode prosseguir e se autenticar agora.')}
                </p>

                <p>
                  <Link to="/account/login/" class="btn btn-primary">
                  {this.props.t('Acessar')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetDone);