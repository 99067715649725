import React , {Component} from 'react';
import Input from '../../../components/Input';
import StepControl from './StepControl';
import { withTranslation } from 'react-i18next';

class FourthOperationStep extends Component{

    constructor(props){
        super(props);
        this.state = {
            initial_ident : ""
        }                
    }

    render(){
        let landplot = "";
        let ident = "";
        let error_component = "";

        if(this.props.op_type !== 0){
            landplot = "";
        }else{
            landplot =  <div className="form-group">
                            <strong>{this.props.t('Talhão')}  :</strong><span>{this.props.landplot_label}</span><br></br>
                        </div>;
        }
        
        if(this.props.ident === ""){
            ident = this.state.initial_ident;
        }else{
            ident = this.props.ident;
        }

        if(this.props.error_save !== ""){            
            error_component = this.props.error_save            
        }   

        return(        
            <div className="col-xs-12 col-md-12 col-lg-12">               
                <div>
                    {this.props.error_save}
                </div>
                <div className="form-group">
                    <strong>{this.props.t('Tipo de Operação')}  :</strong><span>{this.props.op_type_label}</span>                
                </div>
                <div className="form-group">
                    <Input handleChange={this.props.handleChangeText} strong="true" value={this.props.ident} type="text" class="form-control" label={this.props.t('Identificação da operação')} name="ident" />                
                </div>
                <div className="form-group">
                    <Input handleChange={this.props.handleChangeText} strong="true" value={this.props.width} type="number" class="form-control" label={this.props.t('Largura de Trabaho')} name="width" />                
                </div>
                <div className="form-group">
                    <Input handleChange={this.props.handleChangeText} strong="true" value={this.props.speed.replace(" km/h","")} type="number" class="form-control" label={this.props.t('Velocidade Adequada da Operação (km/h)')} name="speed" />                
                </div>
                {landplot}
                <div className="form-group">
                    <strong> {this.props.t('Operador')} :</strong><span>{this.props.operator_label}</span><br></br>                
                </div>
                <div className="form-group">
                    <strong>{this.props.t('Máquina')} :</strong><span>{this.props.machine_label}</span><br></br>
                </div>        
                <StepControl handleSave={this.props.handleSave} handleNext={this.props.handleNext} handleBack={this.props.handleBack} lastStep={this.props.lastStep} currentStep={this.props.currentStep} />                                                             
            </div>
        );
    }

}

export default withTranslation()(FourthOperationStep);