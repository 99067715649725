import { FETCH_FARM_SUCCESS } from "../actions/types";

const INITAL_STATE = {
  id: localStorage.getItem("farm"),
  nome_da_fazenda: localStorage.getItem("farm_name"),
};

export default function current_farm(state = INITAL_STATE, action) {
  switch (action.type) {
    case FETCH_FARM_SUCCESS:
      return action.farm;
    default:
      return state;
  }
}
