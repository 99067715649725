import React from 'react';
import classnames from 'classnames';

function Button(props){      

        return(             
            <a href={props.href} className={classnames("btn",props.class)} onClick={props.click}>                                
                {props.children}                
            </a>             
        );    
}

export default Button;