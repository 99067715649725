import React, { useEffect, useState } from "react";
import MDSpinner from "react-md-spinner";
import GoogleMapReact from "google-map-react";
import myConfig from "../../configs/config";

import "./Landplot.css";

function MapLandplot({ farm_center }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (farm_center !== undefined) {
      setLoading(false);
    }
  }, []);

  function setPolygon(google) {
    let Polygon = new google.maps.Polygon({
      paths: window.path,
      editable: true,
      fillColor: "#000",
      fillOpacity: 0.5,
      strokeColor: "#000",
      strokeOpacity: 1,
      strokeWeight: 1,
    });

    Polygon.setMap(google.map);

    google.maps.event.addListener(Polygon, "mouseout", function () {
      // console.log("mouseout");
      let path = Polygon.getPath()
        .getArray()
        .map((latLng) => {
          return { lng: latLng.lng(), lat: latLng.lat() };
        });

      window.path = path;
    });

    function clearPolygon() {
      Polygon.setMap(null);
    }
    google.maps.event.addDomListener(
      document.getElementById("CoordsButton"),
      "click",
      clearPolygon
    );
  }

  function handleGoogleMapApi(google) {
    setPolygon(google);

    // FERRAMENTAS DE DESENHO DO POLIGONO
    let drawingManager = new google.maps.drawing.DrawingManager({
      drawingControlOptions: {
        drawingModes: ["polygon"],
        position: window.google.maps.ControlPosition.TOP_CENTER,
      },
      drawingControl: true,

      polygonOptions: {
        editable: true,
        fillColor: "#000",
        fillOpacity: 0.5,
        strokeColor: "#000",
        strokeOpacity: 1,
        strokeWeight: 1,
      },
    });

    drawingManager.setMap(google.map);

    // FUNÇÃO PARA TRABALHAR  OS DADOS DO POLIGONO
    google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      function (polygon) {
        let path = polygon
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lng: latLng.lng(), lat: latLng.lat() };
          });

        window.path = path;

        drawingManager.setMap(null); //SETAR AS OPÇÕES DE DESENHO

        function clearPolygon() {
          polygon.setMap(null);
          drawingManager.setMap(google.map);
        }
        google.maps.event.addDomListener(
          document.getElementById("CoordsButton"),
          "click",
          clearPolygon
        );
      }
    );
  } //FIM HANDLE

  function getMapOptions(maps) {
    return {
      streetViewControl: true,
      scaleControl: true,
      fullscreenControl: true,
      gestureHandling: "greedy",
      disableDoubleClickZoom: false,
      minZoom: 2,
      mapTypeId: maps.MapTypeId.SATELLITE,
      zoomControl: true,
      clickableIcons: false,
    };
  }

  return (
    <>
      {loading === false ? (
        <div className="map_style">
          <GoogleMapReact
            bootstrapURLKeys={{
              libraries: "drawing",
              key: myConfig.GOOGLE_API_KEY,
            }}
            options={getMapOptions}
            center={{ lat: farm_center.ltd, lng: farm_center.lng }}
            defaultZoom={16}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={handleGoogleMapApi}
          />

          <input
            defaultValue="Limpar Área"
            id="CoordsButton"
            className="clear_map btn btn-default"
          />
        </div>
      ) : (
        <div
          style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}
        >
          <MDSpinner />
        </div>
      )}
    </>
  );
}

export default MapLandplot;
