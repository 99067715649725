import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import SelectBox from "../../components/SelectBox";
import myConfig from "../../configs/config";
import { withTranslation } from "react-i18next";
import { Link, withRouter, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function FormMachine(props) {
  const farmId = props.match.params.farm_id;
  const machineId =
    props.match.params.machine_id === "add"
      ? -1
      : props.match.params.machine_id;

  let hasMachine = machineId !== -1;

  let history = useHistory();

  const [ident, setIdent] = useState("");
  const [manufacture_year, setManufactureYear] = useState("");
  const [device_uid, setDeviceUid] = useState("");
  const [capacity, setCapacity] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [classification, setClassification] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [manufacturies, setManufacturies] = useState([]);
  const [manufacturer, setManufacturer] = useState("");
  const [length, setLength] = useState("");
  const [title, setTitle] = useState("Cadastrar máquina");

  async function loadCategories() {
    let CATEGORY_URL = `${myConfig.API_URL}/equipment_category/?classification!=implemento`;

    let response = await axios({
      baseURL: CATEGORY_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;
    console.log('**',data)
    if (data) {
      setCategories(data);
    }
  }

  async function loadManufacturies() {
    let MANUFACTURER_URL = `${myConfig.API_URL}/manufacturer/`;

    let response = await axios({
      baseURL: MANUFACTURER_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setManufacturies(data);
  }

  async function loadSubCategories() {
    if (categories.length > 0) {
      let SUBCATEGORY_URL = `${myConfig.API_URL}/equipment_subcategory/?category=${category}`;
      let response = await axios({
        baseURL: SUBCATEGORY_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      // console.log("*****************");
      // console.log(response.data);
      // console.log("*****************");
      let data = response.data;

      setSubcategories(data);
    }
  }

  async function getCurrentMachine() {
    let MACHINE_URL = `${myConfig.API_URL}/equipment/${machineId}/`;

    let response = await axios({
      baseURL: MACHINE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;


console.log(data)
    setTitle("Editar máquina");
    setIdent(data.ident);
    setManufactureYear(data.manufacture_year);
    setDeviceUid(data.device_uid);
    setCapacity(data.capacity);
    setCategory(data.category);
    // setClassification(data.classification);
    setSubcategory(data.sub_category);
    setManufacturer(data.manufacturer);
    setLength(data.length);
  }

  async function createMachine() {
    let CREATE_MACHINE = `${myConfig.API_URL}/equipment/`;

    try {
      let response = await axios({
        baseURL: CREATE_MACHINE,
        method: "post",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });
      let status = response.status;

      if (status === 201) {
        // SHOW TOAST
        toast.success(
          ` ${props.t("Máquina")}  ${props.t("cadastrada com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/equipment/machine/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " - ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // SHOW TOAST
    }
  }

  async function updateMachine() {
    let UPDATE_MACHINE = `${myConfig.API_URL}/equipment/${machineId}/`;

    try {
      let response = await axios({
        baseURL: UPDATE_MACHINE,
        method: "put",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 200) {
        // SHOW TOAST SUCCESSS
        toast.success(
          ` ${props.t("Máquina")}  ${props.t("atualizada com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/equipment/machine/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";

      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " - ";
      });
      // SHOW TOAST
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function handleChangeCategory(e) {
    e.preventDefault();

    setCategory(e.target.value);

    let SUBCATEGORY_URL = `${myConfig.API_URL}/equipment_subcategory/?category=${e.target.value}`;

    let response = await axios({
      baseURL: SUBCATEGORY_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setSubcategories(data);
  }

  function getFormData() {
    let form_data = new FormData();

    form_data.append("ident", ident);
    form_data.append("farm", farmId);
    form_data.append("capacity", capacity);
    if (manufacture_year !== null) {
      form_data.append("manufacture_year", manufacture_year);
    }
    form_data.append("device_uid", device_uid);
    form_data.append("category", category);
    form_data.append("sub_category", subcategory);
    form_data.append("manufacturer", manufacturer);
    if (length) {
      form_data.append("length", length.replace(",", "."));
    }

    return form_data;
  }

  function handleSave(e) {
    e.preventDefault();
    if (hasMachine) {
      // PUT
      updateMachine();
    } else {
      // POST
      createMachine();
    }
  }

  useEffect(() => {
    loadCategories();

    loadManufacturies();

    if (hasMachine) {
      getCurrentMachine();
    }
  }, []);

  useEffect(() => {
    if (hasMachine) loadSubCategories();
  }, [categories]);

  
  return (
    <>
      <div id="machine_form_container">
        <h2>{props.t(title)}</h2>
        <form method="POST" onSubmit={handleSave}>
          <div className="form-group">
            <label htmlFor="id_ident">{props.t("Identificação")}</label>
            <input
              type="text"
              value={ident}
              onChange={(e) => setIdent(e.target.value)}
              name="ident"
              maxLength={35}
              className="form-control"
            />
          </div>
          {categories.length > 0 ? (
            <SelectBox
              strong="true"
              data={categories}
              selected_value={category}
              value={"name"}
              change={handleChangeCategory}
              label="Categoria"
              id="id_category"
            />
          ) : (
            <div>
              <label htmlFor="">Categoria</label>
              <select name="" className="form-control">
                <option value="">-------------</option>
              </select>
            </div>
          )}
          {subcategories.length > 0 ? (
            <SelectBox
              strong="true"
              data={subcategories}
              selected_value={subcategory}
              value={"desc"}
              change={(e) => setSubcategory(e.target.value)}
              label="Sub Categoria"
              id="id_subcategory"
            />
          ) : (
            <div>
              <label htmlFor="">Sub Categoria</label>
              <select name="" className="form-control">
                <option value="">-------------</option>
              </select>
            </div>
          )}

          {manufacturies.length > 0 ? (
            <SelectBox
              strong="true"
              data={manufacturies}
              selected_value={manufacturer}
              value={"name"}
              change={(e) => setManufacturer(e.target.value)}
              label="Fabricantes"
              id="id_manufacturer"
            />
          ) : (
            <div>
              <label htmlFor="">Fabricantes</label>
              <select name="" className="form-control">
                <option value="">-------------</option>
              </select>
            </div>
          )}
          {parseInt(category) !== 1 ? (
            <div className="form-group">
              <label htmlFor="id_length">Largura (metros)</label>
              <input
                type="text"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                name="length"
                maxLength={6}
                className="form-control"
                placeholder="Ex: 22.00"
              />
            </div>
          ) : (
            <></>
          )}
          <div className="form-group">
            <label htmlFor="id_">
              {props.t("Capacidade do tanque (Litros)")}
            </label>
            <input
              type="text"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
              name="capacity"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="id_">{props.t("ID do dispositivo")}</label>
            <input
              type="text"
              value={device_uid}
              onChange={(e) => setDeviceUid(e.target.value)}
              name="device_uid"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="id_">{props.t("Ano de Fabricação")}</label>
            <input
              type="text"
              value={manufacture_year}
              onChange={(e) => setManufactureYear(e.target.value)}
              name="manufacture_year"
              maxLength={4}
              className="form-control"
            />
          </div>
          <div align="center">
            <input
              type="submit"
              className="btn btn-primary"
              value={props.t("Salvar")}
            />
            <Link
              to={`/${farmId}/equipment/machine/`}
              className="btn btn-default"
            >
              {props.t("Cancelar")}
            </Link>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
}

export default withTranslation()(withRouter(FormMachine));
