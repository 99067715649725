import React, { useState, useEffect } from "react";
import axios from "axios";
import myConfig from "../../configs/config";

import MapLandplot from "./MapLandplot";

import { withTranslation } from "react-i18next";
import { Link, withRouter, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

function FormLandplot(props) {
  const [farm_center, setFarmCenter] = useState([]);
  const [landplotName, setLandplotName] = useState("");

  let farmId = props.match.params.farm_id;
  let landplotId =
    props.match.params.landplot_id === undefined
      ? -1
      : props.match.params.landplot_id;

  const history = useHistory();

  let hasLandplot = landplotId !== -1;

  useEffect(() => {
    document.title = "Agriconnected - Talhões";

    if (hasLandplot) {
      //PUT
      getDataofcurrentLandplot();
    } else {
      //POST
      getCenterofCurrentFarm();
    }
  }, []);

  async function getCenterofCurrentFarm() {
    let CENTER_URL = `${myConfig.API_URL}/farm/${farmId}/`;
    window.path = null;

    await axios({
      baseURL: CENTER_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    }).then((res) => {
      // console.log("********************");
      // console.log(res.data);
      // console.log("********************");
      setFarmCenter(res.data.farm_center);
    });
  }

  async function getDataofcurrentLandplot() {
    let LANDPLOT_URL = `${myConfig.API_URL}/landplot/${landplotId}/`;

    await axios({
      baseURL: LANDPLOT_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    }).then((res) => {
      // console.log('********************')
      // console.log(res.data)
      // console.log('********************')
      let coords = [];
      let geometry = JSON.parse(res.data.path);
      coords = geometry.coordinates[0].map(function (item) {
        return { lat: item[1], lng: item[0] };
      });
      window.path = coords;
      setLandplotName(res.data.name);
      setFarmCenter(res.data.center);
    });
  }

  function getFormData() {
    let area = window.path;

    area.push(area[0]);
    area = area.map((i) => {
      return "" + i["lng"] + " " + i["lat"];
    });

    let form_data = new FormData();
    form_data.append("name", landplotName);
    form_data.append("area", "SRID=4326;POLYGON ((" + area + "))");
    form_data.append("farm", farmId);

    return form_data;
  }

  function handleSave(e) {
    e.preventDefault();

    if (hasLandplot) {
      handleEditLandplot();
    } else {
      handleCreateLandplot();
    }
  }

  async function handleEditLandplot() {
    let LANDPLOT_UPDATE = `${myConfig.API_URL}/landplot/${landplotId}/`;

    let response = await axios({
      baseURL: LANDPLOT_UPDATE,
      method: "put",
      data: getFormData(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    if (response.status === 200) {
      toast.success(
        ` ${props.t("Talhão")}  ${props.t("atualizado com sucesso")}!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTimeout(() => {
        history.push(`/${farmId}/landplots/`);
      }, 2000);
    } else {
      // console.log(response.data);
      toast.error(
        `${this.props.t(
          "O formulário não pode ser submetido, verifique se todos os campos estão preenchidos"
        )}.`,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  async function handleCreateLandplot() {
    let CREATE_LANDPLOT = `${myConfig.API_URL}/landplot/?farm_id=${farmId}`;

    let response = await axios({
      baseURL: CREATE_LANDPLOT,
      method: "post",
      data: getFormData(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });
    if (response.status === 201) {
      toast.success(
        `${props.t("Talhão")}  ${props.t("cadastrado com sucesso !")}`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTimeout(() => {
        history.push(`/${farmId}/landplots/`);
      }, 2000);
    } else {
      // console.log(response.data);
      toast.error(
        `${props.t(
          "O formulário não pode ser submetido, verifique se todos os campos estão preenchidos"
        )}.`,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  return (
    <div id="landplot_form_container">
      {landplotId !== -1 ? (
        <h2> {props.t("Editar Talhão")} </h2>
      ) : (
        <h2> {props.t("Cadastrar Talhão")}</h2>
      )}

      <form method="POST" onSubmit={handleSave} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="id_landplot">{props.t("Nome do Talhão")}</label>
          <input
            type="text"
            onChange={(e) => {
              setLandplotName(e.target.value);
            }}
            name="name"
            value={landplotName}
            maxLength={50}
            className="form-control"
            placeholder={props.t("Nome do Talhão")}
            required
          />
        </div>

        <MapLandplot farm_center={farm_center} />

        <br />

        <div align="center">
          <input
            type="submit"
            className="btn btn-primary"
            value={props.t("Salvar")}
          />
          <Link to={`/${farmId}/landplots/`} className="btn btn-default">
            {props.t("Cancelar")}
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default withTranslation()(withRouter(FormLandplot));
