import React, { Component } from 'react';
import { Map, Marker, Polygon, Polyline, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import myConfig from '../../configs/config';

import './Marker.css';

export class MapContainer extends Component {

  constructor(props) {    
    super(props)  
    this.ref = null;  
    this.state = {
      centroidLat: "",
      centroidLng: "",
      //- Azul - Vermelho - Amarelo - Laranja - Roxo - Magenta - Cyano - Ocre - Bordo - Petróleo - Azul naval - Quentão - Rosa           
      colors: ["#031AA5", "#DB0C03", "#F5B002", "#F36924", "#69029B", "#F100F7", "#23A1C6", "#857012", "#740224", "#0F565C", "#040244", "#AE4E46", "#DF1B5F"],
      activeMarker: {},
      selectedPlace: {},
      showPolygonInfoWindow : {},
      showPolygonInfo: false,
      zoom : false
    }    
    this.map = "";
    this.onMarkerClick = this.onMarkerClick.bind(this);    
  }
  
  onMarkerClick(props, marker, e) {    
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      showPolygonInfo : false        
    });
  }  
  
  onPolygonClick = (props,landplot,e) => {       
    //clica no elemento marker    
    this.setState({ showPolygonInfoWindow:{      
      render_marker : true,
      landplot : props.landplot,
      position: props.landplot.landplot_center,      
      markers: props.marker
    } });

    if(this.ref != null){         
        this.ref.props.onClick(this.ref.props,this.ref.marker)        
    }
    
  }  

  handleShowPolygonInfo = (props, marker, e) => {    
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: false,
      showPolygonInfo:true
    });
  }
  

  getRandomColor(i) {
    let color = this.state.colors[i];

    if (i >= this.state.colors.length) {
      color = this.state.colors[Math.round(Math.random(0, this.state.colors.length))];
    }

    return color;
  }


  render_markers() {
    let obj = this.props.equipments;
    let markers = [];

    for (let equip of Object.values(obj)) {
      let icon_url = "";
      icon_url += (equip.machine_status === "offline") ? require(`../../assets/traccar/offline/${equip.traccar_ico}.svg`)  : require(`../../assets/traccar/online/${equip.traccar_ico}.svg`);
     
      let coords = equip.last_posi_point[equip.last_posi_point.length - 1]
      
      markers.push(<Marker
        key={equip.id}
        icon={icon_url}
        title={equip.ident}
        speed={equip.speed}
        last_connection={equip.last_connection}
        operation={equip.operation}
        label={{ text: equip.ident, color: 'white', fontWeight: 'bold' }}
        extra_inf={equip.extra_inf}
        opacity={1}
        onClick={this.onMarkerClick}
        position={coords}>
      </Marker>);
    }

    return markers;
  }

  render_polygon_makers(){    
    if(this.state.showPolygonInfoWindow.render_marker === true){      
      return <Marker        
                    opacity={0}                                                
                    title={this.state.showPolygonInfoWindow.landplot.name}                   
                    ref={el=>this.ref = el}
                    onClick={this.handleShowPolygonInfo}
                    label={{ text: this.state.showPolygonInfoWindow.landplot.name, color: 'white', fontWeight: 'bold' }}
                    position={this.state.showPolygonInfoWindow.landplot.landplot_center}/>                                                                                                        
    }
  }

  render_info() {        
    if(this.state.showPolygonInfo === true){          
      return <InfoWindow marker={this.state.activeMarker} visible={true} onCloseclick={(e) => { this.setState({ showPolygonInfo: false }) }}>
          <div>
            <ul>
              <h5><b>Nome:</b> {this.state.showPolygonInfoWindow.landplot.name}</h5>
              <h5><b>Area:</b> {this.state.showPolygonInfoWindow.landplot.area_h2} ha</h5>
            </ul>
          </div>
      </InfoWindow>;         
    }else{            
      let extra_inf = "";
      
      let operation = this.state.activeMarker.operation !== "" ?
      <h5><b>Operação:</b> {this.state.selectedPlace.operation}</h5> : null

      if (this.state.selectedPlace.extra_inf !== undefined) {
        this.state.selectedPlace.extra_inf.map((info) => {
          extra_inf = <h5><b>{info.label}:</b> {info.value}</h5>
        });
      }

      return <InfoWindow
        marker={this.state.activeMarker}
        visible={true}>
        <div>
          <ul>
            <h5>{this.state.selectedPlace.title}</h5>
            <h5><b>Velocidade:</b> {this.state.selectedPlace.speed}</h5>
            <h5><b>Última conexão:</b> {this.state.selectedPlace.last_connection}</h5>
            {operation}
            {extra_inf}
          </ul>
        </div>
      </InfoWindow>
    }
  }

  render_lines() {
    let i = 0;
    let colors = [];
    let lines = [];
    for (let target of Object.values(this.props.equipments)) {
      let coords = "";

      colors.push({ "equipment": target.ident, "color": this.getRandomColor(i) });

      if (Array.isArray(target.last_posi_point)) {
        coords = target.last_posi_point.map(item => {
          return { lat: item.lat, lng: item.lng };
        });
      } else {
        coords = target.last_posi_point
      }

      lines.push(<Polyline
        key={target.id}
        path={coords}
        strokeColor={colors[i].color}
        strokeWeight={4} />)
    }
    i++;
    return lines;
  }

  render_polygon() {
    if (this.props.landplot !== "") {
      return this.props.landplot.map(landplot => {
        let geometry = JSON.parse(landplot.geomap);
        let coords = geometry.coordinates[0].map(function (item) {
          return { lat: item[1], lng: item[0] };
        });

        return (
          <Polygon
            key={landplot.id}
            paths={coords}
            strokeColor="#00709E"
            strokeWeight={2}
            fillColor="#00709E"        
            landplot={landplot}                     
            onClick={this.onPolygonClick}                                                             
            />                                       
        )
      });
    }
  }

  get_boundary() {    
    if(this.props.marker_center.length < 0){
      return;
    }
    const GLOBE_WIDTH = 256;
    if (this.props.farm.centroid && this.props.farm.centroid.angle > 0) {
      let zoom = (Math.round(Math.log(window.innerWidth * 360 / this.props.farm.centroid.angle / GLOBE_WIDTH) / Math.LN2) - 2); 
      return zoom > 16 ? 16 : zoom 
    } 
    else {
      return 14
    }
  }
  
  render_map() {
    let polygons = this.render_polygon();
    let polygon_marker = this.render_polygon_makers();    
    let markers = this.render_markers();
    let zoom = (this.props.mapZoom !== 0)?this.props.mapZoom:this.get_boundary();
    let lines = this.render_lines();
    let info = this.render_info();    
    let lat = this.props.farm.centroid.lat !== 0 ? this.props.farm.centroid.lat : this.props.farm.latitude;
    let lng = this.props.farm.centroid.lng !== 0 ? this.props.farm.centroid.lng : this.props.farm.longitude;
    let markers_center = this.props.marker_center;
    let bounds = new this.props.google.maps.LatLngBounds();    
        
    if(markers_center.length > 0 ){                  
      if(this.map !== ""){                        
        this.map.map.fitBounds(bounds.extend(markers_center[markers_center.length - 1]));
        this.map.map.setZoom(zoom);
      }
    }    

    return (      
      <Map google={this.props.google}        
        ref={(el) => this.map = el}       
        zoom={zoom}            
        initialCenter={{
          lat: lat,
          lng: lng
        }}
        mapType={"satellite"}       
        gestureHandling={this.props.typeMap &&  "greedy"}
        onZoom_changed={(e)=>this.props.zoomChanged(e)}            
      >        
        {polygons}
        {polygon_marker}
        {markers}
        {lines}
        {info}
      </Map>
    );
  }


  render() {
    let map = "";

    map = this.render_map()    

    return (
      <div>
        {map}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (myConfig.GOOGLE_API_KEY),
  libraries:['drawing','places']
})(MapContainer)
