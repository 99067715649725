import React, { useState, useEffect } from "react";
import AddButton from "../../components/Add_button";
import CardAdmin from "./Card_Admin";
import myConfig from "../../configs/config";
import MDSpinner from "react-md-spinner";
import axios from "axios";
import { withTranslation } from "react-i18next";

function ListAdmin(props) {
  document.title = "Agriconnected - Administradores";

  const farmId = props.match.params.farm_id;

  const [listAdmins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);

  async function loadAdministrators() {
    try {
      let ADMIN_LIST = `${myConfig.API_URL}/accounts/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: ADMIN_LIST,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setAdmins(data);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar administradores, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  useEffect(() => {
    loadAdministrators();
  }, []);

  return (
    <div id="admin_list_container">
      {loading === false ? (
        <>
          <div className="x_title">
            <h2>{props.t("Administradores")}</h2>
            <div className="clearfix"></div>
          </div>
          <AddButton
            classname="btn btn-primary btn-default-add"
            url={`/${farmId}/administrator/add`}
          />

          {listAdmins.map((admins) => (
            <CardAdmin key={admins.id} admin={admins} farm_id={farmId} />
          ))}
        </>
      ) : (
        <div
          style={{
            marginTop: "50px",
            marginLeft: "50%",
            minHeight: "200px",
          }}
        >
          <MDSpinner />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(ListAdmin);
