import axios from 'axios'
import myConfig from '../../configs/config'
import {
    FETCH_FARMS,
    SET_FARM,
    FETCH_FARM_SUCCESS
} from './types';

import i18n from '../../i18n';

const FARM_URL = `${myConfig.API_URL}/farm/`;

export const setFarm = (id) => {
    return {
        type: SET_FARM,
        payload: id
    }
};

export const fetchFarmSuccess = (farm) => {
    return {
        type: FETCH_FARM_SUCCESS,
        farm
    }
}

export function fetchFarm(id) {
    return function (dispatch) {

        if (id !== undefined) {
            return axios({
                baseURL: `${FARM_URL}${id}/`,
                method: 'get',
                headers: {
                    'Authorization': `Bearer  ${localStorage.getItem('client-token')}`
                },
            })
                .then(({ data }) => {

                    if (data.error) {
                        throw (data.error)
                    }
                    // console.log("fetchFarm",data)
                    localStorage.setItem("farm", JSON.stringify(data.id))
                    localStorage.setItem("farm_name", data.nome_da_fazenda)

                    // console.log('***********')
                    // console.log(data.language)
                    // console.log('***********')
                    data.language !== ''  ? i18n.changeLanguage(data.language) : 
                    i18n.changeLanguage('pt-BR'); 

                    dispatch(fetchFarmSuccess(data))

                    return data

                })
                .catch(error => {
                    console.log("ERROR!", error)
                });
        }
        else {
            console.log('LOADING...')
        }
    };
}

export const setFarms = (farms) => {
    return {
        type: FETCH_FARMS,
        farms
    }
};

export function fetchFarms() {
    return function (dispatch) {
        return axios({
            baseURL: FARM_URL,
            method: 'get',
            headers: {
                'Authorization': `Bearer  ${localStorage.getItem('client-token')}`
            },
        })
            .then(({ data }) => {
                dispatch(setFarms(data))

            })
            .catch(error => {
                console.log("ERROR!", error)
                throw (error);
            });
    };
}

