import React, { Component } from "react";
import MapContainer from "../../components/Dashboard/Dashboard_map";
import myConfig from "../../configs/config";
import axios from "axios";
import { getErrorComponent } from "../../helpers/utils";
import { withTranslation } from "react-i18next";
import Input from "../../components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTractor } from "@fortawesome/free-solid-svg-icons";
import PoupUp from "../../components/PoupUp";
import KpisGadgets from "./KpiGadgets";
import { Link } from "react-router-dom";
import { FaUndo, FaPlus } from "react-icons/fa";

class Home extends Component {
  farmId = this.props.match.params.farm_id;

  FARM_URL = `${myConfig.API_URL}/farm/${this.farmId}/`;
  LANDPLOT_URL = `${myConfig.API_URL}/landplot/?farm_id=${this.farmId}`;
  MARKERS_URL = `${myConfig.API_URL}/equipment/equipments_info/?farm_id=${this.farmId}`;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      map: {},
      online_machines: "",
      weather_data: [],
      errors: [],
      farm: [],
      landplot: [],
      equipment: {},
      equip_search: {},
      map_search: false,
      marker_center: [],
      mapZoom: 0,
      modal: false,
      modalOperation: false,
      modalHoursIdle: false,
      kpi_data: [],
      periods: [],
      kpiOperations: [],
      kpiMachines: [],
      kpiReports: [],
      kpiIdle: [],
      period_id: 0,
      operations: [],
    };
    this.handleMapLoaded = this.handleMapLoaded.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(e) {
    const { map_search } = this.state;
    e.preventDefault();
    if (map_search === true) {
      this.setState({ map_search: false });
    } else {
      this.setState({ map_search: true });
    }
  }

  handleModal = () => {
    const { modal } = this.state;

    this.setState({ modal: !modal });
  };

  handleModalOperation = () => {
    // console.log("handleModalOperation");
    const { modalOperation } = this.state;

    this.setState({ modalOperation: !modalOperation });
  };

  handleModalHoursIdle = () => {
    // console.log("handleModalHoursIdle");
    const { modalHoursIdle } = this.state;

    this.setState({ modalHoursIdle: !modalHoursIdle });
  };

  handleMapLoaded(e, colors) {
    if (this.state.mapLoaded === false) {
      this.setState({
        line_color: colors,
        mapLoaded: true,
      });
    }
  }

  same_position(last_posi, new_posi) {
    if (last_posi.lat !== new_posi.lat) {
      if (last_posi.lng !== new_posi.lng) {
        return false;
      }
    }

    return true;
  }

  onZoomChanged = (e) => {
    this.setState({
      marker_center: [],
    });
  };

  load_markers_data() {
    axios({
      baseURL: this.MARKERS_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      // axios.get(this.MARKERS_URL)
      .then((res) => {
        let response = res.data;
        if (res.status === 200) {
          let equipment_data = {};

          for (let i = 0; i < response.length; i++) {
            equipment_data[response[i].id] = {
              id: response[i].id,
              ident: response[i].ident,
              farm: response[i].farm,
              farm_name: response[i].farm_name,
              category: response[i].category,
              marca: response[i].marca,
              category_name: response[i].category_name,
              sub_category: response[i].sub_category,
              subcategory_name: response[i].subcategory_name,
              capacity: response[i].capacity,
              manufacture_year: response[i].manufacture_year,
              classification: response[i].classification,
              manufacturer: response[i].manufacturer,
              device_uid: response[i].device_uid,
              machine_status: response[i].machine_status,
              traccar_ico: response[i].traccar_ico,
              speed: response[i].speed,
              last_connection: response[i].last_connection,
              operation: response[i].operation,
              last_posi_point: [],
              extra_inf: response[i].extra_inf,
            };

            if (this.state.equipment.hasOwnProperty(response[i].id)) {
              equipment_data[response[i].id][
                "last_posi_point"
              ] = this.state.equipment[response[i].id]["last_posi_point"];
            }
            equipment_data[response[i].id]["last_posi_point"].push({
              lat: response[i].last_posi_point.lat,
              lng: response[i].last_posi_point.lng,
            });
          }

          this.setState({
            equipment: equipment_data,
          });

          if (window.location.pathname.length < 6) {
            setTimeout(this.load_markers_data.bind(this), 10000);
          }
        } else {
          this.setState({
            errors: this.state.errors.concat(
              getErrorComponent(res.data.detail)
            ),
          });
        }
      });
  }

  load_landplot_data() {
    axios({
      baseURL: this.LANDPLOT_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((res) => {
        let landplot = res.data;
        if (res.status === 200) {
          this.setState({
            landplot: landplot,
          });
        } else {
          this.setState({
            errors: this.state.errors.concat(
              getErrorComponent(res.data.detail)
            ),
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        alert(
          "Falha ao carregar talhões, recarregue a página ou tente novamente mais tarde"
        );
      });
  }

  async fetchKpis() {
    let { farm } = this.state;

    try {
      const KPI_URL = `${myConfig.API_URL}/dashboard/?farm_id=${this.farmId}`;

      let response = await axios({
        baseURL: KPI_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      const kpi = await response.data;

      if (response.status === 200) {
        this.setState({
          periods: kpi.idle_info,
          loading: false,
          kpi_data: kpi,
          map: {
            centroidLat: farm.latitude.toString().replace(",", "."),
            centroidLng: farm.longitude.toString().replace(",", "."),
          },
        });

        if (window.location.pathname.length < 6) {
          setTimeout(this.fetchKpis.bind(this), 10000);
        }
      }
    } catch (err) {
      // console.log("err");
    }
  }

  setPeriod = (period) => {
    // console.log("period ***", period);
    this.setState({
      period_id: period,
    });
  };

  load_kpi_data() {
    axios({
      baseURL: this.FARM_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    }).then((res) => {
      let farm = res.data;

      if (res.status === 200) {
        this.setState({
          farm,
        });

        this.fetchKpis();
      } else {
        this.setState({
          errors: this.state.errors.concat(getErrorComponent(res.data.detail)),
        });
      }
    });
  }

  async loadOperations() {
    try {
      const URL = `${myConfig.API_URL}/operation/?farm_id=${this.farmId}&fineshed=0`;

      let response = await axios({
        baseURL: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      this.setState({
        operations: data,
      });
    } catch (e) {
      let error_msg =
        "Falha ao carregar operações, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  async componentWillMount() {
    //GTM

    //KPI's
    await this.load_kpi_data();
    // landplot
    this.load_landplot_data();
    // markers
    this.load_markers_data();
    // operations
    this.loadOperations();

    document.title = "Agriconnected - Painel";
  }

  _search_equipment_extra_info(equipment, search_value) {
    let extra_info = equipment.extra_inf;
    let found = false;

    if (extra_info.length > 0) {
      extra_info.map((item) => {
        if (item.value.toLowerCase().indexOf(search_value) !== -1) {
          found = true;
        }
      });
    }

    return found;
  }

  _search_equipment_info(equipment, value) {
    if (
      equipment.ident.toLowerCase().indexOf(value) !== -1 ||
      equipment.operation.toLowerCase().indexOf(value) !== -1 ||
      this._search_equipment_extra_info(equipment, value) !== false
    ) {
      return equipment;
    }

    return [];
  }

  handle_equip_search = (e, name) => {
    e.preventDefault();
    let value = e.target.value.toLowerCase();
    const { equipment } = this.state;
    let data = [];
    let has_result = false;
    let search_item = "";

    Object.keys(equipment).map((index) => {
      //search extra info
      search_item = this._search_equipment_info(equipment[index], value);
      if (search_item.length !== 0) {
        data.push(search_item);
      }

      // console.log(data);
      if (data.length !== 0) {
        has_result = true;
      }
    });

    if (has_result === true) {
      this.setState({
        equip_search: data,
      });
    } else {
      this.setState({
        equip_search: {
          msg:
            "Nenhum equipamento e operação encontrados com os valores forncecidos.",
        },
      });
    }
  };

  handleMarkerCenter = (e, equipment) => {
    e.preventDefault();
    let last_posi = equipment.last_posi_point;
    this.setState({
      marker_center: last_posi,
      mapZoom: 20,
    });
  };

  get_equipment_detail(equipment) {
    let extra_info = [];

    if (equipment.operation !== "") {
      extra_info.push(
        <p key={equipment.id}>
          <strong>Operação :</strong> {equipment.operation}
        </p>
      );
    }

    if (equipment.extra_inf !== undefined) {
      if (equipment.extra_inf.length > 0) {
        equipment.extra_inf.map((item) => {
          extra_info.push(
            <p key={item.id}>
              <strong>{item.label} : </strong>
              {item.value}
            </p>
          );
        });
      }
    }

    return (
      <>
        <li
          onClick={(e) => this.handleMarkerCenter(e, equipment)}
          className="list-group-item d-flex justify-content-between align-items-center"
          key={equipment.id}
        >
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">
              <div
                className={
                  equipment.machine_status === "offline"
                    ? "circle-red"
                    : "circle-green"
                }
              ></div>
              <strong>{equipment.ident}</strong>
            </h5>
            {extra_info}
          </div>
        </li>
      </>
    );
  }

  render_search_list() {
    let list = [];

    let equipment = this.sortedEquipments(this.state.equipment);
    let equip_search = this.state.equip_search;

    if (Object.keys(equip_search).length === 0) {
      Object.keys(equipment).map((item) => {
        if (equipment[item].machine_status === "offline") {
          list.push(this.get_equipment_detail(equipment[item])[-1]);
        }

        list.push(this.get_equipment_detail(equipment[item]));
      });
    } else {
      if (equip_search.hasOwnProperty("msg")) {
        list.push(<div>{equip_search.msg}</div>);
      } else {
        Object.keys(equip_search).map((item) => {
          if (equip_search[item].machine_status === "offline") {
            list.push(this.get_equipment_detail(equip_search[item])[-1]);
          }
          // console.log("else", equip_search, item);
          list.push(this.get_equipment_detail(equip_search[item]));
        });
      }
    }

    return list;
  }

  render_search_component() {
    let content = "";

    content = this.render_search_list();

    return (
      <div className="container-search">
        <Input
          icon="search"
          placeholder="Buscar"
          handleChange={this.handle_equip_search}
          class="form-control"
          type="text"
        />
        <ul className="list-group">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <div
              className="d-flex w-100 justify-content-between"
              onClick={(e) => {
                this.setState({ map_search: false });
              }}
            >
              <h5 className="mb-1">
                <strong>
                  <FaUndo />
                  Voltar
                </strong>
              </h5>
            </div>
          </li>
          {content}
        </ul>
      </div>
    );
  }

  load_map() {
    let search_content,
      button_class,
      button_label = "";
    let {
      map_search,
      mapZoom,
      marker_center,
      map,
      landplot,
      farm,
      equipment,
      modal,
      modalOperation,
      modalHoursIdle,
    } = this.state;

    if (map_search === true) {
      search_content = this.render_search_component();
      button_label = <div>Máquinas</div>;
      button_class = "btn-search active";
    } else {
      search_content = "";
      button_class = "btn-search active minimize-button";
      button_label = <FontAwesomeIcon icon={faTractor} />;
    }

    return (
      <div className="map-container">
        <MapContainer
          zoomChanged={this.onZoomChanged}
          mapZoom={mapZoom}
          marker_center={marker_center}
          map={map}
          equipments={equipment}
          farm={farm}
          handleLoaded={this.handleMapLoaded}
          landplot={landplot}
          farm_id={this.farmId}
        />
        <div className="map-search">
          <button className={button_class} onClick={this.handleSearch}>
            {button_label}
          </button>
          {search_content}
        </div>
        <div className="modal-list-machines-web">
          {modal && (
            <div className="x_panel">
              {this.renderMachines()}

              <Link
                to={`/${this.farmId}/equipment/machine/add/`}
                className="btn btn-kpi-add"
              >
                {" "}
                <FaPlus size={20} />{" "}
              </Link>
            </div>
          )}
        </div>

        <div className="modal-list-operations-web">
          {modalOperation && (
            <>
              <div className="x_panel">
                {this.renderOperations()}

                <Link
                  to={`/${this.farmId}/operation/add/`}
                  className="btn btn-kpi-add"
                >
                  {" "}
                  <FaPlus size={20} />{" "}
                </Link>
              </div>
            </>
          )}
        </div>

        <div className="modal-list-machine-idle-web">
          {modalHoursIdle && (
            <>
              <div className="x_panel">{this.renderMachinesIdle()}</div>
            </>
          )}
        </div>
      </div>
    );
  }

  sortedEquipments(data) {
    let newList = Object.keys(data)
      .map((key) => data[key])
      .sort((a, b) =>
        a.machine_status == b.machine_status
          ? 0
          : a.machine_status == "offline"
          ? 1
          : -1
      );
    // console.log("newList", newList);
    return newList;
  }

  renderMachinesIdle() {
    let { periods, period_id } = this.state;

    return (
      <div className="table-machines-idle">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{this.props.t("Identificação")}</th>
              <th>{this.props.t("Tempo ocioso")}</th>
            </tr>
          </thead>
          <tbody>
            {periods[period_id].equipments.map((item) => (
              <tr key={item.name}>
                <td scope="row">{item.equipment_name}</td>
                <td>{item.hours}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderMachines() {
    const { equipment } = this.state;

    return (
      <>
        <ul className="list-group list-machines-with-link">
          {Object.keys(equipment).map((item) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={equipment[item].id}
              >
                <Link
                  to={`/${this.farmId}/equipment/machine/${equipment[item].id}/report`}
                  className="machine-link-detail"
                >
                  <strong>{equipment[item].ident}</strong>
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  renderOperations() {
    const { operations } = this.state;

    return (
      <>
        <ul className="list-group list-operations-with-link">
          {operations.map((item) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={item.id}
              >
                <Link
                  to={`/${this.farmId}/operation/${item.id}/progress/`}
                  className="machine-link-detail"
                >
                  <strong>{item.ident}</strong>
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  get_content() {
    let { modal, modalOperation, modalHoursIdle } = this.state;

    return (
      <>
       <div className="modal-list-machine-idle-mobile">
          {modalHoursIdle && (
            <>
              <div className="x_panel-idle-mobile">
                {this.renderMachinesIdle()}
              </div>
            </>
          )}
        </div>
        <div className="modal-list-operations-mobile">
          {modalOperation && (
            <div className="x_panel-operations-mobile">
              {this.renderOperations()}

              <Link
                to={`/${this.farmId}/equipment/operation/add/`}
                className="btn btn-kpi-add"
              >
                {" "}
                <FaPlus size={20} />{" "}
              </Link>
            </div>
          )}
        </div>

        <div className="modal-list-machines-mobile">
          {modal && (
            <div className="x_panel-mobile">
              {this.renderMachines()}

              <Link
                to={`/${this.farmId}/equipment/machine/add/`}
                className="btn btn-kpi-add"
              >
                {" "}
                <FaPlus size={20} />{" "}
              </Link>
            </div>
          )}
        </div>
       
        {this.load_map()}
      </>
    );
  }

  showPopup() {
    let client = JSON.parse(localStorage.client);

    if (client.accepted_terms !== true) {
      return <PoupUp user_id={client.id} farm_id={this.farmId} />;
    }
  }

  displayKpis() {
    let { kpi_data, periods, period_id, loading } = this.state;

    return (
      <div className="row tile_count ind-container">
        {loading ? (
          <>
            <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6 ">
              <div className="x_panel no-margin-top animate"></div>
            </div>
            <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6 ">
              <div className="x_panel no-margin-top animate"></div>
            </div>{" "}
            <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6 ">
              <div className="x_panel no-margin-top animate"></div>
            </div>{" "}
            <div className="col-xs-12 col-md-3 col-lg-3 col-sm-6 ">
              <div className="x_panel no-margin-top animate"></div>
            </div>
          </>
        ) : (
          <KpisGadgets
            periods={periods}
            period_id={period_id}
            kpi={kpi_data}
            farm_id={this.farmId}
            handleModal={this.handleModal}
            setPeriod={this.setPeriod}
            handleModalOperation={this.handleModalOperation}
            handleModalHoursIdle={this.handleModalHoursIdle}
          />
        )}
      </div>
    );
  }

  render() {
    let { loading } = this.state;

    let PoupUp;
    let content;

    if (loading === false) {
      content = this.get_content();
      PoupUp = this.showPopup();
    } else {
      content = <ShimmerLoadingMap />;
    }

    return (
      <div className="dashboard">
        {this.displayKpis()}

        {content}
        {PoupUp}
      </div>
    );
  }
}

function ShimmerLoadingMap() {
  return (
    <>
      <div className="shimmer-map-container animate"></div>
    </>
  );
}

export default withTranslation()(Home);
