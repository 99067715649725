import React from "react";
import classnames from "classnames";
import { FaSearch } from "react-icons/fa";

function Input(props) {
  let label = "";

  if (props.label) {
    label = (
      <label className="control-label" htmlFor={props.name}>
        {props.label}
      </label>
    );
    if (props.strong) {
      label = (
        <label className="control-label" htmlFor={props.name}>
          <strong>{props.label}</strong>
        </label>
      );
    }
  }

  // if(props.icon){
  //     icon = <>
  //     </>
  // }

  return (
    <div className="form-group">
      {label}
      <div className="input-group">
        <input
          type={props.type}
          className={classnames("form-control", props.class)}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(event) => props.handleChange(event, props.name)}
        />
        {props.icon && (
          <div className="input-group-addon">
            {" "}
            <FaSearch />{" "}
          </div>
        )}
      </div>
    </div>
  );
}

export default Input;
