import React, {  useState } from "react";
import { KeyboardDatePicker,MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import ptBR from "date-fns/locale/pt-BR";
import green from "@material-ui/core/colors/green";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import  '../containers/Machine/Machine.css';


function BasicDate(props) {
  const [selectedDate, handleDateChange] = useState(new Date());

    const defaultMaterialTheme = createMuiTheme({
        palette: {
        primary: green,
        },
    });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <ThemeProvider theme={defaultMaterialTheme}>
         <label id="labelDate">{props.label}</label>
           <KeyboardDatePicker
            selected={props.date}
            value={props.date}
            format="dd/MM/yyyy"
            label="Data"
            views={["date",]}
            onAccept={props.handChangeDate}
            onChange={handleDateChange}
           />  
        </ThemeProvider>   
    </MuiPickersUtilsProvider>
  );
}

export default BasicDate;
