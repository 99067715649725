let WEATHER_DOMAIN_API_KEY = "185871f63cc882253dcfa3ef909277e3";

export default {
  STATIC_URL: process.env.REACT_APP_STATIC_URL,
  API_URL: process.env.REACT_APP_API_URL,
  ABSOLUTE_URL: process.env.REACT_APP_ABSOLUTE_URL,
  BE_URL: process.env.REACT_APP_BE_URL,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  WEATHER_DOMAIN_API:
    "https://api.openweathermap.org/data/2.5/weather?appid=" +
    WEATHER_DOMAIN_API_KEY +
    "&units=metric",
  GA_ID: "UA-107569106-5",
};
