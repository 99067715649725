import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import axios from "axios";
import myConfig from "../../../configs/config";
import { withTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MDSpinner from "react-md-spinner";

import { ToastContainer, toast } from "react-toastify";

import FloatingDropdown from "../../../components/FloatingDropdown";
import AgriDateTime from "../../../components/AgriDateTime";
import BasicDate from "../../../components/Date";
import SelectBox from "../../../components/SelectBox";

import { Collapse, CardBody, Card } from "reactstrap";

import { FaEye, FaPlus } from "react-icons/fa";

import { getTime, formatedDate } from "../../../helpers/utils";

import "./index.css";

function Appointments(props) {
  const params = useParams();
  const history = useHistory();

  const farmId = params.farm_id;
  const machineId = params.machine_id;

  const [currentMachine, setCurrentMachine] = useState([]);

  const [currentMaintenance, setCurrentMaintenance] = useState([]);

  const [listMaintenances, setListMaintenances] = useState([]);
  const [listAllMaintenances, setListAllMaintenances] = useState([]);
  const [listDataMaintenances, setListDataMaintenances] = useState([]);

  const [listFuel, setListFuel] = useState([]);

  const [selectedUnit, setSelectedUnit] = useState("");

  const [selectedType, setSelectedType] = useState(0);

  const [selectedResponsible, setSelectedResponsible] = useState("");

  const [selectedQuantity, setSelectedQuantity] = useState("");

  const [lengthOnDate, setLengthOnDate] = useState("");

  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedHorimeter, setSelectedHorimeter] = useState("");

  const [selectedCurrentDate, setSelectedCurrentDate] = useState(new Date());

  const [openEye, setOpenEyeModal] = useState(false);
  const [openPlus, setOpenPlusModal] = useState(false);

  const [openContentFuel, setOpenContentFuel] = useState(true);
  const [openCollapseFuel, setOpenCollapseFuel] = useState(false);

  const [openContentMaintenance, setOpenContentMaintenance] = useState(false);
  const [openCollapseMaintenance, setOpenCollapseMaintenance] = useState(false);

  const [loading, setLoding] = useState(true);

  async function getDataFromMachine() {
    let CURRENT_MACHINE_URL = `${myConfig.API_URL}/equipment/${machineId}/`;

    let response = await axios({
      baseURL: CURRENT_MACHINE_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    if (data) {
      setCurrentMachine(data);
      setLoding(false);
    }
  }

  async function loadAllMaintenanceData() {
    const URL = `${myConfig.API_URL}/equipment_maintenance/?equipment=${machineId}`;

    let response = await axios({
      baseURL: URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setListAllMaintenances(data);
  }

  async function loadMaintenanceData() {
    const URL = `${myConfig.API_URL}/equipment_maintenance/lasts/?equipment=${machineId}`;

    let response = await axios({
      baseURL: URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setListMaintenances(data);
  }

  async function loadFuelData() {
    const URL = `${myConfig.API_URL}/equipment_fuel/?equipment=${machineId}`;

    let response = await axios({
      baseURL: URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setListFuel(data);
  }

  useEffect(() => {
    document.title = "Agriconnected - Apontamentos";

    getDataFromMachine();

    loadMaintenanceData();
    loadAllMaintenanceData();

    loadFuelData();
  }, []);

  const handleOpen = (e, item) => {
    e.preventDefault();

    let maintencesList = listAllMaintenances.filter(
      (maintence) => maintence.desc_name === item.desc_name
    );

    setOpenEyeModal(true);

    setListDataMaintenances(maintencesList);
  };

  const handleClose = () => {
    setOpenEyeModal(false);
  };

  const handleOpenPlusModal = (e, item) => {
    e.preventDefault();

    setOpenPlusModal(true);
    setCurrentMaintenance(item);
  };
  const handleClosePlusModal = () => {
    setOpenPlusModal(false);
  };

  function formatDate(e) {
    let data = new Date(e),
      dia = e.getDate().toString(),
      diaF = dia.length === 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(),
      mesF = mes.length === 1 ? "0" + mes : mes,
      anoF = data.getFullYear();

    return `${anoF}-${mesF}-${diaF}`;
  }

  function formDataFuel() {
    let formData = {
      equipment: currentMachine.id,
      responsible: selectedResponsible,
      datetime: selectedCurrentDate,
      fuel: selectedType,
      qtty: selectedQuantity,
      hotimeter: selectedHorimeter,
    };
    return formData;
  }

  async function handleSaveFuel(e) {
    e.preventDefault();
    try {
      let URL = `${myConfig.API_URL}/equipment_fuel/`;

      let response = await axios({
        baseURL: URL,
        method: "POST",
        data: formDataFuel(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 201) {
        toast.success(
          `${props.t("Combustível")}  ${props.t("cadastrado com sucesso")}!`,
          {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.go(`/${farmId}/equipment/machine/${machineId}/appointments`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function formDataMaintenance() {
    let formData = {
      equipment: currentMachine.id,
      date: formatDate(selectedCurrentDate),
      desc: selectedDescription,
      qtty: selectedQuantity,
      unity: selectedUnit,
      hotimeter: selectedHorimeter,
    };
    return formData;
  }

  async function handleSaveMaintenance(e) {
    e.preventDefault();

    try {
      let URL = `${myConfig.API_URL}/equipment_maintenance/`;

      let response = await axios({
        baseURL: URL,
        method: "POST",
        data: formDataMaintenance(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 201) {
        toast.success(
          `${props.t("Nova manutenção")}  ${props.t(
            "cadastrada com sucesso"
          )}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.go(`/${farmId}/equipment/machine/${machineId}/appointments`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleTimeChange(event) {
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let timeFormated = new Date(
      selectedCurrentDate.toLocaleDateString("en-US", options) +
        " " +
        event.target.value
    );

    setSelectedCurrentDate(timeFormated);
  }

  function handChangeDate(event) {
    setSelectedCurrentDate(event);
  }

  function Title(props) {
    return (
      <div className="x_title">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-6">
              <h3 className="title-appointments">{props.children}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Loading() {
    return (
      <div className="loading-spinner">
        <MDSpinner />
      </div>
    );
  }

  function OpenContentFuel() {
    setOpenContentFuel(true);
    setOpenContentMaintenance(false);
  }

  function OpenContentMaintenance() {
    setOpenContentMaintenance(true);
    setOpenContentFuel(false);
  }

  function TabsContent() {
    return (
      <div className="x_content">
        <div className="x_content">
          <ul className="nav nav-tabs bar_tabs" id="myTab" role="tablist">
            <li className={`nav-item ${openContentFuel && "active"}`}>
              <a aria-selected="false" onClick={() => OpenContentFuel()}>
                {props.t("Combustível")}
              </a>
            </li>
            <li className={`nav-item ${openContentMaintenance && "active"}`}>
              <a
                className="nav-link"
                aria-selected="false"
                onClick={() => OpenContentMaintenance()}
              >
                {props.t("Manutenção")}
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    );
  }

  async function handleSaveLastMaintenance(e) {
    e.preventDefault();

    let { desc_name, equipment, unity } = currentMaintenance;

    try {
      let URL = `${myConfig.API_URL}/equipment_maintenance/`;

      let response = await axios({
        baseURL: URL,
        method: "POST",
        data: {
          date: formatDate(selectedCurrentDate),
          desc: desc_name,
          equipment,
          hotimeter: lengthOnDate,
          unity,
          qtty: selectedQuantity,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = response.status;

      if (status === 201) {
        toast.success(
          `${props.t("Manutenção")}  ${props.t("cadastrada com sucesso")}!`,
          {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        // setLoding(true);
        setTimeout(() => {
          history.go(`/${farmId}/equipment/machine/${machineId}/appointments`);
          // setLoding(false);
          // loadMaintenanceData();
          // loadAllMaintenanceData();
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function TableFuel() {
    return (
      <table className="table table-striped table-row-color">
        <thead>
          <tr>
            <th> {props.t("Data")}</th>
            <th> {props.t("Hora")}</th>
            <th> {props.t("Responsável")}</th>
            <th> {props.t("KM/Horímetro")}</th>
            <th> {props.t("Combustível")}</th>
            <th> {props.t("Quantidade")}</th>
          </tr>
        </thead>
        <tbody>
          {listFuel.map((item, index) => (
            <tr key={index}>
              <td>{formatedDate(item.datetime)}</td>
              <td>{getTime(item.datetime)}</td>
              <td>{item.responsible}</td>
              <td>{item.hotimeter}</td>
              <td>{item.fuel}</td>
              <td>{item.qtty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function handleSetOpenFuel() {
    setOpenCollapseFuel(!openCollapseFuel);
    setOpenContentFuel(true);
    setOpenContentMaintenance(false);
  }
  function handleSetOpenMaintenance() {
    setOpenCollapseMaintenance(!openCollapseMaintenance);
    setOpenContentMaintenance(true);
    setOpenContentFuel(false);
  }

  return (
    <div className="appoiments-container">
      {loading === false ? (
        <>
          <FloatingDropdown>
            {" "}
            <li>
              {" "}
              <a
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseFuel"
                onClick={handleSetOpenFuel}
              >
                {props.t("Novo Abastecimento")}
              </a>
            </li>
            <li>
              {" "}
              <a
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseMaintenance"
                onClick={handleSetOpenMaintenance}
              >
                {props.t("Nova manutenção")}
              </a>{" "}
            </li>
          </FloatingDropdown>

          <Title>
            {`${currentMachine.ident} - ${props.t("Apontamentos")}`}
          </Title>
          <br />
          <TabsContent />

          <div className="">
            {openContentFuel && (
              <div className="tab-pane ">
                <br />

                <Collapse isOpen={openCollapseFuel}>
                  <Card>
                    <CardBody className="card card-body  collapse-suport">
                      <form onSubmit={handleSaveFuel}>
                        <div className="container ">
                          <div className="row-collpse-responsive">
                            <div className="col col-collapse-suply">
                              <div className="col-filter col-xs-12 pull-left">
                                <AgriDateTime
                                  time={getTime(selectedCurrentDate)}
                                  align="horizontal"
                                  timeLabel={"Hora "}
                                  label={"Data"}
                                  datetimeformat="true"
                                  selectedDate={selectedCurrentDate}
                                  handleDateChange={handChangeDate}
                                  handleTimeChange={handleTimeChange}
                                />
                              </div>
                            </div>

                            <div
                              className="col col-collapse-suply"
                              style={{ width: "20%" }}
                            >
                              <label htmlFor="">{props.t("Responsável")}</label>
                              <input
                                style={{ width: "100%" }}
                                className="input-collapse"
                                type="text"
                                value={selectedResponsible}
                                onChange={(event) =>
                                  setSelectedResponsible(event.target.value)
                                }
                              />
                            </div>
                            <div
                              className="col col-collapse"
                              style={{ width: "20%" }}
                            >
                              <label htmlFor="">
                                {props.t("KM/Horímetro")}
                              </label>
                              <input
                                style={{ width: "100%" }}
                                className="input-collapse"
                                type="number"
                                id="horimeter"
                                name="quantity"
                                value={selectedHorimeter}
                                onChange={(event) =>
                                  setSelectedHorimeter(event.target.value)
                                }
                              />
                            </div>
                            <div className="col col-collapse-suply">
                              <SelectBox
                                strong="true"
                                classname="input-collapse"
                                data={[
                                  { id: "diesel", name: "Diesel" },
                                  { id: "gasolina", name: "Gasolina" },
                                  { id: "alcool", name: "Álcool" },
                                ]}
                                selected_value={selectedType}
                                value={"name"}
                                change={(event) =>
                                  setSelectedType(event.target.value)
                                }
                                label="Combustível"
                                id="id_type"
                              />
                            </div>

                            <div className="col col-collapse-suply">
                              <label htmlFor="">{props.t("Quantidade")}</label>
                              <input
                                style={{ width: "100%" }}
                                className="input-collapse"
                                type="number"
                                placeholder=""
                                value={selectedQuantity}
                                id="quantity"
                                name="quantity"
                                onChange={(event) =>
                                  setSelectedQuantity(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div align="center">
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={"Adicionar"}
                          />
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </Collapse>

                {listFuel.length === 0 ? (
                  <h4 className="text-center title-appointments">
                    {props.t("Ainda não há nenhum registro de combustível")}
                  </h4>
                ) : (
                  <TableFuel />
                )}
              </div>
            )}

            {openContentMaintenance && (
              <div className="tab-pane" role="tabpanel">
                <br />
                <Collapse isOpen={openCollapseMaintenance}>
                  <Card>
                    <CardBody className="card card-body  collapse-suport">
                      <form onSubmit={handleSaveMaintenance}>
                        <div className="container ">
                          <div className="row-collpse-responsive">
                            <div
                              className="col col-collapse"
                              style={{ marginLeft: "15px" }}
                            >
                              <br />
                              <BasicDate
                                date={selectedCurrentDate}
                                handChangeDate={handChangeDate}
                              />
                            </div>
                            <div className="col col-collapse">
                              <label htmlFor="">{props.t("Descrição")}</label>
                              <br />
                              <input
                                style={{ width: "100%" }}
                                className="input-collapse"
                                type="text"
                                id="description"
                                name="description"
                                value={selectedDescription}
                                onChange={(event) =>
                                  setSelectedDescription(event.target.value)
                                }
                              />
                            </div>

                            <div className="col col-collapse">
                              <SelectBox
                                strong="true"
                                classname="input-collapse"
                                data={[
                                  { id: "L", name: "Litros" },
                                  { id: "pc", name: "Peça" },
                                  { id: "kg", name: "Kilo" },
                                ]}
                                selected_value={selectedUnit}
                                value={"name"}
                                change={(event) =>
                                  setSelectedUnit(event.target.value)
                                }
                                label="Unidade"
                                id="id_unit"
                              />
                            </div>

                            <div className="col col-collapse-suply">
                              <label htmlFor="">{props.t("Quantidade")}</label>
                              <input
                                style={{ width: "100%" }}
                                className="input-collapse"
                                type="number"
                                placeholder=""
                                value={selectedQuantity}
                                id="quantity"
                                name="quantity"
                                onChange={(event) =>
                                  setSelectedQuantity(event.target.value)
                                }
                              />
                            </div>

                            <div className="col col-collapse">
                              <label htmlFor="">
                                {props.t("KM/Horímetro")}
                              </label>
                              <input
                                style={{ width: "100%" }}
                                className="input-collapse"
                                type="number"
                                id="horimeter"
                                name="horimeter"
                                value={selectedHorimeter}
                                onChange={(event) =>
                                  setSelectedHorimeter(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div align="center">
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={"Adicionar"}
                          />
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </Collapse>

                {listMaintenances.length === 0 ? (
                  <h4 className="text-center title-appointments ">
                    Ainda não há nenhum registro de manutenção
                  </h4>
                ) : (
                  <table className="table table-striped table-row-color">
                    <thead>
                      <tr>
                        <th> {props.t("Descrição")}</th>
                        <th> {props.t("Última data")}</th>
                        <th> {props.t("Unidade")}</th>
                        <th> {props.t("Quantidade")}</th>
                        <th> {props.t("Última medida")}</th>
                        <th className="text-center"> {props.t("Ações")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listMaintenances.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.desc_name}</td>
                            <td>{formatedDate(item.date)}</td>
                            <td>{item.unity}</td>
                            <td>{item.qtty}</td>
                            <td>{item.hotimeter}</td>
                            <td align="center">
                              <div className="btn-group">
                                <>
                                  <p
                                    className="btn"
                                    onClick={(e) => handleOpen(e, item)}
                                  >
                                    <FaEye />
                                  </p>
                                  <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    open={openEye}
                                    onClose={handleClose}
                                  >
                                    <div className="modal-size">
                                      <div className="x_title">
                                        <br />
                                        <h4 align="center">
                                          {props.t("Histórico")} -{" "}
                                          {listDataMaintenances[0] &&
                                            listDataMaintenances[0].desc_name}
                                        </h4>
                                        <div className="clearfix"></div>
                                      </div>

                                      {listDataMaintenances.length === 0 ? (
                                        <h4 className="text-center title-appointments">
                                          {props.t(
                                            "Ainda não há nenhum registro semelhante a este"
                                          )}
                                        </h4>
                                      ) : (
                                        <Table
                                          aria-label="customized table"
                                          style={{
                                            marginLeft: "18px",
                                            width: "93%",
                                          }}
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>
                                                {" "}
                                                {props.t("Data")}
                                              </TableCell>
                                              <TableCell>
                                                {" "}
                                                {props.t("Quantidade")}
                                              </TableCell>
                                              <TableCell>
                                                {" "}
                                                {props.t("KM/Horímetro")}
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {listDataMaintenances.map(
                                              (item, index) => (
                                                <TableRow key={index}>
                                                  <TableCell>
                                                    {formatedDate(item.date)}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.qtty}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.hotimeter}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      )}
                                    </div>
                                  </Modal>
                                </>
                                <p className="btn">
                                  <>
                                    <a
                                      onClick={(e) =>
                                        handleOpenPlusModal(e, item)
                                      }
                                    >
                                      <FaPlus />
                                    </a>
                                    <Modal
                                      aria-labelledby="simple-modal-title"
                                      aria-describedby="simple-modal-description"
                                      open={openPlus}
                                      onClose={handleClosePlusModal}
                                    >
                                      <div className="modal-size">
                                        <div className="x_title">
                                          <h4 align="center">
                                            {props.t("Histórico")} -{" "}
                                            {currentMaintenance &&
                                              currentMaintenance.desc_name}
                                          </h4>
                                          <div className="clearfix"></div>
                                        </div>

                                        <form
                                          onSubmit={(event) =>
                                            handleSaveLastMaintenance(event)
                                          }
                                        >
                                          <div className="responsive-inline-row">
                                            <div
                                              className=""
                                              style={{
                                                marginRight: "15px",
                                                marginLeft: "9%",
                                                width: "20%",
                                              }}
                                            >
                                              <BasicDate
                                                date={selectedCurrentDate}
                                                handChangeDate={handChangeDate}
                                              />
                                            </div>
                                            <div
                                              className=""
                                              style={{
                                                marginRight: "15px",
                                                width: "25%",
                                              }}
                                            >
                                              <label htmlFor="">
                                                {props.t("Quantidade")}
                                              </label>
                                              <input
                                                className="input-collapse"
                                                type="number"
                                                placeholder=""
                                                value={selectedQuantity}
                                                id="quantity"
                                                name="quantity"
                                                onChange={(event) =>
                                                  setSelectedQuantity(
                                                    event.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div
                                              className=""
                                              style={{
                                                marginRight: "15px",
                                                marginLeft: "30px",
                                                width: "25%",
                                              }}
                                            >
                                              <label htmlFor="">
                                                {props.t("KM/Horímetro")}
                                              </label>
                                              <input
                                                type="text"
                                                id="lengthOnDate"
                                                name="lengthOnDate"
                                                className="input-collapse"
                                                value={lengthOnDate}
                                                onChange={(e) =>
                                                  setLengthOnDate(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <br />
                                          <div align="center">
                                            <button
                                              type="submit"
                                              className="btn btn-primary"
                                            >
                                              Adicionar{" "}
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </Modal>
                                  </>
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
          <ToastContainer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default withTranslation()(Appointments);
