import React, { Component } from "react";
import Title from "../../components/Title";
import Stepper from "react-stepper-horizontal";
import FirstOperationStep from "../../components/Operation/Steps/FirstOperationStep";
import SecondOperationStep from "../../components/Operation/Steps/SecondOperationStep";
import ThirdOperationStep from "../../components/Operation/Steps/ThirdOperationStep";
import FourthOperationStep from "../../components/Operation/Steps/FourthOperationStep";
import myConfig from "../../configs/config";
import axios from "axios";
import { withTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";
import { getFormatedDate } from "../../helpers/utils";

class OpertationRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      op_id: "",
      currentStep: 0,
      landplot: "",
      operator: "",
      implement: "",
      machine: "",
      op_type: "covering",
      op_type_label: "Operação em Talhão",
      landplot_label: "",
      operator_label: "",
      machine_label: "",
      implement_label: "",
      machine_classification: "",
      daily_hours: 8,
      op_date: new Date(),
      ident: "",
      speed: "",
      width: "",
      error_fields: "",
      error_save: "",
      length: "",
      isempty: false,
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }
  componentDidMount() {}

  getSteps() {
    let type_operation = this.props.t("Tipo de Operação");
    let Envolvidos = this.props.t("Envolvidos");
    let Quando = this.props.t("Quando");
    let Resumo = this.props.t("Resumo");

    return [
      { title: type_operation },
      { title: Envolvidos },
      { title: Quando },
      { title: Resumo },
    ];
  }

  handleDateChange(e) {
    // console.log(e);
    this.setState({
      op_date: e,
    });
  }

  handleTimeChange(e) {
    // console.log(e.target.value);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(this.state.op_date);
    data = new Date(
      data.toLocaleDateString("en-US", options) + " " + e.target.value
    );
    this.setState({ op_date: new Date(getFormatedDate(data)) });
  }

  handleChangeSelect(e, component_name) {
    let component_label = component_name + "_label";
    let component_classification = component_name + "_classification";
    let index = e.target.selectedIndex;
    let label = e.target[index].text;
    if (component_name === "implement" || component_name === "machine") {
      this.setState({
        [component_name]: e.target.value,
        [component_label]: label,
        [component_classification]: e.target[index].getAttribute(
          "classification"
        ),
        width:
          e.target[index].getAttribute("width") === null
            ? ""
            : e.target[index].getAttribute("width"),
        speed:
          e.target[index].getAttribute("speed") === "0 km/h"
            ? ""
            : e.target[index].getAttribute("speed"),
        ident: "",
      });
    } else {
      this.setState({
        [component_name]: e.target.value,
        [component_label]: label,
      });
    }
  }

  handleChangeText(e, component_name) {
    if (component_name === "ident" && e.target.value === "") {
      this.setState({
        isempty: true,
        [component_name]: e.target.value,
      });
    } else {
      this.setState({
        [component_name]: e.target.value,
      });
    }
  }

  handleChangeRadio(e) {
    this.setState({
      op_type: e.target.value,
      op_type_label: e.target.getAttribute("label"),
      landplot_label: "",
      landplot: "",
    });
  }

  getStepContent(step) {
    let return_button, next_button, last_step;

    last_step = this.getSteps().length - 1;

    switch (step) {
      case 0:
        return (
          <FirstOperationStep
            currentStep={this.state.currentStep}
            lastStep={last_step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            current_type={this.state.op_type}
            handleCheck={this.handleChangeRadio}
          />
        );
      case 1:
        return (
          <SecondOperationStep
            currentStep={this.state.currentStep}
            lastStep={last_step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            {...this.state}
            farm_id={this.props.match.params.farm_id}
            handleChange={this.handleChangeSelect}
          />
        );
      case 2:
        return (
          <ThirdOperationStep
            currentStep={this.state.currentStep}
            lastStep={last_step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            {...this.state}
            init_date={this.state.op_date}
            daily_hours={this.state.daily_hours}
            handleChangeText={this.handleChangeText}
            handleDateChange={this.handleDateChange}
            handleTimeChange={this.handleTimeChange}
          />
        );
      case 3:
        this.operationIdent();
        return (
          <FourthOperationStep
            ident={this.state.ident}
            handleSave={this.handleSave}
            currentStep={this.state.currentStep}
            lastStep={last_step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            button={next_button}
            return={return_button}
            {...this.state}
            handleChangeText={this.handleChangeText}
          />
        );
      default:
        return "Unknown step";
    }
  }

  operationIdent() {
    if (this.state.ident === "") {
      let ident_arr = [];

      this.state.implement_label.trim() !== "" &&
        ident_arr.push(this.state.implement_label);
      this.state.landplot_label.trim() !== "" &&
        ident_arr.push(this.state.landplot_label);
      this.state.machine_label.trim() !== "" &&
        ident_arr.push(this.state.machine_label);

      let ident_name =
        this.state.isempty === true ? " " : ident_arr.join(" - ");

      this.setState({
        ident: ident_name,
      });
    }
  }

  handleSave(e) {
    e.preventDefault();

    let farm_id = this.props.match.params.farm_id;
    let SAVE_URL = myConfig.API_URL + "/operation/?farm_id=" + farm_id;
    var self = this;

    const data = {
      ident: this.state.ident,
      farm: this.props.match.params.farm_id,
      farm_id: this.props.match.params.farm_id,
      finished: false,
      operator: this.state.operator,
      machine: this.state.machine,
      impl: this.state.implement,
      dt_ini: this.state.op_date,
      daily_hours: this.state.daily_hours,
      created: new Date(),
      land_plot: this.state.landplot,
      operation_type: this.state.op_type,
      width: parseInt(this.state.width),
      speed: parseInt(this.state.speed),
    };

    axios({
      baseURL: SAVE_URL,
      method: "post",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          let SUCESS_URL =
            myConfig.ABSOLUTE_URL +
            farm_id +
            "/operation/" +
            response.data.id +
            "/progress";
          window.location.href = SUCESS_URL;
        } else {
          let errors_component = [];
          for (let i = 0; i < response.data.msg.length; i++) {
            errors_component.push(
              <Alert variant="danger">{response.data.msg[i]}</Alert>
            );
          }
          self.setState({
            error_save: errors_component,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Erro ao cadastrar nova operação, reinicie a página e tente novamente")
      });
  }

  setActiveStep(step) {
    this.setState({
      currentStep: step,
    });
  }

  handleBack() {
    this.setState({
      error_fields: "",
      error_save: "",
    });
    this.setActiveStep(this.state.currentStep - 1);
  }

  validateSecondStep() {
    let error_fields = [];
    // 0 is the landplot operation
    if (this.state.op_type === "covering") {
      if (this.state.landplot.trim() === "") {
        error_fields.push("landplot");
      }
    }
    if (this.state.machine_classification === "trator") {
      if (this.state.implement.trim() === "") {
        error_fields.push("implement");
      }
    }

    if (this.state.operator.trim() === "") {
      error_fields.push("operator");
    }

    if (this.state.machine.trim() === "") {
      error_fields.push("machine");
    }

    if (error_fields.length === 0) {
      return true;
    } else {
      this.setState({
        error_fields,
      });
      return false;
    }
  }

  validateThirdStep() {
    let error_fields = [];
    let { daily_hours } = this.state;
    
    if (daily_hours === "0" || daily_hours === "") {
      error_fields.push("daily_hours");
    }

    if (error_fields.length === 0) {
      return true;
    } else {
      this.setState({
        error_fields,
      });
      return false;
    }
  }

  validateStep() {
    let isValid = true;

    switch (this.state.currentStep) {
      case 1:
        isValid = this.validateSecondStep();

        if (isValid) {
          this.setState({
            error_fields: "",
          });
        }

        return isValid;

        break;
      case 2:
        isValid = this.validateThirdStep();

        if (isValid) {
          this.setState({
            error_fields: "",
          });
        }

        return isValid;

        break;
      default:
        return true;
    }
  }

  handleNext(e) {
    e.preventDefault();
    let { op_type } = this.state

    let farmId = this.props.match.params.farm_id
    
    if(op_type === "multiples_operations"){
      this.props.history.push(`/${farmId}/operation/mult-operations`)
      return
    }

    let isValid = false;

    isValid = this.validateStep();
    if (!isValid) {
      return false;
    }
    this.setActiveStep(this.state.currentStep + 1);
  }

  render() {
    const steps = this.getSteps();
    const main_step_color = "#3EAF5C";
    let content = "";

    content = this.getStepContent(this.state.currentStep);
    console.log("RENDER");
    return (
      <div className="x_content">
        <Title title={this.props.t("Cadastrar Operações")}></Title>
        <Stepper
          steps={steps}
          activeStep={this.state.currentStep}
          activeColor={main_step_color}
          completeColor={main_step_color}
        />
        <div className="operation_register_content">{content}</div>
      </div>
    );
  }
}
export default withTranslation()(OpertationRegister);
