import React, { Component, useState, useEffect } from "react";

import AvatarEditor from "react-avatar-editor";
import MaskedInput from "react-text-mask";
import { toast, ToastContainer } from "react-toastify";

import { withTranslation } from "react-i18next";

import { Link, withRouter, useParams, useHistory } from "react-router-dom";

import myConfig from "../../configs/config";
import axios from "axios";

function OperatorCreate(props) {
  const params = useParams();
  const history = useHistory();
  const width = 120;

  const farmId = params.farm_id;
  const operatorId = params.operator_id === undefined ? -1 : params.operator_id;
  
  let hasOperator = operatorId !== -1;

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [identity, setIdentity] = useState("");
  const [hours_week, setHoursWeek] = useState("");
  const [salary, setSalary] = useState("");
  const [picture, setPicture] = useState(null);

  async function getCurrentOperator() {
    let OPERATOR_URL = `${myConfig.API_URL}/operator/${operatorId}/?farm_id=${farmId}`;
    let response = await axios({
      baseURL: OPERATOR_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    let data = response.data;

    setName(data.nome);
    setPhone(data.telefone);
    setIdentity(data.ident);
    setHoursWeek(data.horas_semanais);
    setSalary(data.salario);
    setPicture(data.picture);
  }

  async function updateOperator() {
    try {
      let UPDATE_OPERATOR = `${myConfig.API_URL}/operator/${operatorId}/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: UPDATE_OPERATOR,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
        data: getFormData(),
      });

      let status = response.status;

      if (status === 200) {
        toast.success(
          `${props.t("Operador")}  ${props.t("atualizado com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/operator/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function createOperator() {
    try {
      let CREATE_OPERATOR = `${myConfig.API_URL}/operator/?farm_id=${farmId}`;

      let response = await axios({
        baseURL: CREATE_OPERATOR,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
        data: getFormData(),
      });

      let status = response.status;

      if (status === 201) {
        toast.success(
          `${props.t("Operador")}  ${props.t("cadastrado com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/operator/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function getFormData() {
    let form_data = new FormData();
    if (typeof picture === "object" && picture !== null) {
      form_data.append("picture", picture, picture.name);
    }
    form_data.append("farm", params.farm_id);
    form_data.append("nome", name);
    form_data.append("telefone", phone);
    form_data.append("ident", identity);
    form_data.append("horas_semanais", hours_week);
    form_data.append("salario", salary);
    return form_data;
  }

  function handleSave(e) {
    e.preventDefault();

    if (hasOperator) {
      // PUT
      updateOperator();
    } else {
      //POST
      createOperator();
    }
  }
  useEffect(() => {
    if (hasOperator) getCurrentOperator();
  }, []);

  return (
    <div id="operator_create_container">
      <h2>{hasOperator ? "Editar Operator" : "Cadastrar Operador"}</h2>

      <form method="POST" onSubmit={handleSave} encType="multipart/form-data">
        <div className="avatar-user">
          <AvatarEditor
            className="avatar"
            image={picture}
            width={120}
            height={120}
            style={{ border: " 1px solid #f2f2f2f2" }}
            border={50}
            borderRadius={width / (100 / 50)} //BORDER FOCUS 50%
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
        </div>

        <div className="form-group">
          <label htmlFor="picture">{props.t("Foto")}</label>
          <input
            type="file"
            name="picture"
            onChange={(e) => setPicture(e.target.files[0])}
            title="Insira uma foto"
            id="id_picture"
          />
        </div>

        <div className="form-group">
          <label htmlFor="name">{props.t("Nome")}</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            maxLength={35}
            className="form-control"
            placeholder={props.t("Nome")}
            required
            id="name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="identity">{props.t("Apelido")}</label>
          <input
            type="text"
            value={identity}
            onChange={(e) => setIdentity(e.target.value)}
            name="identity"
            maxLength={35}
            className="form-control"
            placeholder={props.t("Apelido")}
            required
            id="identity"
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">{props.t("Telefone")}</label>

          <MaskedInput
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            name="cell_phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            maxLength={16}
            className="form-control"
            placeholder={props.t("Telefone")}
          />
        </div>

        <div className="form-group">
          <label htmlFor="salary">{props.t("Salário")}</label>
          <input
            type="text"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
            name="salary"
            maxLength={5}
            className="form-control"
            placeholder={props.t("Salário")}
            id="salary"
          />
        </div>

        <div className="form-group">
          <label htmlFor="hours_week">{props.t("Horas semanais")}</label>
          <input
            type="text"
            value={hours_week}
            onChange={(e) => setHoursWeek(e.target.value)}
            name="hours_week"
            maxLength={2}
            className="form-control"
            placeholder="40"
            id="hours_week"
          />
        </div>

        <div align="center">
          <input
            type="submit"
            className="btn btn-primary"
            value={props.t("Salvar")}
          />
          <Link to={`/${farmId}/operator/`} className="btn btn-default">
            {props.t("Cancelar")}
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default withTranslation()(withRouter(OperatorCreate));
