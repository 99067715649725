import React, { useEffect, useState } from "react";

import KpiGadget from "./Kpi_gadget";

import myConfig from "../../../configs/config";
import { withTranslation } from "react-i18next";
import axios from "axios";

import { FaCogs, FaTractor, FaFileAlt } from "react-icons/fa";

function KpisGadgets(props) {
  const { farm_id } = props;
  
  const KPI_URL = `${myConfig.API_URL}/dashboard/?farm_id=${farm_id}`;
  let contentGadgets = [];

  const [allKpiData, setAllKpisData] = useState([]);
  const [kpiOperations, setKpiOperations] = useState([]);
  const [kpiMachines, setKpiMachines] = useState([]);
  const [kpiReports, setKpiReports] = useState([]);
  const [kpiIdle, setKpiIdle] = useState([]);
  const [period_id, setPeriodId] = useState(0);
  const [periods, setPeriods] = useState([
    {
      id: 0,
      label: "Hoje",
      pc: 0,
      hours: "0.0",
    },
    {
      id: 1,
      label: "Ontem",
      pc: 0,
      hours: "0.0",
    },
    {
      id: 2,
      label: "Última Semana",
      pc: 0,
      hours: "0.0",
    },
    {
      id: 3,
      label: "Último Mês",
      pc: 0,
      hours: "0.0",
    },
  ]);

  const [modalMachine, setModalMachine] = React.useState(false);
  const [modalOperation, setModalOperation] = React.useState(false);
  const [modalHoursIdle, setModalHoursIdle] = React.useState(false);
  const [equipment, setEquipments] = React.useState({});
  const [operations, setOperations] = React.useState([]);

  async function loadOperations() {
    try {
      const URL = `${myConfig.API_URL}/operation/?farm_id=${farm_id}&fineshed=0`;

      let response = await axios({
        baseURL: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      setOperations(data);
    } catch (e) {
      let error_msg =
        "Falha ao carregar operações, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  async function loadEquipmentsData() {
    let MARKERS_URL = `${myConfig.API_URL}/equipment/equipments_info/?farm_id=${farm_id}`;

    try {
      let response = await axios({
        baseURL: MARKERS_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let { data, status } = response;

      if (status === 200) {
        let equipment_data = {};

        for (let i = 0; i < data.length; i++) {
          equipment_data[data[i].id] = {
            id: data[i].id,
            ident: data[i].ident,
            farm: data[i].farm,
            farm_name: data[i].farm_name,
            category: data[i].category,
            marca: data[i].marca,
            category_name: data[i].category_name,
            sub_category: data[i].sub_category,
            subcategory_name: data[i].subcategory_name,
            capacity: data[i].capacity,
            manufacture_year: data[i].manufacture_year,
            classification: data[i].classification,
            manufacturer: data[i].manufacturer,
            device_uid: data[i].device_uid,
            machine_status: data[i].machine_status,
            traccar_ico: data[i].traccar_ico,
            speed: data[i].speed,
            last_connection: data[i].last_connection,
            operation: data[i].operation,
            last_posi_point: [],
            extra_inf: data[i].extra_inf,
          };

          if (equipment.hasOwnProperty(data[i].id)) {
            equipment_data[data[i].id]["last_posi_point"] =
              equipment[data[i].id]["last_posi_point"];
          }

          equipment_data[data[i].id]["last_posi_point"].push({
            lat: data[i].last_posi_point.lat,
            lng: data[i].last_posi_point.lng,
          });
        }

        setEquipments(equipment_data);

        if (props.width === "xs") {
          setTimeout(() => loadEquipmentsData(), 10000);
        }
      }
    } catch (e) {
      // console.error(e);
      alert("Erro ao carregar dados dos equipamentos");
    }
  }

  React.useEffect(() => {
    loadOperations();
    loadEquipmentsData();
  }, []);

  const handleModalMachine = () => {
    setModalMachine(!modalMachine);
  };

  const handleModalOperation = () => {
    setModalOperation(!modalOperation);
  };

  const handleModalHoursIdle = () => {
    setModalHoursIdle(!modalHoursIdle);
  }


  async function fetchKpis() {
    let response = await axios({
      baseURL: KPI_URL,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    });

    const kpi = response.data;

    setPeriods(kpi.idle_info);

    let kpi_data = [
      {
        identity: "operations",
        label_header: "Operações",
        header_value: "",
        header_class: "green",
        header_ico_class: <FaCogs className="fa-2x fa pull-right" />,
        content_header: "em andamento",
        content_value: kpi.operation_status.ment,
        content_class: "green",
        footer_header: `${kpi.operation_status.pending}  ${props.t(
          "pendentes"
        )}    ${props.t("e")} ${kpi.operation_status.scheduled} 
        ${props.t("agendados")}`,
        footer_value: "",
        footer_class: "yellow",
        first_value: "true",
      },
      {
        identity: "machines",
        label_header: `${props.t("Máquinas")}`,
        header_value: `${props.t("Ligadas")}`,
        header_class: "red",
        header_ico_class: <FaTractor className="fa-2x fa pull-right" />,
        content_header: `${props.t("Ligadas")} :`,
        content_value: kpi.online_machines,
        content_class: "red",
        footer_header: `${props.t("Total")}  :`,
        footer_class: "yellow",
        footer_value: kpi.total_machines,
      },
      {
        main_link: `/${farm_id}/report/fleet/?year=${kpi.last_report_data.year}&month=${kpi.last_report_data.month_value}`,
        identity: "reports",
        label_header: `${props.t("Relatórios")}`,
        header_value: "",
        header_class: "blue",
        header_ico_class: <FaFileAlt className="fa-2x fa pull-right" />,
        content_header: `${kpi.last_report_data.month_name}   ${props.t(
          "Disponível para analise"
        )}`,
        content_value: "",
        content_class: "blue ",
      },
      {
        identity: "idle",
        label_header: periods[period_id].pc,
        header_value: `${props.t(`Percentual ocioso`)}`,
        header_class: "dark",
        period_id: period_id,
        periods: periods,
        content_header: "Percentual ocioso",
        content_value: "",
        content_class: "dark",
        footer_header: `${props.t("Tempo Total")}: `,
        footer_class: "yellow",
        footer_value: periods[period_id].hours,
      },
    ];

    setAllKpisData(kpi_data);
    setKpiOperations(kpi_data[0]);
    setKpiMachines(kpi_data[1]);
    setKpiReports(kpi_data[2]);
    setKpiIdle(kpi_data[3]);


    if (window.location.pathname.length < 6) {
      // console.log('fetchKpis')
      setTimeout(fetchKpis, 10000);
    }
  }

  function KPIOperations() {
    // pushKPIS(0);
    contentGadgets.push(
      <KpiGadget
        key={0}
        identity={kpiOperations.identity}
        handleModalOperation={handleModalOperation}
        modalOperation={modalOperation}
        operations={operations}
        label_header={kpiOperations.label_header}
        header_value={kpiOperations.header_value}
        header_ico_class={kpiOperations.header_ico_class}
        first_value={kpiOperations.first_value}
        content_header={kpiOperations.content_header}
        content_value={kpiOperations.content_value}
        content_class={kpiOperations.content_class}
        footer_header={kpiOperations.footer_header}
        footer_value={kpiOperations.footer_value}
        footer_class={kpiOperations.footer_class}
        header_class={kpiOperations.header_class}
      />
    );
  }
  function KPIMachines() {
    contentGadgets.push(
      <KpiGadget
        key={1}
        identity={kpiMachines.identity}
        handleModalMachine={handleModalMachine}
        modalMachine={modalMachine}
        equipment={equipment}
        label_header={kpiMachines.label_header}
        header_value={kpiMachines.header_value}
        header_ico_class={kpiMachines.header_ico_class}
        first_value={kpiMachines.first_value}
        content_header={kpiMachines.content_header}
        content_value={kpiMachines.content_value}
        content_class={kpiMachines.content_class}
        footer_header={kpiMachines.footer_header}
        footer_value={kpiMachines.footer_value}
        footer_class={kpiMachines.footer_class}
        header_class={kpiMachines.header_class}
      />
    );
  }
  function KPIReports() {
    contentGadgets.push(
      <KpiGadget
        key={2}
        identity={kpiReports.identity}
        main_link={kpiReports.main_link}
        label_header={kpiReports.label_header}
        header_value={kpiReports.header_value}
        header_ico_class={kpiReports.header_ico_class}
        first_value={kpiReports.first_value}
        content_header={kpiReports.content_header}
        content_value={kpiReports.content_value}
        content_class={kpiReports.content_class}
        footer_header={kpiReports.footer_header}
        footer_value={kpiReports.footer_value}
        footer_class={kpiReports.footer_class}
        header_class={kpiReports.header_class}
      />
    );
  }

  function KPIidle() {
    contentGadgets.push(
      <KpiGadget
        key={3}
        identity={kpiIdle.identity}
        label_header={kpiIdle.label_header}
        header_value={kpiIdle.header_value}
        header_ico_class={kpiIdle.header_ico_class}
        first_value={kpiIdle.first_value}
        content_header={kpiIdle.content_header}
        content_value={kpiIdle.content_value}
        content_class={kpiIdle.content_class}
        footer_header={kpiIdle.footer_header}
        footer_value={kpiIdle.footer_value}
        footer_class={kpiIdle.footer_class}
        header_class={kpiIdle.header_class}
        periods={periods}
        handleModalHoursIdle={handleModalHoursIdle}
        modalHoursIdle={modalHoursIdle}
      />
    );
  }

  function renderGadgets() {
    KPIidle();
    KPIOperations();
    KPIMachines();
    KPIReports();

    return contentGadgets;
  }

  useEffect(() => {
    fetchKpis();
  }, []);

  return <div>{renderGadgets()}</div>;
}

export default withTranslation()(KpisGadgets);
