import React, { useState, useEffect } from "react";
import Card_operator from "./Card_operator";
import myConfig from "../../configs/config";
import MDSpinner from "react-md-spinner";
import axios from "axios";
import ExportButton from "../../components/Export_button";
import AddButton from "../../components/Add_button";
import { withTranslation } from "react-i18next";
import { isChildrenEmpty } from "../../helpers/utils";
import { useParams } from "react-router-dom";

function ListOperator(props) {
  const params = useParams();

  const [listOperators, setListOperators] = useState([]);
  const [loading, setLoading] = useState(true);

  async function loadOperators() {
    try {
      let OPERATOR_URL = `${myConfig.API_URL}/operator/?farm_id=${params.farm_id}`;

      const response = await axios({
        baseURL: OPERATOR_URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setListOperators(data);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar operadores, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  useEffect(() => {
    document.title = "Agriconnected - Operadores";
    loadOperators();
  }, []);

  return (
    <>
      {loading === false ? (
        <div id="operator_list_container">
          <div className="x_title">
            <h2>{props.t("Operadores")}</h2>
            <div className="clearfix"></div>
          </div>

          <AddButton
            url={`/${params.farm_id}/operator/add`}
            classname="btn btn-primary btn-default-add"
          />
          <ExportButton
            classname="btn btn-default-export"
            export={`${myConfig.API_URL}/operator/export_operator/?farm_id=${params.farm_id}`}
          />
          {isChildrenEmpty(
            listOperators,
            <div>
              <h4 align="center">
                Está fazenda ainda não possui nenhum operador cadastrado
              </h4>
            </div>,
            <div>
              {listOperators.map((operator) => (
                <Card_operator
                  operator={operator}
                  key={operator.id}
                  farm_id={params.farm_id}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}
        >
          <MDSpinner />
        </div>
      )}
    </>
  );
}
export default withTranslation()(ListOperator);
