import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// CONFIGS
import axios from "axios";
import myConfig from "../../../configs/config";
import { withTranslation } from "react-i18next";

// HELPERS
import { isChildrenEmpty } from "../../../helpers/utils";

// COMPONENTS
import MDSpinner from "react-md-spinner";
import Title from "../components/Title";
import Table from "../components/Table";

function Index(props) {
  const params = useParams();

  let tableHeaders = [
    { title: props.t("Identificação") },
    { title: props.t("Visualizar") },
  ];

  const [listMachines, setMachines] = useState([]);
  const [loading, setLoding] = useState(true);

  async function getMachines() {
    try {
      let MACHINE_LIST_URL = `${myConfig.API_URL}/equipment/?farm_id=${params.farm_id}`;

      const response = await axios({
        baseURL: MACHINE_LIST_URL,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let data = response.data;

      if (data) {
        setMachines(data);
        setLoding(false);
      }
    } catch (error) {
      // console.log(error);
      let error_msg =
        "Falha ao carregar máquinas, recarregue a página e tente novamente";

      alert(error_msg);
    }
  }

  useEffect(() => {
    getMachines();
  }, []);

  return (
    <>
      {loading === false ? (
        <div>
          <Title title="Máquinas" />

          {isChildrenEmpty(
            listMachines,
            <h4 align="center">
              {props.t(
                "Está fazenda ainda não possui nenhuma máquina cadastrada."
              )}
            </h4>,
            <Table headers={tableHeaders}>
              {listMachines.map((machine) => (
                <tr key={machine.ident}>
                  <td>{machine.ident}</td>

                  <td align="center">
                    <div className="btn-group">
                      <Link
                        to={`/${params.farm_id}/equipment/machine/${machine.id}/report`}
                        className="btn btn-default btn-primary"
                      >
                        {props.t("Escolher")}
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </Table>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

function Loading() {
  return (
    <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
      <MDSpinner />
    </div>
  );
}

export default withTranslation()(Index);
