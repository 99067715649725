import React, { useState, useEffect } from "react";

import StepControl from "../StepControl";
import SelectBox from "../../../SelectBox";

import Alert from "react-bootstrap/Alert";
import MDSpinner from "react-md-spinner";

import { isEmpty } from "../../../../helpers/utils";
import { withTranslation } from "react-i18next";

import { FaPlus, FaTrashAlt } from "react-icons/fa";

import "./index.css";

function FirstOperationStep(props) {
  function mountOperationInvolved() {
    let content = [];
    let fields = [
      {
        field: "machine",
        value: "ident",
        state_name: props.FakedMachines,
        label: props.t("Máquina"),
        component_name: "machine",
        error: props.t("É necessário escolher o maquinário"),
      },
      {
        field: "implement",
        value: "ident",
        state_name: props.FakedImplements,
        label: props.t("Implemento"),
        component_name: "implement",
        error: props.t("É necessário escolher o implemento"),
      },
      {
        field: "operator",
        value: "nome",
        state_name: props.operators,
        label: props.t("Operador"),
        component_name: "operator",
        error: props.t("É necessário escolher um operador"),
      },
    ];

    Object.keys(fields).map(function (key, index) {
      if (isEmpty(fields[index])) {
        return;
      }

      let state_name = fields[index].state_name;
      let state_data = state_name;
      let selected_value = props[fields[index].component_name];
      let field = fields[index].field;
      let field_value = fields[index].value;
      let show = false;

      const data = state_data.map((item) => ({
        id: item.id,
        ident: item[field_value],
        extra:
          field === "implement" || field === "machine"
            ? {
                classification: item.classification,
                category: item.category_name,
              }
            : {},
      }));

      if (field === "implement") {
        show = true;
        if (props.machine_classification === "trator") {
          show = false;
        }
      }

      let error_component = "";

      if (props.error_fields !== "") {
        props.error_fields.map((error) => {
          if (field === error) {
            error_component = (
              <Alert variant="danger">{fields[index].error}</Alert>
            );
          }
        });
      }

      content.push(
        <div className="col step-first-selects">
          {error_component}
          <SelectBox
            strong="true"
            disabled={show}
            data={data}
            selected_value={selected_value}
            value={"ident"}
            change={props.handleChange}
            label={fields[index].label}
            component_name={fields[index].component_name}
          />
        </div>
      );
    });

    return content;
  }

  return (
    <div className="">
      <div className="container">
        {props.loading === true ? (
          <Spinner />
        ) : (
          <div className="row-steps">
            {mountOperationInvolved()}

            <div className="col step-first-btn">
              <button className="btn btn-in-row" onClick={props.pushSetsData}>
                <FaPlus />
              </button>
            </div>
          </div>
        )}
      </div>

      {props.error_fields !== ""
        ? props.error_fields.map((field) => {
            return (
              <>
                <Alert variant="danger">
                  Campo {field} não pode ser nulo, por favor preencha para
                  prosseguir.
                </Alert>
              </>
            );
          })
        : null}

      <br />
      <br />
      <br />
      {props.sets.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">Implemento</th>
              <th scope="col">Operador</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {props.sets.length > 0 &&
              props.sets.map((set, index) => (
                <tr>
                  <td>
                    {props.machines.map((machine) => (
                      <>
                        {machine.id === parseInt(set.machine) && (
                          <p className="">{machine.ident}</p>
                        )}
                      </>
                    ))}
                  </td>
                  <td>
                    {props.implements.map((implement) => (
                      <>
                        {implement.id === parseInt(set.impl) && (
                          <p className="">{implement.ident}</p>
                        )}
                      </>
                    ))}
                  </td>
                  <td>
                    {props.operators.map((operator) => (
                      <>
                        {operator.id === parseInt(set.operator) && (
                          <p className="">{operator.ident}</p>
                        )}
                      </>
                    ))}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-in-row"
                      onClick={() => props.popSetsData(set, index)}
                    >
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <br />
      <br />
      <br />

      {props.sets.length > 0 && (
        <StepControl
          handleNext={props.handleNext}
          handleBack={props.handleBack}
          lastStep={props.lastStep}
          currentStep={props.currentStep}
        />
      )}
    </div>
  );
}
function Spinner() {
  return (
    <div style={{ marginTop: "50px", marginLeft: "50%", minHeight: "200px" }}>
      <MDSpinner />
    </div>
  );
}
export default withTranslation()(FirstOperationStep);
