import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import m from "moment";

const LineGraph = ({ data, refValue = 5, period, onClick }) => {
  const handleClick = (e) => {
    if (e && e.activePayload && e.activePayload[0]) {
      onClick(e.activePayload[0].payload);
    }
  };
  const getXAxisTicks = () => {
    const ONE_HOUR = 3600000;
    const startTime = data[0].time;
    const endTime = data.at(-1).time;
    const firstHour = Math.ceil(startTime / ONE_HOUR) * ONE_HOUR;
    const hourlyTicks = Math.ceil((endTime - firstHour) / ONE_HOUR);
    const result = Array.from(
      { length: hourlyTicks },
      (_, i) => firstHour + i * ONE_HOUR
    );
    return result;
  };
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          width={800}
          height={400}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          onClick={handleClick}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            scale="time"
            type="number"
            domain={[data[0].time, data.at(-1).time]}
            tickFormatter={(time) =>
              period > 1 ? m(time).format("DD/MM HH") : m(time).format("HH")
            }
            ticks={getXAxisTicks()}
          />
          <YAxis />
          <Tooltip labelFormatter={(time) => new Date(time).toLocaleString()} />

          <Legend />
          <Line
            type="monotone"
            dataKey="velocidade"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <ReferenceLine
            y={refValue}
            label="Velocidade máxima"
            stroke="red"
            strokeDasharray="3 3"
          />
          <Brush
            dataKey="time"
            height={20}
            stroke="#8884d8"
            startIndex={0}
            endIndex={data.length - 1}
            travellerWidth={6}
            tickFormatter={(time) => m(time).format("DD/MM HH:mm")}
            alwaysShowText
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineGraph;
