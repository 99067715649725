import React, { useEffect, useState } from "react";

import myConfig from "../../configs/config";

import { withTranslation } from "react-i18next";

import { Form, Label, Input } from "reactstrap";

import { Link, withRouter, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";

function FormAdmin(props) {
  const farmId = props.match.params.farm_id;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  let history = useHistory();

  useEffect(() => {
    document.title = "Agriconnected - Administradores";
  }, []);

  function getFormData() {
    let form_data = new FormData();

    form_data.append("first_name", name);
    form_data.append("email", email);
    form_data.append("farm", farmId);

    return form_data;
  }

  async function createNewAdministrator() {
    try {
      const CREATE_ADMIN = `${myConfig.API_URL}/accounts/create_admin/`;

      let response = axios({
        baseURL: CREATE_ADMIN,
        method: "POST",
        data: getFormData(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      });

      let status = (await response).status;

      if (status === 201) {
        toast.success(
          `${props.t("Administrator")}  ${props.t("cadastrado com sucesso")}!`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          history.push(`/${farmId}/administrator/`);
        }, 2000);
      }
    } catch (error) {
      let error_msg = "";
      Object.keys(error.response.data).forEach(function (e) {
        error_msg += e + ": " + error.response.data[e][0] + " \n ";
      });
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleSave(e) {
    e.preventDefault();

    createNewAdministrator();
    window.scrollTo(0, 0);
  }

  return (
    <div id="admin_form_container">
      <div className="x_title">
        <h2>{props.t("Cadastrar Administradores")}</h2>
        <div className="clearfix"></div>
      </div>

      <Form onSubmit={handleSave} encType="multipart/form-data">
        <div className="form-group">
          <Label htmlFor="id_name">Nome</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            maxLength="35"
            className="form-control"
            placeholder="Nome"
            required
          />
        </div>

        <div className="form-group">
          <Label htmlFor="id_email">Email</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            className="form-control"
            placeholder="Email"
            required
          />
        </div>

        <div align="center">
          <input type="submit" className="btn btn-primary" value="Salvar" />
          <Link to={`/${farmId}/administrator/`} className="btn btn-default">
            Cancelar
          </Link>
        </div>
      </Form>
      <ToastContainer />
    </div>
  );
}
// }
export default withTranslation()(withRouter(FormAdmin));
