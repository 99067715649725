import React, { useEffect, useState } from "react";

import axios from "axios";

import myConfig from "../../configs/config";

import { useHistory } from "react-router-dom";

import Loading from "../../components/Loading";
import Modal from "@material-ui/core/Modal";

import checkedGif from "../../assets/gifs/checked.gif";

function ConfirmEmail(props) {
  const [isLoading, setLoading] = useState(true);
  let history = useHistory();

  let token =
    props.match.params.token === undefined ? -1 : props.match.params.token;

  let hasToken = token !== -1;

  function validNewUserFormData() {
    const form_data = new FormData();

    form_data.append("id", token);

    return form_data;
  }

  async function validNewUser() {
    let VALID_USER_EMAIL_URL = `${myConfig.API_URL}/accounts/set_valid_new_user/`;

    let response = await axios({
      baseURL: VALID_USER_EMAIL_URL,
      method: "PUT",
      data: validNewUserFormData(),
    });

    let status = response.status;

    if (status === 200) {
      setLoading(false);
      setTimeout(() => {
        history.push("/account/login/");
      }, 2200);
    }
  }

  useEffect(() => {
    if (hasToken) {
      validNewUser();
    }
  }, []);

  return <>{isLoading ? <Loading /> : <PoupUp />}</>;
}

export default ConfirmEmail;


function PoupUp() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="poupup-confirm-email">
          <img src={checkedGif} alt="gif-loading" className="checked-gif" />
          <br />
          <h3 align="center">Email confirmado com sucesso!</h3>
        </div>
      </Modal>
    </div>
  );
}
